import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React, { FC } from 'react';
import { ArrowForward } from 'react-ionicons';
import { useNavigate } from 'react-router-dom';
import { ActivityProps } from 'screens/InventoryAccount/inventoryAccount.types';

const Footer: FC<Pick<ActivityProps, 'type' | 'linkTo'>> = ({
  type,
  linkTo,
}) => {
  const navigate = useNavigate();
  const onClick = () => linkTo && navigate(linkTo.to);

  let label = 'ticket';
  if (type === 'invoice_added') label = 'invoice';
  if (type === 'added_service' || type === 'end_dated_service')
    label = 'service';
  if (type === 'ordered_servies' || type === 'new_quote_request')
    label = 'quote request';
  return (
    <div className="p-4">
      <GraniteButton variant="tertiary" className="w-full" onClick={onClick}>
        Go to {label}{' '}
        <ArrowForward color="currentColor" width="20px" height="20px" />
      </GraniteButton>
    </div>
  );
};

export default Footer;
