import TagManager from 'react-gtm-module';

const initializeGTM = (gtmId?: string) => {
  if (gtmId) {
    TagManager.initialize({ gtmId });
  } else {
    console.warn('GTM ID is not defined for the current environment.');
  }
};

const initializeClarity = (clarityId?: string) => {
  if (clarityId) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityId}");
      `;
    document.head.appendChild(script);
  } else {
    console.warn('Clarity ID is not defined for the current environment.');
  }
};

export const initializeTrackingScripts = () => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  const clarityId = process.env.REACT_APP_CLARITY_ID;

  initializeGTM(gtmId);
  initializeClarity(clarityId);
};
