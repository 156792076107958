import format from 'date-fns/format';
import { useLayoutEffect, useRef } from 'react';

interface TicketActivityItemProps {
  datetime?: string;
  title?: string;
  body?: string;
  isActive?: boolean;
  hasNewActivityDivider?: boolean;
  color?: string;
}

export const TicketActivityItem = ({
  datetime,
  title,
  body,
  isActive = true,
  hasNewActivityDivider,
  color,
}: TicketActivityItemProps) => {
  const container = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (isActive && container.current) {
      container.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [isActive]);

  return (
    <div ref={container}>
      {hasNewActivityDivider && (
        <div className="flex items-center">
          <div className="flex-grow border-t border-stroke-base-subdued border-opacity-50" />
          <span className="mx-2 rounded-full bg-green-300 bg-opacity-20 px-2 py-1 text-xs font-bold text-green-300">
            New activity
          </span>
          <div className="flex-grow border-t border-stroke-base-subdued border-opacity-50" />
        </div>
      )}
      <div className="odd:text-status-info-default even:text-teal-400">
        {datetime && (
          <div className="text-xs font-bold text-content-base-subdued">
            {format(new Date(datetime), 'MM/dd/yyyy @ HH:mm a ')}
          </div>
        )}
        <div className="mb-2 text-base font-bold" style={{ color }}>
          {title}
        </div>{' '}
        <p className="whitespace-pre-line text-base font-bold text-content-base-default	">
          {body ? <div dangerouslySetInnerHTML={{ __html: body }} /> : null}
        </p>
      </div>
    </div>
  );
};
