import { formatPhoneNumber } from 'screens/LetUsHelp/utils/formatPhoneNumber';
import { ContactSubForm } from 'screens/LetUsHelp/utils/schemas';
import { lookup } from 'zipcode-to-timezone';

export const convertHexToRgba = (hex: string, opacity: number) => {
  return `rgba(${parseInt(hex.slice(1, 3), opacity)},
  ${parseInt(hex.slice(3, 5), opacity)},
  ${parseInt(hex.slice(5, 7), opacity)},
  0.5)`;
};

export const removeDuplicatesFromArray = <T>(array: T[]): T[] => {
  const uniqueValues = new Set(array);
  return Array.from(uniqueValues);
};

export function toSentenceCase(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const formatContactTesting = (itTestingContacts: ContactSubForm[]) => {
  return (
    (itTestingContacts.length > 1 ? '\n' : '') +
    itTestingContacts
      .map(
        (contact, index, array) =>
          `${array.length > 1 ? `• Testing contact #${index + 1}:` : ''} ${
            contact.contactName
          } who can be reached via phone at ${formatPhoneNumber(
            contact.contactNumber,
          )}${
            contact.contactEmail ? ` or email at ${contact.contactEmail}` : ''
          }.`,
      )
      .join('\n')
  );
};

export const pagination = (
  currentPage: number,
  pageCount: number,
): (number | string)[] => {
  if (pageCount <= 5) {
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  const pages: (number | string)[] = [];

  if (currentPage <= 3) {
    pages.push(1, 2, 3, 4, '...', pageCount);
  } else if (currentPage >= pageCount - 2) {
    pages.push(
      1,
      '...',
      pageCount - 3,
      pageCount - 2,
      pageCount - 1,
      pageCount,
    );
  } else {
    pages.push(
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      pageCount,
    );
  }

  return pages;
};
export const plainTextToPhoneNumber = (str: string) =>
  `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6)}`;

export const getTimeZoneAbbreviationFromZipCode = (zipcode: string) => {
  const targetTimezone = lookup(zipcode);
  if (targetTimezone)
    return new Date()
      .toLocaleTimeString('en-US', {
        timeZone: targetTimezone,
        timeZoneName: 'short',
      })
      .split(' ')
      .pop();
};

export const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
