import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { PageLoader } from 'components/PageLoader';
import { getG360Token } from 'api/auth/auth';
import { AuthProvider } from 'authContext';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { OktaGroup } from 'api/companies/schema';
import { getGroups } from 'api/companies/api';
import Searchbar from 'components/Table/SearchBar';
import { CardSkeleton } from './CardSkeleton';
import { Card } from './Card';
import { G360Token } from 'api/auth/schema';
import { ArrowBack } from 'react-ionicons';

const SubgroupSelection = ({
  className,
  subGroups,
  onBack,
  onSelect,
}: {
  className?: string;
  subGroups: OktaGroup[];
  onBack?: () => void;
  onSelect: (group: OktaGroup) => void;
}) => {
  return (
    <div
      className={clsx(
        'min-h-full w-full bg-background-base-surface-2 px-6 py-12 md:min-h-0 md:w-[624px] md:px-12',
        className,
      )}
    >
      <div className="flex w-full flex-col justify-center gap-8">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Select account
          </h1>
        </div>
        <div className="flex flex-col items-center gap-6">
          {subGroups.map((group) => (
            <Card
              key={group.id}
              group={group}
              onClick={() => onSelect(group)}
            />
          ))}
        </div>
        <button
          className={clsx(
            'flex items-center justify-center gap-2 text-content-base-default',
            !onBack && 'hidden',
          )}
          onClick={onBack}
        >
          <ArrowBack color="inherit" width="20px" height="20px" />
          <span>Go back</span>
        </button>
      </div>
    </div>
  );
};

const GroupSelection = ({
  className,
  onSelect,
}: {
  className?: string;
  onSelect: (group: OktaGroup) => void;
}) => {
  const loadMoreRef = useRef(null);
  const [search, setSearch] = useState('');
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['companies', { search }],
      queryFn: ({ pageParam }) => {
        return getGroups({ after: pageParam, search });
      },
      getNextPageParam: (lastPage) => {
        return lastPage.metadata.next;
      },
    });

  const groups = useMemo(
    () => data?.pages.map(({ data }) => data).flat() ?? [],
    [data],
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage(); // Fetch the next page when the end is reached
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!search && groups.length === 1) {
      onSelect(groups[0]);
    }
  }, [groups, onSelect, search]);

  if (!search && isLoading) return <PageLoader />;
  if (!search && groups.length === 1) return null;

  return (
    <div
      className={clsx(
        'w-full bg-background-base-surface-2 py-12 md:min-h-0 md:w-[624px] ',
        className,
      )}
    >
      <div className="flex w-full flex-col justify-center gap-8">
        <div className="flex flex-col gap-8 px-6 md:px-12">
          <div className="flex flex-col items-center gap-6">
            <Logo className="!h-auto !w-[126px]" />
            <h1 className="font-semibold text-content-base-default">
              Choose a company
            </h1>
          </div>
          <Searchbar
            placeholder="Search by company name or account #"
            onQueryClear={() => setSearch('')}
            onSearch={setSearch}
          />
        </div>
        <div className="vertical-scrollbar mb-12 flex max-h-[calc(100vh-320px)] flex-col items-center gap-6 overflow-y-auto px-6 py-0.5 scrollbar-track-transparent  scrollbar-thumb-stroke-base-subdued md:max-h-[calc(100vh-368px)] md:px-12">
          {isLoading ? (
            <Fragment>
              <CardSkeleton />
            </Fragment>
          ) : groups.length === 0 ? (
            <div className="w-full text-center">No results found.</div>
          ) : (
            groups?.map((group) => (
              <Card
                key={group.id}
                group={group}
                showMacnum
                onClick={() => onSelect(group)}
              />
            ))
          )}
          {hasNextPage && <CardSkeleton ref={loadMoreRef} />}
        </div>
      </div>
    </div>
  );
};

const SelectCompanyInner = () => {
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<OktaGroup>();
  const [subGroups, setSubGroups] = useState<OktaGroup[]>([]);

  const { data, isLoading } = useQuery(
    ['group', { id: selectedGroup?.id }],
    () => getG360Token(selectedGroup?.id as string),
    {
      enabled: !!selectedGroup,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data.sub_groups.length === 0 || data.group_metadata.parent_id) {
          handleG360Token(data);
        } else if (data.sub_groups.length === 1) {
          setSelectedGroup(data.sub_groups[0]);
        } else {
          setSubGroups(data.sub_groups);
        }
      },
    },
  );

  const queryClient = useQueryClient();

  const handleG360Token = useCallback(
    async (token: G360Token) => {
      const originalUri = sessionStorage.getItem('originalUri') ?? '/';
      sessionStorage.removeItem('originalUri');
      localStorage.setItem('g360-token-storage', JSON.stringify(token));
      queryClient.clear();
      navigate(toRelativeUrl(originalUri, window.location.origin), {
        replace: true,
      });
    },
    [navigate, queryClient],
  );

  const handleOnBack = useCallback(() => {
    setSelectedGroup(undefined);
    setSubGroups([]);
  }, []);

  return (
    <div className="flex h-screen flex-wrap items-start justify-center overflow-hidden md:h-[calc(100vh-24px)] md:py-6">
      <GroupSelection
        className={isLoading || subGroups.length > 0 ? 'hidden' : ''}
        onSelect={setSelectedGroup}
      />
      <SubgroupSelection
        className={isLoading || subGroups.length === 0 ? 'hidden' : ''}
        subGroups={subGroups}
        onBack={!data?.multiple_groups ? undefined : handleOnBack}
        onSelect={setSelectedGroup}
      />
      {isLoading && <PageLoader />}
    </div>
  );
};

export const SelectCompany = () => (
  <AuthProvider>
    <SelectCompanyInner />
  </AuthProvider>
);
