import React from 'react';
import IconMapper from 'components/IconMapper/IconMapper';
import { Button, IconButtonWrapper } from './IconButton.styles';
import { ButtonProps } from 'components/Button/Button';
import theme from '../../shared/theme/custom-theme';
import clsx from 'clsx';

export interface IconButtonProps extends Omit<ButtonProps, 'label'> {
  icon: string;
  buttonType?: 'primary' | 'secondary' | 'destructive' | 'ghost'; // Add the type prop
  width?: string;
  padding?: string;
  size?: 'xs' | 'small' | 'medium' | 'large';
  color?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  buttonType = 'primary',
  size = 'small',
  width,
  padding = undefined,
  color = theme.colors.text.white,
  ...props
}: IconButtonProps) => {
  return (
    <IconButtonWrapper size={size}>
      <Button
        {...props}
        className={clsx(
          `storybook-button--${buttonType} flex items-center justify-center`,
          props.className,
        )}
        style={{ width, padding }}
      >
        <IconMapper name={icon} color={color} />
      </Button>
    </IconButtonWrapper>
  );
};

export default IconButton;
