import clsx from 'clsx';

import Divider from 'components/Divider';
import { ProductType } from './schemas';
import { formatCurrency } from 'shared/util/formatCurrency';
import { TermType, getServiceActivationFee } from './utils';
import { ReactComponent as Pin } from 'assets/images/pin.svg';
import { SelectedPackages } from 'context/OpenQuoteServiceManagerContext';

interface ServiceSummaryProps {
  data: (ProductType & { term: TermType }) | undefined;
  isPinnedSummary?: boolean;
  isDisabled?: boolean;
  mns?: SelectedPackages;
}

export const ServiceSummary = ({
  data,
  isPinnedSummary = false,
  isDisabled = false,
  mns,
}: ServiceSummaryProps) => {
  const pricing = data?.formal_broadband_pricing || data?.formal_dia_pricing;

  const serviceActivationFee = getServiceActivationFee(data);

  return (
    <div className="font-bold">
      <div className="mb-1.5">
        <span
          className={clsx(
            'text-4xl transition-colors duration-500',
            'text-content-accent-default',
            isPinnedSummary && 'text-purple-300',
            isDisabled && '!text-content-base-default',
          )}
        >
          {formatCurrency(
            (pricing?.total_mrc ?? 0) +
              (mns?.essential ? 19.99 : 0) +
              (mns?.enhanced ? 34.99 : 0),
          )}
        </span>
        <span className="text-xl">/month</span>
      </div>
      {isPinnedSummary && (
        <Pin
          color="inherit"
          className="pinned-summary absolute right-4 top-6"
        />
      )}
      <div className="text-content-base-default">
        +${serviceActivationFee} service activation fee
      </div>
      <Divider className="my-4" />
      <div className="mb-1 text-xs text-content-base-default">
        Non-recurring charges
      </div>
      <div className="mb-4 flex w-full justify-between gap-4">
        <div>Service activation fee</div>
        <div
          className={clsx(
            'text-xl transition-colors duration-500',
            'text-content-accent-default',
            isPinnedSummary && 'text-purple-300',
            isDisabled && '!text-content-base-default',
          )}
        >
          ${serviceActivationFee}
        </div>
      </div>
      <div className="mb-1 text-xs text-content-base-default">
        Monthly recurring charges
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>Connection</div>
        <div>{formatCurrency(pricing?.circuit)}</div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>Modem</div>
        <div>{formatCurrency(pricing?.modem || pricing?.equipment)}</div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>IP blocks</div>
        <div>{formatCurrency(pricing?.ip)}</div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>Internet access recovery</div>
        <div>
          {formatCurrency(
            (pricing as { internet_access_recovery?: number })
              ?.internet_access_recovery ??
              (pricing as { carrier_surcharge_recovery?: number })
                ?.carrier_surcharge_recovery ??
              0,
          )}
        </div>
      </div>
      {mns?.essential && (
        <div className="mb-2 flex justify-between gap-4">
          <div>MNS Essentials</div>
          <div>{formatCurrency(mns.essential ? 19.99 : 0)}</div>
        </div>
      )}
      {mns?.enhanced && (
        <div className="mb-2 flex justify-between gap-4">
          <div>MNS Enhanced</div>
          <div>{formatCurrency(mns.enhanced ? 34.99 : 0)}</div>
        </div>
      )}
      <div className="flex justify-between gap-4">
        <div className="">Monthly price</div>
        <div
          className={clsx(
            'text-xl transition-colors duration-500',
            'text-content-accent-default',
            isPinnedSummary && 'text-purple-300',
            isDisabled && '!text-content-base-default',
          )}
        >
          {formatCurrency(
            (pricing?.total_mrc ?? 0) +
              (mns?.essential ? 19.99 : 0) +
              (mns?.enhanced ? 34.99 : 0),
          )}
        </div>
      </div>
    </div>
  );
};
