import clsx from 'clsx';
import React, { FC } from 'react';
import {
  ActivityStatusType,
  CommonActivityProps,
} from 'screens/InventoryAccount/inventoryAccount.types';

const Status: FC<Pick<CommonActivityProps, 'status'>> = ({ status }) => {
  return (
    <p className="flex items-center gap-3 text-xs font-bold text-white">
      <span
        className={clsx('h-3 w-3 rounded-full', {
          'bg-orange-300':
            status === 'in_progress' || status === 'checking_availability',
          'bg-teal-400':
            status === 'auto_pay_initiated' || status === 'order_placed',
          'bg-green-300': status === 'added',
          'bg-red-500': status === 'end_dated',
        })}
      />{' '}
      {ActivityStatusType[status]}
    </p>
  );
};

export default Status;
