import { z } from 'zod';
import { BEUserRoles } from './UserRole';
import { nullToUndefined } from '../../../shared/util/schema-utils';

export const InviteUserResponseSchema = z.discriminatedUnion('result', [
  z.object({
    result: z.literal('Success'),
    email: z.string(),
  }),
  z.object({
    result: z.literal('Failure'),
    email: z.string(),
    error_type: z.string(),
    error_message: z.string(),
  }),
]);

export type InviteUserResponse = z.infer<typeof InviteUserResponseSchema>;
export const InviteUserRequestSchema = z.array(
  z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    phone_number: z.string(),
    role: BEUserRoles,
    inviter_name: z.string().optional(),
    sub_groups: z.array(z.number()).optional(),
  }),
);
export type InviteUserRequest = z.infer<typeof InviteUserRequestSchema>;

export const InvitationItemSchema = z.object({
  id: z.string(),
  inviter_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  role: z.string(),
  phone_number: z.string().nullish().transform(nullToUndefined),
  created_at: z.string(),
});

export type InvitationItem = z.infer<typeof InvitationItemSchema>;

export const InvitationResponseSchema = z.object({
  invitations: z.array(InvitationItemSchema),
  page: z.number(),
});

export type InvitationResponse = z.infer<typeof InvitationResponseSchema>;
