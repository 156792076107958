import clsx from 'clsx';
import React, { FC } from 'react';

interface BodyItemProps {
  label: string;
  value?: string;
}
const BodyItem: FC<BodyItemProps> = ({ label, value }) => {
  return (
    <div className="text-xs font-bold">
      <p>{label}</p>
      <p className={clsx({ 'text-white': value?.length })}>{value ?? 'N/A'}</p>
    </div>
  );
};

export default BodyItem;
