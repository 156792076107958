import React, { FC } from 'react';
import { EndDatedServiceType } from 'screens/InventoryAccount/inventoryAccount.types';
import Status from './Status';
import BodyItem from './BodyItem';

const EndDatedService: FC<
  Pick<EndDatedServiceType, 'status' | 'completionDate' | 'endDate'>
> = ({ status, completionDate, endDate }) => {
  return (
    <div className="flex items-center justify-between">
      <Status status={status} />
      <div className="flex gap-6">
        <BodyItem label="Completion date" value={completionDate} />
        <BodyItem label="End date" value={endDate} />
      </div>
    </div>
  );
};

export default EndDatedService;
