import { apiClient } from 'api/apiClient';
import { SignUpForm, SignupResponse, G360Token } from './schema';
import { OktaGroup } from 'api/companies/schema';

export const signup = async (
  request: SignUpForm,
): Promise<Partial<SignupResponse>> => {
  const payload = request.has_granite_service
    ? { org_name: request.org_name, type: request.type.value }
    : { org_name: request.org_name, type: 'New Customer' };
  const response = await apiClient.post('/api/v1/auth/signup', payload);
  return response.data;
};

export const signupGraniteUser = async (accessToken: string) =>
  apiClient.post('/api/v1/auth/signup/granite', undefined, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const signupWithAccountNumber = async (
  ticket_id: number,
  macnum: string,
) => {
  const response = await apiClient.post('/api/v1/auth/signup/account-number', {
    ticket_id,
    macnum,
  });
  return response.data;
};

export const changePassword = async (): Promise<{ message: string }> => {
  const response = await apiClient.get(`/api/v1/users/reset/password`);
  return response.data;
};

export const getG360Token = async (
  group_id: OktaGroup['id'],
): Promise<G360Token> => {
  const response = await apiClient.get(`/api/v1/auth/token/${group_id}`);
  return response.data;
};
