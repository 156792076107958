import React, { FC } from 'react';
import { OverviewType } from '../inventoryAccount.types';
import CollapsableContent, {
  CollapsableContentProps,
} from './CollapsableContent';
import ContentItem from './ContentItem';
import TextContent from './TextContent';
import { ReactComponent as FootstepsOutlineSVG } from '../../../assets/icon/footsteps-outline.svg';

export interface IAccess
  extends Pick<CollapsableContentProps, 'expand' | 'togglePreference'>,
    Pick<OverviewType, 'access'> {}

const Access: FC<IAccess> = ({ access, expand, togglePreference }) => {
  return (
    <CollapsableContent
      title="Access"
      icon={<FootstepsOutlineSVG fill="currentColor" />}
      expand={expand}
      togglePreference={togglePreference}
    >
      <ContentItem
        title="Access hours"
        content={<TextContent content={access.access_hours} />}
      />
      <ContentItem
        title="Access instructions"
        content={<TextContent content={access.access_instructions} />}
      />
      <ContentItem
        title="Intrusive testing window"
        content={<TextContent content={access.intrusive_testing_window} />}
      />
    </CollapsableContent>
  );
};

export default Access;
