import { Control, useController } from 'react-hook-form';
import { GraniteSelect } from '../Select/Select';
import { splitRoleKey } from '../Navbar/utils';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useQuery } from 'react-query';
import { getAllRoles } from 'screens/RolesPermissions/utils';
import { RoleType } from 'screens/RolesPermissions/roles-permissions.types';

export type FormWithPermissions = {
  roles: { value: string; label: string }[];
};

export interface UserPermissionFeatureSwitchesProps<
  FormType extends FormWithPermissions,
> {
  control: FormType extends FormWithPermissions ? Control<FormType> : never;
  isProfile?: boolean;
  isSuperAdmin?: boolean;
}

interface RoleError {
  roles?: {
    message?: string;
    type?: string;
  };
}

export const UserRolePermissionFeatureSwitches = <
  FormType extends FormWithPermissions,
>({
  control,
  isProfile,
  isSuperAdmin = false,
}: UserPermissionFeatureSwitchesProps<FormType>) => {
  const {
    field: { ref, ...role },
    fieldState: { error: roleErrors },
  } = useController({ control, name: 'roles' });

  const { data, isLoading } = useQuery(
    ['user-form-all-roles'],
    () => getAllRoles(),
    {
      refetchOnWindowFocus: false,
      enabled: isSuperAdmin,
    },
  );
  const roles = userRolesEnumToSelectOptions(isSuperAdmin, data);

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-2xl font-semibold text-content-base-default">
        Role & permissions
      </h2>
      <GraniteSelect
        {...role}
        onChange={(newValue) => {
          role.onChange(isSuperAdmin ? newValue : [newValue]);
        }}
        label="Role"
        options={roles}
        error={(roleErrors as RoleError)?.roles?.message ?? roleErrors?.message}
        isDisabled={isProfile || isLoading}
        isMulti={isSuperAdmin}
      />
      <p className="mt-2 text-sm text-content-base-subdued">
        {role.value?.[0]?.value === 'viewer' &&
          'This user will be limited to read only permissions'}
        {role.value?.[0]?.value === 'user' &&
          'This user can have read & write or read only permissions'}
        {role.value?.[0]?.value === 'company-admin' &&
          'This user will have full read & write permissions and the ability to manage users'}
      </p>
    </div>
  );
};

const userRolesEnumToSelectOptions = (
  isSuperAdmin: boolean,
  data?: RoleType[],
) => {
  if (isSuperAdmin)
    return data?.map((r) => ({
      value: r.name,
      label: splitRoleKey(r.name, '-'),
    }));

  return ['VIEWER', 'USER', 'COMPANY_ADMIN', 'WELCOME_USER'].map((role) => ({
    value: TokenUserRoles[role as keyof typeof TokenUserRoles],
    label: splitRoleKey(role),
  }));
};
