import { NetOpsItem } from './schema';
import { getNetOpsTicketsIndex } from './api';

export interface GetDuplicateTicketsRequest {
  configuration_ids?: string;
  status: string;
  site_ids?: string;
}

export const getDuplicateTickets = async (
  request: GetDuplicateTicketsRequest,
): Promise<NetOpsItem[]> => {
  const ticketResponse = await getNetOpsTicketsIndex(request);
  return ticketResponse.data;
};
