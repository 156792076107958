import { HelpCircle } from 'react-ionicons';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';

export const FieldTooltip = ({
  children,
  title,
  placement = 'top',
  className = 'pointer-events-none',
}: {
  children: ReactNode;
  title?: string;
  placement?: Placement;
  className?: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes, forceUpdate } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement,
      modifiers: [
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ],
    },
  );

  useEffect(() => {
    forceUpdate?.();
  }, [forceUpdate, isVisible]);

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="z-auto"
      >
        <HelpCircle width="24px" height="24px" color="#94A3B8" />
      </div>
      <div
        ref={popperEltRef}
        style={{ ...styles.popper, zIndex: 99999 }}
        {...attributes.popper}
        className={className}
      >
        {isVisible && (
          <div
            className={clsx(
              'w-[328px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3',
            )}
          >
            {title && (
              <p className="mb-1 text-base font-bold text-content-base-default">
                {title}
              </p>
            )}
            {children}
          </div>
        )}
      </div>
    </Fragment>
  );
};
