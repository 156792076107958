import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { pagination } from 'shared/util/util';
import { ChevronBack, ChevronForward } from 'react-ionicons';
import { clsx } from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteSelect } from 'components/Select/Select';
import { ShortPagination } from './ShortPagination';
import { MenuPlacement } from 'react-select';
import { GraniteInput } from 'components/V2/Input/GraniteInput';

export interface PaginationProps {
  currentPage: number;
  pageCount: number;
  totalRows: number;
  currentRowsShown: number | string;
  onPageChange: (newPage: number) => void;
  pageSizeChanged: (newPage: number) => void;
  variant?: 'short' | 'default';
  hidePageSizeSelection?: boolean;
  paginationSizeStored?: number;
  pageSizeMenuPlacement?: MenuPlacement;
}

interface PageProps {
  page: string | number;
  label: string | number;
  currentPage: number;
  pageCount: number;
  onPageChange: (newPage: number) => void;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
}

const Page: React.FC<PageProps> = ({
  page,
  label,
  currentPage,
  pageCount,
  onPageChange,
  setError,
  error,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const maxDigits = useMemo(() => pageCount.toString().length, [pageCount]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (/^[0-9]$/.test(e.key)) setError(null);

    if (e.key === 'Enter' && ref.current && ref.current.value) {
      const newPage = parseInt(ref.current.value, 10);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= pageCount) {
        onPageChange(newPage);
        setIsEditing(false);
        ref.current.value = '';
      } else if (isNaN(newPage)) {
        setError('Only numbers allowed');
        ref.current.value = '';
      } else {
        setError('Number must be within available page range');
        ref.current.value = '';
      }
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setError(null);
      if (ref.current) ref.current.value = '';
    }
  };

  const onBlur = () => {
    setIsEditing(false);
    setError(null);
    if (ref.current) ref.current.value = '';
  };

  if (page === '...' && isEditing) {
    return (
      <GraniteInput
        innerInputClassName={clsx(
          '!min-h-[32px] !pl-2 !pr-1.5 !text-sm text-center',
          pageCount.toString().length > 2 ? 'w-10' : 'w-8',
        )}
        ref={ref}
        maxLength={maxDigits}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        autoFocus
        error={!!error}
      />
    );
  }

  return (
    <div
      className={clsx(
        'flex h-8 w-[85px] cursor-pointer items-center justify-center rounded-full font-bold md:w-8',
        page === currentPage &&
          'bg-button-background-primary-default text-content-flip-default',
        page !== '...' &&
          page !== currentPage &&
          'hidden text-content-base-default md:flex',
        page === '...' &&
          'hidden text-button-content-primary-disabled md:block',
      )}
      onClick={() => {
        if (page === '...') setIsEditing(true);
        else onPageChange(Number(page));
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          if (page === '...') setIsEditing(true);
          else onPageChange(Number(page));
        }
      }}
      role="button"
      tabIndex={0}
    >
      <span className="inline-flex items-center justify-center font-bold md:h-full md:w-full">
        {label}
      </span>
      {page !== '...' && (
        <span className="ml-1 block font-bold md:hidden"> of {pageCount}</span>
      )}
    </div>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageCount = 0,
  totalRows,
  onPageChange,
  pageSizeChanged,
  currentRowsShown,
  variant = 'default',
  hidePageSizeSelection = false,
  paginationSizeStored,
  pageSizeMenuPlacement,
}: PaginationProps) => {
  const [error, setError] = useState<string | null>(null);
  const options = useMemo(
    () => [
      { value: '10', label: 'Show 10' },
      { value: '20', label: 'Show 20' },
      { value: '50', label: 'Show 50' },
      { value: '100', label: 'Show 100' },
    ],
    [],
  );
  const [pageSize, setPageSize] = useState<{
    value: string;
    label: string;
  } | null>(options[1]);

  useEffect(() => {
    if (paginationSizeStored) {
      const storedOption = options.find(
        (o) => parseInt(o.value, 10) === paginationSizeStored,
      );
      if (storedOption) {
        setPageSize(storedOption);
        pageSizeChanged(paginationSizeStored);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSizeStored]);

  const [pages, setPages] = useState<(number | string)[]>([]);

  useEffect(() => {
    if (pageCount > 0) {
      setPages(pagination(currentPage, pageCount));
    } else {
      setPages([1]);
    }
  }, [currentPage, pageCount]);

  return (
    <div className="flex w-full flex-col-reverse items-end justify-start gap-6 sm:flex-row sm:items-start sm:justify-between sm:gap-0">
      <div className="flex items-center justify-between gap-6 sm:w-[330px]">
        <p className="text-base text-content-base-subdued">
          Showing {currentRowsShown} of {totalRows}
        </p>
        {!hidePageSizeSelection && (
          <div className="w-[140px]">
            <GraniteSelect
              placeholder="Select one"
              value={pageSize}
              menuPlacement={pageSizeMenuPlacement}
              onChange={(data) => {
                const val = data?.value;
                if (val) {
                  setPageSize(data);
                  pageSizeChanged(Number(val));
                }
              }}
              options={options}
            />
          </div>
        )}
      </div>
      {variant === 'default' && (
        <div className="flex flex-col gap-4 self-center">
          <div className="flex h-full items-center justify-start gap-4">
            <GraniteButton
              variant="secondary"
              size="small"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={clsx(
                'border-stroke-secondary-default flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded border !px-0',
                currentPage === 1 && 'pointer-events-none',
              )}
            >
              <ChevronBack color={'inherit'} height={'24px'} width={'24px'} />
            </GraniteButton>
            {!!pages.length &&
              pages.map((p: number | string, idx: number) => (
                <Page
                  key={idx}
                  page={p}
                  label={p as string}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageCount={pageCount}
                  setError={setError}
                  error={error}
                />
              ))}
            <GraniteButton
              variant="secondary"
              size="small"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === pageCount}
              className={clsx(
                'border-stroke-secondary-default flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded border !px-0',
                currentPage === pageCount && 'pointer-events-none',
              )}
            >
              <ChevronForward
                color={'inherit'}
                height={'24px'}
                width={'24px'}
              />
            </GraniteButton>
          </div>
          {!!error && (
            <p className="text-xs font-semibold text-red-500">{error}</p>
          )}
        </div>
      )}
      {variant === 'short' && (
        <ShortPagination
          currentPage={currentPage}
          totalPages={pageCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default Pagination;
