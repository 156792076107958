import { BasePagination, PaginableRequest } from 'api/common-schemas';
import { KeyFormType, ApiKeyType, ResponseApiKeyCreate } from './keys.types';
import showToast from 'components/Toast/Toast';
import { apiClient } from 'api/apiClient';

export const copyText = (title: string, text: string, name: string) =>
  navigator.clipboard.writeText(text).then(() => {
    showToast.confirmation({
      title: title,
      message: name,
    });
  });

export const getKeys = async (
  args: PaginableRequest,
): Promise<BasePagination<ApiKeyType>> => {
  const response = await apiClient.get('/api/v1/user_settings/api_keys', {
    params: {
      ...args,
    },
  });
  return {
    data: response.data.results,
    metadata: {
      ...response.data.info,
      total_items: response.data.info.total_records,
    },
  };
};

export const getKeyById = async (
  client_id: string,
): Promise<ApiKeyType | undefined> => {
  const response = await apiClient.get(
    `/api/v1/user_settings/api_keys/${client_id}`,
  );
  return response.data;
};

export const deleteKey = async (
  client_id: string,
): Promise<ApiKeyType | undefined> => {
  const response = await apiClient.delete(
    `/api/v1/user_settings/api_keys/${client_id}`,
  );
  return response.data;
};

export const regenerateKey = async (
  client_id: string,
): Promise<ResponseApiKeyCreate | undefined> => {
  const response = await apiClient.post(
    `/api/v1/user_settings/api_keys/${client_id}/regenerate`,
  );
  return response.data;
};

export const postKey = async (
  body: KeyFormType,
): Promise<ResponseApiKeyCreate | undefined> => {
  const response = await apiClient.post('/api/v1/user_settings/api_keys', body);
  return response.data;
};
