import React from 'react';
import Divider from 'components/Divider';
import { DropdownOption, IDropdownOption } from './DropdownMenu';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

interface DropdownGroup {
  options: IDropdownOption[];
}

interface DropdownMenuOptionsProps {
  options: Array<DropdownGroup>;
  handleOptionClick: (groupIndex: number, optionIndex: number) => void;
  className?: string;
  activeClassName?: string;
}

const DropdownMenuOptions: React.FC<DropdownMenuOptionsProps> = ({
  options,
  handleOptionClick,
  className,
}) => {
  const location = useLocation();
  return (
    <ul
      className={clsx(
        'flex-col items-start justify-center gap-1 rounded-[8px]',
        className,
      )}
    >
      {options.map((group, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="flex w-full flex-col gap-1">
            {group.options.map((option, index) => (
              <DropdownOption
                key={index}
                option={option}
                optionIndex={index}
                groupIndex={groupIndex}
                handleOptionClick={handleOptionClick}
                isActiveLink={option.link === location.pathname}
              />
            ))}
          </div>
          {groupIndex === options.length - 2 && (
            <div className="w-full py-2">
              <Divider variant="subdued" />
            </div>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default DropdownMenuOptions;
