import React, { FC, ReactNode } from 'react';

interface ContentItemProps {
  title: string;
  content: ReactNode;
}

const ContentItem: FC<ContentItemProps> = ({ content, title }) => {
  return (
    <div className="grid grid-cols-3">
      <p className="col-span-1 font-bold text-neutral-500">{title}</p>
      <div className="col-span-2">{content}</div>
    </div>
  );
};

export default ContentItem;
