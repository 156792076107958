import { forwardRef } from 'react';
import Skeleton from 'components/Skeleton/Skeleton';

export const CardSkeleton = forwardRef<HTMLDivElement>((_, ref) => (
  <div
    className="flex w-full items-center gap-x-4 rounded px-4 py-3 ring-1 ring-stroke-base-subdued"
    ref={ref}
  >
    <Skeleton className="h-6 w-6" />
    <Skeleton className="h-6 w-2/3" />
  </div>
));
CardSkeleton.displayName = 'CardSkeleton';
