import { updateUserStatus } from 'api/users/api';
import clsx from 'clsx';
import Loader from 'components/Loader/Loader';
import showToast from 'components/Toast/Toast';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

type Action = 'reset_password' | 'unlock';

export const UserActions = ({ status, id }: { status: string; id: string }) => {
  const { id: email } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ userId, action }: { userId: string; action: string }) =>
      updateUserStatus({ userId, action }),
    {
      onSuccess: (
        data: { message: string },
        variables: { userId: string; action: Action },
      ) => {
        const toastConfig = {
          reset_password: {
            title: 'Password reset email sent',
            message: 'Link will expire after 24 hours',
          },
          unlock: {
            message: 'Account unlocked',
          },
        };

        showToast.confirmation({
          ...toastConfig[variables.action],
        });
        if (email) {
          // user details page
          queryClient.invalidateQueries(['user', email]);
        } else {
          // users page
          queryClient.invalidateQueries({
            predicate: (query) =>
              Array.isArray(query.queryKey) &&
              query.queryKey[0] === 'users' &&
              typeof query.queryKey[1] === 'object',
          });
        }
      },
      onError: (error: { message: string }) => {
        showToast.error({
          message: error.message || 'Something went wrong!',
        });
      },
    },
  );

  const handleStatusUpdate = useCallback(
    (action: Action) => {
      mutate({
        userId: id,
        action,
      });
    },
    [id, mutate],
  );

  switch (status) {
    case 'PASSWORD_EXPIRED':
    case 'RECOVERY':
      return (
        <GraniteButton
          variant="tertiary"
          size="small"
          disabled={isLoading}
          onClick={() => handleStatusUpdate('reset_password')}
        >
          Reset password
          {isLoading && <Loader animationClassname="!w-3 !h-3" />}
        </GraniteButton>
      );
    case 'LOCKED_OUT':
      return (
        <GraniteButton
          variant="primary"
          size="small"
          onClick={() => handleStatusUpdate('unlock')}
          className={clsx(isLoading && 'loading-button')}
        >
          Unlock user
          {isLoading && <Loader animationClassname="!w-3 !h-3" />}
        </GraniteButton>
      );
    default:
      null;
  }
};
