import { OktaGroupProfileSchema, OktaGroupSchema } from 'api/companies/schema';
import { TokenUserPermissions, TokenUserRoles } from 'api/users/schemas/Users';
import { z } from 'zod';

export const CUSTOMER_DESCRIPTION_TYPES = z.enum([
  'Existing Customer',
  'Agent/TEM/Vendor',
  'Wholesale',
]);

export const SignUpSchema = z.discriminatedUnion('has_granite_service', [
  z.object({
    has_granite_service: z.literal(false),
    org_name: z.string().min(1, 'Company is required'),
  }),
  z.object({
    has_granite_service: z.literal(true),
    org_name: z.string().min(1, 'Company is required'),
    type: z.object({
      label: CUSTOMER_DESCRIPTION_TYPES,
      value: CUSTOMER_DESCRIPTION_TYPES,
    }),
  }),
]);

export type SignUpForm = z.infer<typeof SignUpSchema>;

export const BailOutSchema = z.object({
  macnum: z.string().min(1, 'Account # is required'),
});

export type BailOutForm = z.infer<typeof BailOutSchema>;

export const SignupResponseSchema = z.object({
  message: z.string(),
  status: z.string(),
  info: z.object({
    match: z.string(),
    org_created: z.boolean(),
    company_id: z.string(),
    ticket_id: z.number(),
    no_company: z.boolean(),
    data: z.object({
      email: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      name: z.string(),
      org_name: z.string(),
      phone_number: z.string(),
      type: z.string(),
    }),
  }),
});

export type SignupResponse = z.infer<typeof SignupResponseSchema>;

export const G360TokenSchema = z.object({
  user_id: z.string(),
  group_id: z.string(),
  roles: z.array(z.nativeEnum(TokenUserRoles)),
  permissions: z.array(z.nativeEnum(TokenUserPermissions)),
  super_admin: z.boolean().optional(),
  token: z.string(),
  group_metadata: OktaGroupProfileSchema,
  grandparent_macnum: z.string(),
  sub_groups: z.array(OktaGroupSchema),
  multiple_groups: z.boolean(),
  company_name: z.string(),
});

export type G360Token = z.infer<typeof G360TokenSchema>;
