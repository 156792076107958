import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  downloadTicketAttachment,
  getTicket,
  getTicketAttachmentsMetadata,
  getTicketFeedback,
} from '../../api/techexpress/api';
import {
  ComponentProps,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { GraniteButton } from '../../components/V2/Button/GraniteButton';
import {
  isSameDayAsDispatch,
  isTicketCancellableFn,
  isTicketCompleted,
  isTicketEditableFn,
  isTicketReadonlyFn,
} from './ticketdetail-utils';
import { TicketDetailHeader } from './TicketDetailHeader';
import { DetailField } from './TicketDetailUtil';
import { TicketDetailMeta } from './TicketDetailMeta';
import { TicketDetailActivity } from './TicketDetailActivity';
import { lookup } from 'zipcode-to-timezone';
import { formatInTimeZone } from 'date-fns-tz';
import TicketForm from './TicketEdit/TicketForm';
import { plainTextToPhoneNumber } from 'shared/util/util';
import { Download, InformationCircle } from 'react-ionicons';
import showToast from 'components/Toast/Toast';
import { PriorityBadge } from 'components/PriorityBadge/PriorityBadge';

import { TokenUserPermissions } from '../../api/users/schemas/Users';
import CancelConfirmationDialog from './CancelConfirmationDialog';
import { useModal } from 'hooks/useModal';
import { formatPhoneNumber } from 'screens/LetUsHelp/utils/formatPhoneNumber';
import {
  COMPLETE,
  TECH_ASSIGNED,
  TECH_CHECKED_IN,
} from 'api/techexpress/schema';
import format from 'date-fns/format';
import { useDownloadAttachments } from 'hooks/useDownloadAttachements';
import { useProductTourContext } from 'context/ProductTourContext';
import { getTicketMockData } from 'mocks/tour';
import ProvideFeedbackBanner from './TicketBanner/ProvideFeedbackBanner';
import ResizableLayout from 'layouts/ResizableLayout/ResizableLayout';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';

const formatTimeInTimezone = (date: Date, timezone: string): string => {
  return formatInTimeZone(date, timezone, 'hh:mm a');
};

type NotificationRecipient = {
  email: string;
  description: string;
};

export const TicketDetail = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { ticketId } = useParams();
  const { open, close, ...modalProps } = useModal();
  const { running } = useProductTourContext();

  const [currentAttachmentId, setCurrentAttachmentId] = useState<
    number | undefined
  >(undefined);
  const [currentFilename, setCurrentFilename] = useState<string | undefined>(
    undefined,
  );
  const addNoteField = useRef<HTMLTextAreaElement>(null);

  const [searchParams] = useSearchParams();
  const hasEditParam = searchParams.has('addNote');

  useEffect(() => {
    if (hasEditParam) {
      searchParams.delete('addNote');

      setTimeout(() => {
        addNoteField.current?.scrollTo({ behavior: 'instant' });
        addNoteField.current?.focus();
      }, 0);
    }
  }, [hasEditParam, searchParams]);

  const { data: ticketDetailData } = useQuery(
    [ticketId, 'ticket-detail'],
    () => getTicket(ticketId),
    {
      enabled: !!ticketId && !running,
    },
  );
  const { data: attachments } = useQuery(
    [ticketId, 'ticket-attachments'],
    () => getTicketAttachmentsMetadata(ticketId),
    {
      enabled: !!ticketId && !running,
    },
  );
  const data = running ? getTicketMockData() : ticketDetailData;

  const isTicketInCompleteStatus = useMemo(
    () => (!data ? false : isTicketCompleted(data)),
    [data],
  );

  const {
    data: ticketFeedback,
    isLoading: isTicketFeedbackLoading,
    refetch,
  } = useQuery(
    [ticketId, 'ticket-feedback'],
    () => getTicketFeedback(ticketId),
    {
      enabled: !!ticketId && isTicketInCompleteStatus,
    },
  );

  useQuery(
    [
      ticketId,
      currentAttachmentId,
      currentFilename,
      'downloaded-ticket-attachment',
    ],
    () => downloadTicketAttachment(currentAttachmentId, ticketId),
    {
      enabled: !!(currentAttachmentId && currentFilename),
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;

        if (currentFilename) {
          link.setAttribute('download', currentFilename);
          document.body.appendChild(link);
          link.click();
        }

        setCurrentAttachmentId(undefined);
        setCurrentFilename(undefined);
      },
      onError: () => {
        showToast.error({ message: 'Something went wrong!' });
      },
    },
  );

  const { downloadAllAttachments } = useDownloadAttachments({
    ticketId: ticketId as string,
    attachments: attachments as { filename: string; id?: number | undefined }[],
  });

  const notificationRecipients: NotificationRecipient[] = useMemo(() => {
    const recipients: NotificationRecipient[] = [];
    let recipientIndex = 0;

    if (data) {
      if (data.automatic_email_contact && data.contact_email_address) {
        recipients.push({
          email: data.contact_email_address,
          description: 'Recipient 1',
        });
        recipientIndex++;
      }

      data.automatic_email_cc_address?.forEach((email) =>
        recipients.push({
          email,
          description: `Recipient ${++recipientIndex}`,
        }),
      );
    }

    return recipients;
  }, [data]);

  const onSuccessSubmission = () => {
    setIsEdit(false);
    close();
  };

  const timezoneOfSite = useMemo(() => data && lookup(data?.zip), [data]);

  const isTicketReadonly = useMemo(
    () => (!data ? false : isTicketReadonlyFn(data)),
    [data],
  );

  const isTicketEditable = useMemo(
    () => (!data ? false : isTicketEditableFn(data)),
    [data],
  );

  const isTicketCancellable = useMemo(
    () => (!data ? false : isTicketCancellableFn(data)),
    [data],
  );

  if (!ticketId) {
    return <Navigate to="/techexpress" replace />;
  }

  const onAttachmentDownload = (id?: number, filename?: string) => {
    setCurrentAttachmentId(id);
    setCurrentFilename(filename);
  };

  return (
    <ResizableLayout>
      <ResizableLayout.Content>
        <TicketDetailHeader
          ticket={data}
          buttons={
            isEdit ? (
              <DisplayForPermission
                permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                upgradeTooltip
              >
                <GraniteButton
                  size="large"
                  variant="secondary"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </GraniteButton>
                <GraniteButton
                  size="large"
                  type="submit"
                  form="ticket-form"
                  key="submit-button"
                >
                  Save changes
                </GraniteButton>
              </DisplayForPermission>
            ) : isTicketReadonly || isTicketEditable ? (
              <DisplayForPermission
                permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                upgradeTooltip
              >
                {isTicketReadonly && (
                  <div className="flex items-center gap-x-1">
                    <InformationCircle
                      color="#9796F3"
                      width="20px"
                      height="20px"
                    />
                    <span className="text-sm text-content-base-default">
                      This type of ticket cannot be edited within G360
                    </span>
                  </div>
                )}
                <GraniteButton
                  onClick={() => setIsEdit(true)}
                  size="large"
                  disabled={isTicketReadonly}
                  key="edit-button"
                >
                  Edit ticket
                </GraniteButton>
              </DisplayForPermission>
            ) : !isTicketEditable ? (
              <DisplayForPermission
                permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                upgradeTooltip
              >
                {data && (
                  <InformationalBanner
                    isSameDayAsDispatch={isSameDayAsDispatch({
                      schedule: data.schedule,
                      zip: data.zip,
                    })}
                  />
                )}
                <GraniteButton
                  onClick={() => setIsEdit(true)}
                  size="large"
                  disabled={true}
                  key="edit-button"
                >
                  Edit ticket
                </GraniteButton>
              </DisplayForPermission>
            ) : null
          }
        />
        <div className="mt-12 grid grid-cols-1 items-start gap-8">
          {isTicketInCompleteStatus && !isTicketFeedbackLoading && (
            <ProvideFeedbackBanner
              feedback={ticketFeedback}
              ticketId={ticketId}
              refetch={refetch}
            />
          )}
          <TicketDetailMeta ticket={data} />
          <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow lg:gap-12">
            {isEdit ? (
              <>
                {data && (
                  <TicketForm
                    data={data}
                    attachments={attachments}
                    onSuccessSubmission={onSuccessSubmission}
                  />
                )}
                <div className="flex gap-3">
                  <DisplayForPermission
                    permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                    upgradeTooltip
                  >
                    <GraniteButton
                      size="large"
                      variant="secondary"
                      onClick={() => setIsEdit(false)}
                    >
                      Cancel
                    </GraniteButton>
                    <GraniteButton
                      size="large"
                      type="submit"
                      form="ticket-form"
                    >
                      Save changes
                    </GraniteButton>
                  </DisplayForPermission>
                </div>
              </>
            ) : (
              <>
                <ViewDetailFragment title="Ticket details">
                  <DetailField
                    description="Type of dispatch"
                    className="col-span-full"
                    value={`${data?.type_name} - ${data?.board_name}`}
                  />
                  <DetailField
                    description="Customer ticket #"
                    value={
                      data?.customer_ticket_number
                        ? `#${data?.customer_ticket_number}`
                        : undefined
                    }
                  />
                  <DetailField
                    description="Prior ticket #"
                    value={
                      data?.prior_ticket ? `#${data?.prior_ticket}` : undefined
                    }
                  />
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Date & time">
                  <DetailField
                    description="Date"
                    value={
                      data?.schedule &&
                      timezoneOfSite &&
                      format(data.schedule?.start_date, 'MM/dd/yyyy')
                    }
                  />
                  {data?.schedule?.scheduling_type === 'Requested Window' &&
                  timezoneOfSite ? (
                    <DetailField
                      description="Arrival during dispatch window"
                      value={`${formatTimeInTimezone(
                        data.schedule.start_date,
                        timezoneOfSite,
                      )}-${formatTimeInTimezone(
                        data.schedule.end_date,
                        timezoneOfSite,
                      )}`}
                    />
                  ) : null}
                  {data?.schedule?.scheduling_type === 'Hard Start' &&
                  timezoneOfSite ? (
                    <DetailField
                      description={'Arrival at specified time'}
                      value={
                        data?.schedule?.start_date &&
                        `${formatTimeInTimezone(
                          data?.schedule.start_date,
                          timezoneOfSite,
                        )}`
                      }
                    />
                  ) : null}
                  <div className="w-fit">
                    <DetailField
                      className="gap-1"
                      description="Priority"
                      value={
                        <PriorityBadge
                          className="h-6 !px-2 !py-0"
                          textClassName="!text-xs"
                          priority={
                            (data?.sub_type as 'P1' | 'P2' | 'P3') ?? 'P1'
                          }
                        />
                      }
                    />
                  </div>
                  <DetailField
                    description="Purchase order #"
                    value={data?.po_number}
                  />
                  {(data?.status_name === TECH_ASSIGNED ||
                    data?.status_name === TECH_CHECKED_IN ||
                    data?.status_name === COMPLETE) &&
                    data?.technician_name &&
                    data?.technician_phone_number && (
                      <>
                        <DetailField
                          description="Technician assigned"
                          value={data?.technician_name}
                        />
                        <DetailField
                          description="Technician phone number"
                          value={formatPhoneNumber(
                            data?.technician_phone_number
                              ? data?.technician_phone_number
                              : '',
                          )}
                        />
                      </>
                    )}
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Location details">
                  <DetailField
                    description="Address"
                    className="col-span-full"
                    value={[
                      data?.address_1,
                      data?.address_2,
                      data?.city,
                      data?.state_identifier,
                      data?.zip,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  />
                  <DetailField
                    description="Location name"
                    value={data?.location_name}
                  />
                  <DetailField
                    description="Location number"
                    value={data?.location_number && data?.location_number}
                  />
                  <DetailField
                    description="Parent account"
                    value={
                      data?.company_identifier
                        ? `#${data?.company_identifier}`
                        : null
                    }
                  />
                  <DetailField
                    description="Child account"
                    value={
                      data?.site_name
                        ? `#${data?.site_name.split('-')[0]}`
                        : null
                    }
                  />
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Scope of work & special instructions">
                  <DetailField
                    className="col-span-full"
                    description="Scope of work"
                    value={data?.initial_description}
                  />
                  <DetailField
                    className="col-span-full"
                    description="Special instructions"
                    value={data?.special_instructions}
                  />
                  <DetailField
                    description="Non-standard tools required"
                    value={data?.non_standard_tools}
                  />
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Local contact details">
                  <div className="flex flex-col gap-4">
                    <h3 className="text-xl font-bold text-content-base-subdued">
                      Local contact 1
                    </h3>
                    <DetailField
                      description="Name"
                      value={data?.local_contact_name}
                    />
                    <DetailField
                      description="Email"
                      value={data?.location_contact_email}
                    />
                    <DetailField
                      description="Phone number"
                      value={
                        data?.local_contact_number &&
                        plainTextToPhoneNumber(
                          data.local_contact_number.toString(),
                        )
                      }
                    />
                    <DetailField
                      description="Extension"
                      value={data?.location_contact_ext}
                    />
                  </div>
                  {data?.secondary_lcon_name ? (
                    <div className="flex flex-col gap-4">
                      <h3 className="text-xl font-bold text-content-base-subdued">
                        Local contact 2
                      </h3>
                      <DetailField
                        description="Name"
                        value={data?.secondary_lcon_name}
                      />
                      <DetailField
                        description="Email"
                        value={data?.secondary_lcon_email}
                      />
                      <DetailField
                        description="Phone number"
                        value={
                          data?.secondary_lcon_number &&
                          plainTextToPhoneNumber(data?.secondary_lcon_number)
                        }
                      />
                      <DetailField
                        description="Extension"
                        value={data?.secondary_lcon_ext}
                      />
                    </div>
                  ) : null}
                </ViewDetailFragment>
                {attachments?.length ? (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Attachments">
                      <div className="col-span-2 flex flex-row flex-wrap gap-4">
                        {attachments?.length ? (
                          attachments?.map(
                            ({
                              filename,
                              id,
                            }: {
                              filename?: string;
                              id?: number;
                            }) => (
                              <GraniteButton
                                key={id}
                                className="max-w-full overflow-auto"
                                variant="secondary"
                                size="large"
                                onClick={() =>
                                  onAttachmentDownload(id, filename)
                                }
                              >
                                <span
                                  className="h-1/2 max-w-[250px] truncate"
                                  title={filename}
                                >
                                  {filename}
                                </span>
                                <Download
                                  color="#F8FAFC"
                                  width="22px"
                                  height="22px"
                                />
                              </GraniteButton>
                            ),
                          )
                        ) : (
                          <div className="text-base font-bold text-content-base-subdued">
                            N/A
                          </div>
                        )}
                      </div>
                      {attachments && attachments.length > 0 ? (
                        <GraniteButton
                          className="box-border max-w-[165px]"
                          variant="secondary"
                          size="medium"
                          onClick={downloadAllAttachments}
                        >
                          <span>Export all</span>
                          <Download
                            color="#F8FAFC"
                            width="22px"
                            height="22px"
                          />
                        </GraniteButton>
                      ) : null}
                    </ViewDetailFragment>
                  </>
                ) : null}
                {notificationRecipients.length > 0 ? (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Notifications">
                      <div className="col-span-full grid grid-cols-2 gap-x-8 gap-y-12">
                        {notificationRecipients.length > 0 ? (
                          notificationRecipients.map(
                            ({ email, description }, i) => (
                              <div key={i}>
                                <DetailField
                                  value={email}
                                  description={description}
                                />
                              </div>
                            ),
                          )
                        ) : (
                          <div className="text-base font-bold text-content-base-subdued">
                            N/A
                          </div>
                        )}
                      </div>
                    </ViewDetailFragment>
                  </>
                ) : null}
                <Fragment>
                  <hr className="h-[1px] border-stroke-base-subdued" />
                  <div className="edit-ticket-product-tour flex w-max gap-4">
                    <DisplayForPermission
                      permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                      upgradeTooltip
                    >
                      <GraniteButton
                        size="large"
                        variant="destructive"
                        onClick={open}
                        disabled={!isTicketCancellable}
                      >
                        Cancel ticket
                      </GraniteButton>
                      <GraniteButton
                        onClick={() => setIsEdit(true)}
                        size="large"
                        disabled={!isTicketEditable}
                      >
                        Edit ticket
                      </GraniteButton>
                    </DisplayForPermission>
                  </div>
                </Fragment>
              </>
            )}
          </div>
          {data && (
            <CancelConfirmationDialog
              {...modalProps}
              close={close}
              ticket={data}
              onSuccessSubmission={onSuccessSubmission}
            />
          )}
        </div>
      </ResizableLayout.Content>
      <ResizableLayout.Sidebar>
        <TicketDetailActivity
          ticket={data}
          isEdit={isEdit}
          ref={addNoteField}
        />
      </ResizableLayout.Sidebar>
    </ResizableLayout>
  );
};

interface ViewDetailFragmentProps extends ComponentProps<'div'> {
  title: string;
  gridClassName?: string;
}

export const ViewDetailFragment = ({
  title,
  children,
  className,
  gridClassName,
  ...props
}: ViewDetailFragmentProps) => {
  return (
    <div className={clsx('flex flex-col gap-4', className)} {...props}>
      <h2 className="text-2xl font-bold text-content-base-default">{title}</h2>
      <div className={clsx('grid grid-cols-2 gap-x-8 gap-y-6', gridClassName)}>
        {children}
      </div>
    </div>
  );
};

const InformationalBanner = ({
  isSameDayAsDispatch,
}: {
  isSameDayAsDispatch: boolean;
}) => {
  return (
    <div className="flex items-center gap-x-1">
      <InformationCircle color="#9796F3" width="20px" height="20px" />
      <span className="text-sm text-content-base-default">
        {isSameDayAsDispatch
          ? 'Changes to the ticket cannot be made on the same day of dispatch'
          : 'This type of ticket cannot be edited within G360'}
      </span>
    </div>
  );
};
