import React, { FC } from 'react';
import { OrderedServicesType } from 'screens/InventoryAccount/inventoryAccount.types';
import Status from './Status';
import BodyItem from './BodyItem';

const OrderedServices: FC<
  Pick<OrderedServicesType, 'status' | 'includedServices' | 'locations'>
> = ({ status, includedServices, locations }) => {
  return (
    <div className="flex items-center justify-between">
      <Status status={status} />
      <div className="flex gap-6">
        <BodyItem label="Included services" value={includedServices} />
        <BodyItem label="Locations" value={locations} />
      </div>
    </div>
  );
};

export default OrderedServices;
