import React, { FC } from 'react';
import { InvoiceAddedType } from 'screens/InventoryAccount/inventoryAccount.types';
import Status from './Status';
import BodyItem from './BodyItem';

const InvoiceAdded: FC<
  Pick<InvoiceAddedType, 'status' | 'payPeriod' | 'due' | 'issued'>
> = ({ status, due, issued, payPeriod }) => {
  return (
    <div className="flex items-center justify-between">
      <Status status={status} />
      <div className="flex gap-6">
        <BodyItem label="Pay period" value={payPeriod} />
        <BodyItem label="Issued" value={issued} />
        <BodyItem label="Due" value={due} />
      </div>
    </div>
  );
};

export default InvoiceAdded;
