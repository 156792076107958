import { GraniteSelect, OptionType } from 'components/Select/Select';
import { useFeatureFlags } from 'feature-flags';

export const switchTableOptions = [
  { label: 'View by service', value: 'service' },
  { label: 'View by account', value: 'account' },
];

const SwitchTable = ({
  setTableView,
  selectedTableView,
}: {
  setTableView: React.Dispatch<React.SetStateAction<OptionType>>;
  selectedTableView: OptionType;
}) => {
  const { flags } = useFeatureFlags();

  if (!flags.INVENTORY_ACCOUNT_INDEX_VIEW) return;
  return (
    <GraniteSelect
      options={switchTableOptions}
      className="!box-border w-full md:max-w-[190px]"
      isSearchable={false}
      value={selectedTableView}
      onChange={(selectedItem) => {
        if (selectedItem) {
          setTableView(selectedItem);
        }
      }}
      classNames={{
        control: () => 'border-button-stroke-secondary-default',
        singleValue: () => 'text-center md:text-left',
      }}
    />
  );
};

export default SwitchTable;
