import { useEffect } from 'react';

export const useClickOutside = (
  refs: React.RefObject<HTMLElement>[],
  callback: () => void,
) => {
  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      const isInside = refs.some((ref) => {
        return ref.current && ref.current.contains(event.target as Node);
      });

      if (!isInside) {
        callback();
      }
    };

    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [refs, callback]);
};
