import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import { AlertCircle } from 'react-ionicons';

import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import LocationSection from '../BaseComponents/LocationSection';
import { PricingTier, PricingTierResponse } from 'api/accessexpress/schema';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { RequestQuoteModal } from '../BaseComponents/RequestQuoteModal';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { getQuoteBucketsRequest, selectBucketsRequest } from './utils';
import { SiteAddress } from 'api/addresssearch/schema';
import BasicPagination, {
  useBasicPagination,
} from 'components/BasicPagination';
import ResultsLoading from '../BaseComponents/ResultsLoading';
import { closeQuote, selectMoreOptions } from 'api/accessexpress/api';
import { SimpleFlowNavigationHandler } from './SimpleFlowNavigationHandler';
import { useInAppNotifications } from 'components/Notifications/InAppNotificationsContext';
import { Notification as NotificationType } from 'api/notifications/schema';
import { QuoteStatusEnum } from 'api/accessexpress/schemas';
import Loader from 'components/Loader';

export interface SelectedTierInfo {
  tier: DeepPartial<PricingTier> | null;
  sendMePricing: boolean;
}

interface OldDataType {
  quote_name: string;
  qe_quote_request_id: number;
  qe_quote_created_at: string;
  requester: string;
  status: string;
  locations: Location[];
  pricingTiers: PricingTierResponse[];
}

const REFETCH_DELAYS = [1, 2, 5, 5, 10, 20, 30, 60, 120, 120];

const LOCATIONS_PER_PAGE = 5;

export const SelectServiceBundle = () => {
  const location = useLocation();
  const { quoteId } = useParams();
  const { wizardStore } = useOpenQuoteContext();
  const navigate = useNavigate();
  const [moreOptionsSelected, setMoreOptionsSelected] = useState<{
    [locationId: string]: boolean;
  }>({});

  const queryClient = useQueryClient();
  const isSimpleFlow = location.state?.isSimpleFlow;
  const { registerFilter, unregisterFilter } = useInAppNotifications();

  const simpleFlowSameLocationFilter = useCallback(
    (notification: NotificationType) =>
      notification.actions?.some(
        (action) => action.href === location.pathname,
      ) ?? false,
    [location.pathname],
  );

  useEffect(() => {
    registerFilter(simpleFlowSameLocationFilter);
    return () => unregisterFilter(simpleFlowSameLocationFilter);
  }, [registerFilter, simpleFlowSameLocationFilter, unregisterFilter]);

  const retryIndex = useRef(0);
  const { data, isLoading, refetch } = useQuery(
    ['access-express-quote-buckets', quoteId],
    () => getQuoteBucketsRequest(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        if (
          data?.status !== QuoteStatusEnum.enum['Service results ready'] &&
          retryIndex.current < REFETCH_DELAYS.length
        ) {
          const delay = REFETCH_DELAYS[retryIndex.current] * 1000;
          setTimeout(() => {
            refetch();
          }, delay);
          retryIndex.current++;
        }
      },
    },
  );
  const locationPricingTiers = data?.pricingTiers;
  const hasTwoProductTypes = data?.hasTwoProductTypes;

  useEffect(() => {
    if (data?.qe_quote_request_id)
      wizardStore.setQeQuoteRequestId(data.qe_quote_request_id);
  }, [data, wizardStore]);

  useEffect(() => {
    wizardStore.setStep(3);
  }, [wizardStore]);

  const [selectedTiers, setSelectedTiers] = useState<{
    [key: string]: SelectedTierInfo;
  }>({});

  const { openWithProps, ...modalProps } = useModal<{
    [key: string]: SelectedTierInfo;
  }>();

  useEffect(() => {
    if (locationPricingTiers) {
      const initialSelectedTiers = locationPricingTiers.reduce(
        (acc, loc) => {
          const advancedTier = loc.pricing_tiers.find((tier) => tier.selected);
          acc[loc.id] = {
            tier: advancedTier ? { ...advancedTier } : null,
            sendMePricing: loc.sendMePricing,
          };
          return acc;
        },
        {} as { [key: string]: SelectedTierInfo },
      );
      setSelectedTiers(initialSelectedTiers);

      const initialSelectedMoreOptions = locationPricingTiers.reduce(
        (acc, loc) => ({
          ...acc,
          [loc.id]: loc.sendMePricing,
        }),
        {} as { [key: string]: boolean },
      );
      setMoreOptionsSelected(initialSelectedMoreOptions);
    }
  }, [locationPricingTiers]);

  const [isBlockerDisabled, setIsBlockerDisabled] = useState(false);

  const handleChooseService = useCallback(() => {
    setIsBlockerDisabled(true);
    setTimeout(() => {
      navigate('/access-express/open-quote/add-configurations', {
        state: {
          quoteId,
          addressValue: {
            address_line_1:
              locationPricingTiers?.[0].location.address_line_1 ?? '',
            address_line_2:
              locationPricingTiers?.[0].location.address_line_2 ?? '',
            city: locationPricingTiers?.[0].location.city ?? '',
            state: locationPricingTiers?.[0].location.state ?? '',
            zip: locationPricingTiers?.[0].location.zip ?? '',
          } as SiteAddress,
          selectedOptions: Array.from(
            new Set(
              locationPricingTiers?.map(
                (loc) => loc.product_type?.toLowerCase(),
              ) ?? [],
            ),
          ),
        },
      });
    }, 0);
  }, [locationPricingTiers, navigate, quoteId]);

  const productTypesCount = hasTwoProductTypes ? 2 : 1;

  const { from, to, ...paginationProps } = useBasicPagination(
    locationPricingTiers?.length ?? 0,
    LOCATIONS_PER_PAGE * productTypesCount,
  );

  const handleSelectTier = (
    locationId: string,
    tier: DeepPartial<PricingTier>,
  ) => {
    setSelectedTiers((prev) => {
      const isSelected = prev[locationId]?.tier?.name === tier.name;
      const _selectBucketsRequest = async () => {
        const res = await selectBucketsRequest({
          quote_id: tier.quote_id ?? '',
          product_id: tier.product_id ?? '',
          bucket_id: tier.bucket_id ?? '',
          selected: !isSelected,
        });
        if (res.status === 204) refetch();
      };
      _selectBucketsRequest();
      if (isSelected) {
        return {
          ...prev,
          [locationId]: {
            ...prev[locationId],
            tier: null, // Deselect the tier
          },
        };
      }

      return {
        ...prev,
        [locationId]: {
          tier,
          sendMePricing: prev[locationId]?.sendMePricing || false,
        },
      };
    });
  };

  const onRequestQuoteClick = () => openWithProps(selectedTiers);

  const closeQuoteMutation = useMutation(closeQuote, {
    onSuccess: () => {
      navigate('/access-express/index');
    },
  });

  const onCloseQuoteRequestClick = () => {
    if (closeQuoteMutation.isLoading) return;
    quoteId && closeQuoteMutation.mutate(quoteId);
  };

  const handleRemoveLocation = (locationId: string) => {
    setSelectedTiers((prevSelectedTiers) => {
      const { [locationId]: _, ...remainingTiers } = prevSelectedTiers;
      return remainingTiers;
    });

    setMoreOptionsSelected((prevMoreOptions) => {
      const { [locationId]: _, ...remainingMoreOptions } = prevMoreOptions;
      return remainingMoreOptions;
    });

    queryClient.setQueryData<OldDataType | undefined>(
      ['access-express-quote-buckets', quoteId],
      (oldData) => {
        if (!oldData) return oldData;
        const updatedPricingTiers = oldData.pricingTiers.filter(
          (tier) => !tier.id.startsWith(locationId),
        );

        return {
          ...oldData,
          pricingTiers: updatedPricingTiers,
        };
      },
    );
  };

  const handleMoreOptionsSelect = (locationId: string) => {
    const newSelection = !moreOptionsSelected[locationId];

    const [quoteId, productId] = locationId.split('|');
    selectMoreOptions(quoteId, productId, newSelection);

    setMoreOptionsSelected((prev) => ({
      ...prev,
      [locationId]: newSelection,
    }));
  };

  const unselectedLocationsCount = Object.entries(selectedTiers).filter(
    ([locationId, tierInfo]) => {
      const location = locationPricingTiers?.find(
        (loc) => loc.id === locationId,
      );
      const hasTiers = (location?.pricing_tiers?.length ?? 0) > 0;
      return !tierInfo.tier && !moreOptionsSelected[locationId] && hasTiers;
    },
  ).length;

  // This redirection mitigates issues when users click on notifications
  // that are no longer relevant, preventing navigation to outdated and
  // non-responsive pages.
  if (data?.status === QuoteStatusEnum.Enum['Quote requested']) {
    navigate(`/access-express/quote-details/${quoteId}`);
    return;
  }

  const hasSelecetedTiers = Object.values(selectedTiers).some(
    (tier) => tier.tier,
  );

  const hasSelecetedMoreOptions = Object.values(moreOptionsSelected).some(
    (isSelected) => isSelected,
  );

  return (
    <OpenQuoteLayout
      className={clsx(hasTwoProductTypes ? '!grid-cols-none' : '')}
    >
      <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full flex-col items-start justify-start">
          <h2 className="mb-2 text-2xl font-bold text-content-base-default">
            Choose from the below service packages for each location
          </h2>
        </div>
        {isLoading && (
          <div
            className={clsx('gap-4 rounded bg-background-base-surface-1 p-4')}
          >
            <ResultsLoading />
          </div>
        )}
        <div
          className={clsx(
            hasTwoProductTypes
              ? '2xl:-mx-4 2xl:-my-4 2xl:flex 2xl:flex-wrap'
              : '',
          )}
        >
          {locationPricingTiers?.slice(from, to).map((location, index) => {
            return (
              <div
                key={location.id}
                className={clsx(
                  'py-4',
                  hasTwoProductTypes && '2xl:w-1/2 2xl:px-4',
                )}
              >
                <LocationSection
                  mode="update"
                  location={location}
                  onSelectedTier={(tier) =>
                    handleSelectTier(location.id, tier!)
                  }
                  selectedTier={selectedTiers[location.id]?.tier}
                  pricingAvailable={
                    data?.status ===
                    QuoteStatusEnum.enum['Service results ready']
                  }
                  locationPricingTiersLength={locationPricingTiers.length}
                  onRemoveLocation={() => handleRemoveLocation(location.id)}
                  onMoreOptionsSelect={() =>
                    handleMoreOptionsSelect(location.id)
                  }
                  isMoreOptionsSelected={moreOptionsSelected[location.id]}
                  defaultCollapsed={
                    paginationProps.currentPage > 1 ||
                    index >= productTypesCount
                  }
                  isLoading={isLoading}
                  shouldAnimate={isSimpleFlow}
                  warningMsg="This service will be excluded from the quote request if a package is not selected."
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row items-center">
          <GraniteButton
            variant="secondary"
            onClick={handleChooseService}
            className="whitespace-nowrap"
          >
            Add locations & services
          </GraniteButton>
          <BasicPagination {...paginationProps} />
        </div>
      </div>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {unselectedLocationsCount > 0 && (
          <div className="flex items-center gap-4 rounded border border-status-warning-default p-4 text-status-warning-default">
            <WarningIcon />
            <span className="text-base font-bold text-content-base-default">
              ({unselectedLocationsCount}) service
              {unselectedLocationsCount !== 1 ? 's' : ''} will be excluded from
              this quote request.
            </span>
          </div>
        )}
        {!hasSelecetedTiers && hasSelecetedMoreOptions && (
          <div className="flex items-center gap-4 rounded border border-status-info-default p-4 text-status-warning-default">
            <span className="text-status-info-default">
              <AlertCircle width="24px" height="24px" color="currentColor" />
            </span>
            <span className="text-base font-bold text-content-base-default">
              Since service packages have not been selected, click the
              &apos;Close quote request&apos; button to finalize this request
              without generating a formal quote.
            </span>
          </div>
        )}
        <div className="flex gap-4">
          <GraniteButton
            variant="secondary"
            size="large"
            className={clsx(hasTwoProductTypes ? '' : 'w-full')}
            onClick={() => navigate('/access-express/index')}
          >
            Back to quotes
          </GraniteButton>
          {!hasSelecetedTiers && hasSelecetedMoreOptions ? (
            <GraniteButton
              size="large"
              className={clsx(
                'whitespace-nowrap',
                hasTwoProductTypes ? '' : 'w-full',
              )}
              onClick={onCloseQuoteRequestClick}
            >
              Close quote request
              {closeQuoteMutation.isLoading && (
                <Loader animationClassname="!w-3 !h-3" />
              )}
            </GraniteButton>
          ) : (
            <GraniteButton
              size="large"
              className={clsx(hasTwoProductTypes ? '' : 'w-full')}
              onClick={onRequestQuoteClick}
              disabled={!(hasSelecetedTiers || hasSelecetedMoreOptions)}
            >
              Request quote
            </GraniteButton>
          )}
        </div>
      </div>
      <RequestQuoteModal
        {...modalProps}
        selectedTiers={selectedTiers}
        quoteName={isSimpleFlow ? '' : data?.quote_name ?? ''}
        quoteId={quoteId ?? ''}
        onBeforeExit={() => setIsBlockerDisabled(true)}
      />

      {isSimpleFlow && !isBlockerDisabled && (
        <SimpleFlowNavigationHandler
          quoteId={quoteId || ''}
          block={!isBlockerDisabled}
          notify={
            data?.status === QuoteStatusEnum.Enum['Checking availability']
          }
        />
      )}
    </OpenQuoteLayout>
  );
};

const WarningIcon = ({ color = 'currentColor' }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
  >
    <path
      d="M19.05 15.7069L11.0611 0.870937C10.4948 -0.180938 8.98637 -0.180938 8.41965 0.870937L0.431217 15.7069C0.30827 15.9352 0.24663 16.1916 0.252313 16.4508C0.257997 16.7101 0.33081 16.9635 0.463646 17.1863C0.596482 17.409 0.784801 17.5935 1.01022 17.7217C1.23564 17.85 1.49046 17.9176 1.74981 17.918H17.729C17.9886 17.918 18.2437 17.8507 18.4694 17.7226C18.6952 17.5945 18.8838 17.4101 19.0169 17.1873C19.15 16.9644 19.2231 16.7109 19.2289 16.4514C19.2346 16.1919 19.173 15.9354 19.05 15.7069ZM9.74059 15.6211C9.55517 15.6211 9.37392 15.5661 9.21974 15.4631C9.06557 15.3601 8.94541 15.2137 8.87445 15.0424C8.8035 14.8711 8.78493 14.6826 8.82111 14.5007C8.85728 14.3188 8.94657 14.1518 9.07768 14.0207C9.20879 13.8896 9.37584 13.8003 9.55769 13.7641C9.73955 13.7279 9.92805 13.7465 10.0994 13.8175C10.2707 13.8884 10.4171 14.0086 10.5201 14.1627C10.6231 14.3169 10.6781 14.4982 10.6781 14.6836C10.6781 14.8067 10.6538 14.9286 10.6067 15.0424C10.5596 15.1561 10.4906 15.2595 10.4035 15.3465C10.3164 15.4336 10.2131 15.5026 10.0994 15.5497C9.98561 15.5968 9.86371 15.6211 9.74059 15.6211ZM10.7587 6.19219L10.4897 11.9109C10.4897 12.1098 10.4106 12.3006 10.27 12.4413C10.1293 12.5819 9.93857 12.6609 9.73965 12.6609C9.54074 12.6609 9.34998 12.5819 9.20932 12.4413C9.06867 12.3006 8.98965 12.1098 8.98965 11.9109L8.72059 6.19453C8.71455 6.05793 8.73606 5.92151 8.78386 5.79341C8.83166 5.6653 8.90476 5.54813 8.99881 5.44888C9.09285 5.34963 9.20592 5.27033 9.33127 5.2157C9.45662 5.16108 9.59168 5.13225 9.7284 5.13094H9.73825C9.87591 5.13087 10.0122 5.15869 10.1388 5.21272C10.2654 5.26675 10.3797 5.34587 10.4749 5.44531C10.5701 5.54475 10.6442 5.66245 10.6926 5.7913C10.7411 5.92015 10.7629 6.05748 10.7568 6.195L10.7587 6.19219Z"
      fill={color}
    />
  </svg>
);
