import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Add, InformationCircle } from 'react-ionicons';
import { zodResolver } from '@hookform/resolvers/zod';

import FormSection from 'components/FormSection';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Divider from 'components/Divider';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { Locations } from './Locations/Locations';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { DIAServices } from './DIAServices';
import { BroadbandServices } from './BroadbandService';
import { Controller, useForm } from 'react-hook-form';
import {
  ChooseServicesSchema,
  ChooseServicesSchemaType,
  ProductDataType,
} from './schemas';
import { useQuery } from 'react-query';
import { deleteQuote, fetchAccessStatisData } from 'api/accessexpress/api';
import { useAuthUser } from 'hooks/useAuthUser';
import { createQuoteRequest, loadDraft, saveDraft } from './utils';
import { useModal } from 'hooks/useModal';
import CheckAvailabilityDialog from '../BaseComponents/CheckAvailabilityDialog';
import { LoaderGranite } from 'components';
import { DraftStatusNavigationHandler } from './DraftStatusNavigationHandler';
import Loader from 'components/Loader/Loader';

type ServiceType = 'broadband' | 'dia';

type ServiceCombinationType = ServiceType[] & { length: 0 | 1 | 2 };

export const AddConfigurations = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] =
    useState<ServiceCombinationType>(location.state?.selectedOptions || []);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);

  const locationValue = location.state?.addressValue;
  const previousQuoteId = location.state?.quoteId;
  const draftId = location.state?.draftId;
  const { wizardStore } = useOpenQuoteContext();
  // const { open, ...discardModalProps } = useModal();
  const { open: openCheckAvailabilityModal, ...checkAvailabilityModalProps } =
    useModal();

  const { data: staticData } = useQuery(
    ['static-data'],
    () => fetchAccessStatisData(),
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    wizardStore.setStep(1);
  }, [wizardStore]);

  const userInfo = useAuthUser();

  const { control, handleSubmit, formState, setValue, getValues } =
    useForm<ChooseServicesSchemaType>({
      resolver: zodResolver(ChooseServicesSchema),
      mode: 'all', // Enable validation on input changes
      defaultValues: {
        locations: locationValue ? [locationValue] : [],
      },
    });

  const { isFetching: isDraftDataFetching, data: draftData } = useQuery(
    ['draftData', draftId],
    () => loadDraft(draftId),
    {
      enabled: !!draftId,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setValue('locations', data.locations);
        //@ts-expect-error err
        products.current = data.products;
        const draftSelectedOptions = Object.keys(
          data.products,
        ) as ServiceCombinationType;
        setSelectedOptions(draftSelectedOptions);
        // resetChangeDetection([data.locations, data.products, selectedOptions]);
      },
    },
  );

  const products = useRef<ProductDataType>({
    broadband: null,
    dia: null,
  });

  // const hasChanged = useRef(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [proceedWithSubmit, setProceedWithSubmit] = useState(false);

  const withChange = useCallback((fn: () => void) => {
    setHasChanged(true);
    fn();
  }, []);

  const onSubmit = async ({
    data,
    quoteName,
    submitDraft = false,
  }: {
    data: ChooseServicesSchemaType;
    quoteName: string;
    submitDraft?: boolean;
  }) => {
    if (previousQuoteId) {
      await deleteQuote(previousQuoteId);
    }

    const quoteRequestData = data.locations.map((location) => {
      return {
        ...location,
        products: selectedOptions.map((product) => products.current[product]),
      };
    });

    setProceedWithSubmit(true);

    try {
      if (submitDraft) {
        await saveDraft(
          userInfo,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          quoteRequestData,
          quoteName,
          draftId,
        );
        return;
      }
      await createQuoteRequest(
        userInfo,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        quoteRequestData,
        quoteName,
        draftId,
      );
      return;
    } catch (error) {
      setProceedWithSubmit(false);
    }
  };

  const handleAddLocationClick = useCallback(() => {
    // Automatically add 'broadband' if at least one location is added and no services are selected
    if (selectedOptions.length === 0) {
      setSelectedOptions(['broadband']);
    }
  }, [selectedOptions]);

  const handleAddService = useCallback(
    (service: ServiceType) => {
      setSelectedOptions([
        ...selectedOptions,
        service,
      ] as ServiceCombinationType);
    },
    [selectedOptions],
  );

  const handleRemoveService = useCallback((service: ServiceType) => {
    setSelectedOptions(
      (prev) => prev.filter((s) => s !== service) as ServiceCombinationType,
    );
  }, []);

  const handleCheckAvailabilityClick = async () => {
    if (draftData?.quoteName) {
      setIsSubmittingDraft(true);
      try {
        await onSubmit({
          data: getValues(),
          quoteName: draftData?.quoteName,
        });
        openCheckAvailabilityModal();
      } catch (error) {
        console.error('Error during draft submission:', error);
      } finally {
        setIsSubmittingDraft(false);
      }
    } else {
      openCheckAvailabilityModal();
    }
  };

  const AddDIAButton = () => (
    <GraniteButton
      variant="secondary"
      className="!box-border !max-w-[200px]"
      onClick={() => withChange(() => handleAddService('dia'))}
    >
      <span className="whitespace-nowrap">Add DIA services</span>
      <Add width="20px" height="20px" color="inherit" />
    </GraniteButton>
  );

  const AddBroadbandButton = () => (
    <GraniteButton
      variant="secondary"
      className="!box-border !max-w-[240px]"
      onClick={() => withChange(() => handleAddService('broadband'))}
    >
      <span className="whitespace-nowrap">Add Broadband services</span>
      <Add width="20px" height="20px" color="inherit" />
    </GraniteButton>
  );

  const hasCompleteFormData =
    !formState.errors.locations && !!selectedOptions.length;

  return (
    <form>
      <OpenQuoteLayout>
        <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
          {!isDraftDataFetching ? (
            <>
              <FormSection title="Your locations">
                <Controller
                  control={control}
                  name="locations"
                  render={({ field }) => (
                    <Locations
                      locations={field.value}
                      onChange={(value) =>
                        withChange(() => field.onChange(value))
                      }
                      onAddLocationClick={handleAddLocationClick}
                    />
                  )}
                />
              </FormSection>
              <Divider />

              {selectedOptions.length ? (
                selectedOptions.map((service, index, services) => (
                  <React.Fragment key={service}>
                    {service === 'broadband' && (
                      <>
                        <div>
                          {staticData && (
                            <BroadbandServices
                              staticData={staticData}
                              broadband={products.current.broadband}
                              onChange={(data) =>
                                (products.current.broadband = data)
                              }
                              onUserChange={() => withChange(() => {})}
                            />
                          )}
                          <GraniteButton
                            variant="destructive"
                            className="mt-6"
                            onClick={() =>
                              withChange(() => handleRemoveService('broadband'))
                            }
                          >
                            Delete
                          </GraniteButton>
                        </div>
                        {!selectedOptions.includes('dia') && <AddDIAButton />}
                      </>
                    )}
                    {service === 'dia' && (
                      <>
                        <div>
                          {staticData && (
                            <DIAServices
                              staticData={staticData}
                              dia={products.current.dia}
                              onChange={(data) => (products.current.dia = data)}
                              onUserChange={() => withChange(() => {})}
                            />
                          )}
                          <GraniteButton
                            variant="destructive"
                            className="mt-6"
                            onClick={() =>
                              withChange(() => handleRemoveService('dia'))
                            }
                          >
                            Delete
                          </GraniteButton>
                        </div>
                        {!selectedOptions.includes('broadband') && (
                          <AddBroadbandButton />
                        )}
                      </>
                    )}
                    {index < services.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <div className="flex flex-row gap-4">
                  <AddBroadbandButton />
                  <AddDIAButton />
                </div>
              )}
            </>
          ) : (
            <LoaderGranite />
          )}
        </div>
        <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
          {!selectedOptions.length && !isDraftDataFetching && (
            <div className="flex items-center gap-2 rounded bg-blue-900 p-4 ring-1 ring-purple-300">
              <InformationCircle color={'rgba(151, 150, 243, 1)'} />
              <p className="font-bold text-white">
                {`You must configure at least one service before you can continue`}
              </p>
            </div>
          )}
          <div className="flex gap-4">
            <GraniteButton
              size="large"
              variant="secondary"
              className="w-full"
              onClick={() => navigate(-1)}
            >
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              className={clsx(
                `button primary large w-full`,
                // 0 === 0 && 'disabled-link',
                isSubmittingDraft && 'loading-button',
              )}
              type="button"
              disabled={
                !formState.isValid ||
                !selectedOptions.length ||
                isSubmittingDraft
              }
              onClick={handleCheckAvailabilityClick}
            >
              Check availability
              {isSubmittingDraft && <Loader animationClassname="!w-3 !h-3" />}
            </GraniteButton>
          </div>
        </div>
        <CheckAvailabilityDialog
          {...checkAvailabilityModalProps}
          quoteName={draftData?.quoteName}
          onConfirmation={async ({ quoteName }) => {
            return await handleSubmit((data) =>
              onSubmit({ data, quoteName }),
            )();
          }}
        />
        <DraftStatusNavigationHandler
          quoteId={draftId}
          quoteName={draftData?.quoteName}
          block={
            !proceedWithSubmit &&
            hasCompleteFormData &&
            (!draftData || hasChanged)
          }
          isDraftAlready={!!draftId}
          onSubmit={async ({ quoteName }) =>
            await handleSubmit((data) => {
              onSubmit({ data, quoteName, submitDraft: true });
            })()
          }
        />
      </OpenQuoteLayout>
    </form>
  );
};
