import { z } from 'zod';
import {
  createPaginatedResponseSchema,
  SortableFieldEnum,
} from '../common-schemas';

export const QuoteStatusEnum = z.enum([
  'PRICING_AVAILABLE_WITH_ERRORS',
  'CHECKING_AVAILABILITY',
  'QUOTE_REQUESTED',
  'PRICING_AVAILABLE',
  'PRICING_EXPIRED',
  'QE_CREATE_FAILED',
  'Checking availability',
  'Pricing expired',
  'Quote requested',
  'Pricing available',
  'Formal pricing available',
  'QE error',
  'Order submitted',
  'Order services',
  'Quote ready',
  'Order services',
  'Quote expired',
  'Service results ready',
  'Service results expired',
  'Closed',
  'Resubmit required',
  'Draft',
]);

export const QuoteIndexItemSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  request_number: z
    .union([z.string(), z.coerce.string(), z.coerce.number()])
    .nullable(),
  status: QuoteStatusEnum.nullable(),
  last_update: z.coerce.date().optional(),
  date_submitted: z.coerce.date().nullable(),
  expiration_date: z.coerce.date().optional().nullable(),
  company_id: z.number().nullable(),
  requester: z.string().nullable(),
  submitter: z.string().nullable().optional(),
  customer_name: z.string().nullable().optional(),
  formal_quote_filename: z.string().nullable().optional(),
  flow_type: z.string().nullable().optional(),
});

export type QuoteIndexItem = z.infer<typeof QuoteIndexItemSchema>;

export const QuoteIndexFilterSearchParamsSchema = z
  .object({
    // filters
    search: z.string(),
    status: z.string(),
    requester: z.string(),
    // sorting
    name_order: SortableFieldEnum,
    request_number_order: SortableFieldEnum,
    status_order: SortableFieldEnum,
    last_update_order: SortableFieldEnum,
    date_submitted_order: SortableFieldEnum,
    expiration_date_order: SortableFieldEnum,
    requester_order: SortableFieldEnum,
    customer_name_order: SortableFieldEnum,
    start_date: z.string().datetime(),
    end_date: z.string().datetime(),
    // pagination
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type QuoteIndexFilterSearchParams = z.infer<
  typeof QuoteIndexFilterSearchParamsSchema
>;

export const QuoteIndexAPIResponseSchema =
  createPaginatedResponseSchema(QuoteIndexItemSchema);

export type QuoteIndexAPIResponse = z.infer<typeof QuoteIndexAPIResponseSchema>;

export const AccessStaticData = z.object({
  requester_options: z.array(z.any()),
});

export type AccessStaticDataType = z.infer<typeof AccessStaticData>;
