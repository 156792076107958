import {
  CustomizeTHeadProps,
  CustomizeTHeadReturn,
  exportWithPreferencesReturnType,
  PreferencesType,
  PreferencesTypeWithColumn,
  ResponsePreferencesModel,
} from 'components/Table/Customize/customize.types';
import {
  getPreferenceByTableName,
  postPreferenceByTableName,
  restorePreferenceByTableName,
} from 'components/Table/Customize/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

const isNonNull = <T>(value: T | null): value is T => value !== null;

export const useCustomizeTHead = <T extends object>({
  columns,
  tableName,
  mockPreferencesFn,
  onPreferenceRemoved,
  customizeFirstColumnMobileKey,
}: CustomizeTHeadProps<T>): CustomizeTHeadReturn<T> => {
  const columnsRef = useRef(columns);
  const [open, setOpen] = useState(false);
  const [preferences, setPreferences] = useState<
    PreferencesTypeWithColumn<T>[]
  >([]);

  const onClick = () => {
    setOpen((prev) => {
      if (prev === true && !isLoading) postPreferences();
      return !prev;
    });
  };

  const { isLoading } = useQuery(
    `index-preferences-${tableName}`,
    () =>
      mockPreferencesFn
        ? mockPreferencesFn()
        : getPreferenceByTableName({ tableName }),
    {
      onSuccess: (data) => data && mapPreferences(data),
    },
  );

  const { mutate: postPreferences } = useMutation(
    () =>
      postPreferenceByTableName({
        tableName,
        body: preferences.map((p) => ({ enabled: p.enabled, field: p.field })),
      }),
    {
      onSuccess: (data) => data && mapPreferences(data),
    },
  );

  const { mutate: restorePreference, ...restoreMutation } = useMutation(
    () => restorePreferenceByTableName({ tableName }),
    {
      onSuccess: (data) => data && mapPreferences(data),
    },
  );

  const mapPreferences = useCallback((data: ResponsePreferencesModel) => {
    const result = data
      ?.map((pref) => {
        const column = columnsRef.current.find((col) => col.id === pref.field);
        if (column) {
          return {
            enabled: pref.enabled,
            field: pref.field,
            column: column,
            header:
              typeof column.header === 'string'
                ? column.header
                : column.meta?.name || 'No name',
          };
        }
        return null;
      })
      .filter(isNonNull);
    setPreferences(result);
  }, []);

  const hasFilterByPreference = (field: string): boolean =>
    preferences.filter((p) => p.enabled).some((p) => p.field === field);

  const onToggle = useCallback(
    (field: PreferencesType['field']) =>
      setPreferences((prev) =>
        prev.map((pref) => {
          if (pref.field === field && pref.enabled) {
            onPreferenceRemoved?.(field);
          }
          return pref.field === field
            ? { ...pref, enabled: !pref.enabled }
            : pref;
        }),
      ),
    [onPreferenceRemoved],
  );

  const exportWithPreferences = <T extends exportWithPreferencesReturnType>(
    data: T[],
  ) => {
    return data.map((item) => {
      const result: exportWithPreferencesReturnType = {};

      preferences.forEach((pref) => {
        if (pref.enabled && pref.field in item) {
          result[pref.header] = item[pref.field];
        }
      });

      return result;
    });
  };

  const customizeFirstColumnMobileClass = useMemo(() => {
    const columns = preferences.filter((p) => p.enabled).map((p) => p.column);
    let className: string = 'table-column-mobile';
    if (columns[0]?.id === customizeFirstColumnMobileKey)
      className = 'table-column-mobile sticky-first-column-mobile';
    return className;
  }, [preferences, customizeFirstColumnMobileKey]);

  return {
    columns: preferences.filter((p) => p.enabled).map((p) => p.column),
    isPreferenceLoading: isLoading,
    enabledPreferences: preferences
      .filter((p) => p.enabled)
      .map((p) => ({ enabled: p.enabled, field: p.field, header: p.header })),
    hasFilterByPreference,
    exportWithPreferences,
    customizeFirstColumnMobileClass,
    customizeProps: {
      open,
      onClick,
      preferences,
      setPreferences,
      isLoading,
      onToggle,
      restore: {
        onRestore: restorePreference,
        isLoading: restoreMutation.isLoading,
      },
    },
  };
};
