import { useMemo } from 'react';
import Tabs from 'components/Table/Tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, Ticket, Wifi } from 'react-ionicons';

enum TabsEnum {
  'overview' = 0,
  'services' = 1,
  'activity' = 2,
}

const NavigationTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabs = useMemo(() => {
    return [
      {
        icon: <Home width="20px" height="20px" color="currentColor" />,
        title: 'Overview',
        onClick: () => navigate(''),
      },
      {
        icon: <Wifi width="20px" height="20px" color="currentColor" />,
        title: 'Services',
        onClick: () => navigate('services'),
      },
      {
        icon: <Ticket width="20px" height="20px" color="currentColor" />,
        title: 'Activity',
        onClick: () => navigate('activity'),
      },
    ];
  }, [navigate]);
  const activeTab = useMemo(() => {
    if (pathname.includes('/services')) return TabsEnum.services;
    if (pathname.includes('/activity')) return TabsEnum.activity;
    return TabsEnum.overview;
  }, [pathname]);
  return (
    <Tabs
      variant="medium"
      withUnderline={false}
      defaultActiveTab={activeTab}
      tabs={tabs}
    />
  );
};

export default NavigationTabs;
