import { useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Loader from 'components/Loader';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import {
  RequestQoute,
  RequestQuoteSchema,
} from '../SelectServiceBundle/schema';
import showToast from 'components/Toast/Toast';
import { useQueryClient } from 'react-query';

interface DraftStatusNavigationHandlerProps {
  quoteId: string;
  quoteName?: string;
  block: boolean;
  isDraftAlready: boolean;
  onSubmit: (data: { quoteName: string }) => Promise<void>;
}

export const DraftStatusNavigationHandler = (
  props: DraftStatusNavigationHandlerProps,
) => {
  const { block, quoteName, onSubmit: onSubmitDraft, isDraftAlready } = props;
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const blocker = useBlocker(() => block);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<RequestQoute>({
    resolver: zodResolver(RequestQuoteSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async (request: RequestQoute) => {
    if (isLoading) return;
    setLoading(true);
    try {
      await onSubmitDraft({ quoteName: request.name });
      showToast.confirmation({
        message: 'Draft saved!',
      });
      queryClient.invalidateQueries('quote-index-table');
      setTimeout(() => {
        navigate('/access-express/index');
      }, 200);
    } finally {
      setLoading(false);
    }
  };

  const onError = (err: unknown) => console.log(err);

  const handleCloseModal = () => {
    if (step === 2) {
      setStep(1);
    }

    reset({});
    blocker.reset?.();
  };

  const handleReject = () => {
    blocker.proceed?.();
  };

  const handleSaveAsDraft = () => {
    if (!quoteName) {
      setStep(2);
    } else {
      onSubmit({ name: quoteName });
    }
  };

  return (
    <>
      <Modal
        isVisible={blocker.state === 'blocked'}
        close={handleCloseModal}
        className="w-[624px]"
      >
        {step === 1 && (
          <div className="flex w-full flex-col items-start justify-start rounded-[8px] bg-background-base-surface-2 p-8">
            <div className="mb-8 flex flex-col items-start justify-start gap-2">
              <h1 className="text-2xl font-bold text-content-base-default">
                {isDraftAlready ? 'Save changes?' : 'Save as draft'}
              </h1>
              <p className="text-base font-bold text-content-base-subdued">
                {isDraftAlready
                  ? 'Do you want to save the changes made to this quote request?'
                  : 'Do you want to save this quote request as a draft for future use?'}
              </p>
            </div>
            <div className="flex items-start justify-start gap-4">
              <GraniteButton
                size="large"
                variant="secondary"
                onClick={handleReject}
              >
                {isDraftAlready ? 'Discard changes' : 'No, thanks'}
              </GraniteButton>
              <GraniteButton
                size="large"
                variant="primary"
                onClick={handleSaveAsDraft}
              >
                {isDraftAlready ? 'Save changes?' : 'Save as draft'}
              </GraniteButton>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="flex flex-col gap-2 rounded-lg bg-background-base-surface-2">
            <div
              className={clsx(
                'flex flex-col items-start justify-start rounded-t-lg  px-8 py-6 pb-0 font-bold',
                'bg-background-base-surface-3 !py-6',
              )}
            >
              <h1 className="text-[28px] text-content-base-default">
                Name your quote
              </h1>{' '}
            </div>
            <div className={clsx('p-8')}>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="grid grid-cols-1 gap-6"
              >
                <GraniteInput
                  label="Give this quote request a name so that you can reference it later"
                  className="col-span-1"
                  error={errors.name?.message}
                  {...register(`name`)}
                />
                <div className={clsx('flex gap-5')}>
                  <GraniteButton
                    size="large"
                    variant="secondary"
                    onClick={() => blocker.reset?.()}
                  >
                    Cancel
                  </GraniteButton>
                  <GraniteButton
                    size="large"
                    variant="primary"
                    type="submit"
                    className={clsx(isLoading && 'loading-button')}
                  >
                    Save
                    {isLoading && <Loader animationClassname="!w-3 !h-3" />}
                  </GraniteButton>
                </div>
              </form>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
