import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar } from 'components';
import { useElementPresence } from 'shared/hooks/useElementPresence';
import ProductTour from 'components/ProductTour/ProductTour';
import { InAppNotifications } from 'components/Notifications/InAppNotifications';
import { AuthProvider } from 'authContext';
import { InAppNotificationsProvider } from 'components/Notifications/InAppNotificationsContext';
import UpgradeRequestedModal from 'components/UpgradeRequestedModal/UpgradeRequestedModal';
import { useModalContext } from 'context/ModalContext';

const DefaultLayout = () => {
  const location = useLocation();
  const isWidgetOpen = useElementPresence(
    '.announcekit-widget-iframe-backdrop',
  );
  const { isOpen, closeModal } = useModalContext();

  return (
    <div
      className={clsx(
        `relative flex min-h-screen max-w-[100%] flex-col justify-start bg-background-base-surface-1 xl:flex-row`,
        location.pathname === '/' && 'home-bg bg-cover',
      )}
    >
      <InAppNotificationsProvider>
        <Navbar />
        {!sessionStorage.getItem('remindMeLater') && <ProductTour />}
        <Outlet />
        <div
          style={{ zIndex: isWidgetOpen ? 9999999999999 : 0 }}
          className={`${
            isWidgetOpen ? 'opacity-1' : 'opacity-0'
          } fixed bottom-[10px] right-[10px] max-h-[37px] w-[400px] bg-background-base-surface-2 transition-all duration-200 ease-in ${
            isWidgetOpen ? 'translate-x-0' : 'translate-x-14'
          }`}
        />
        <InAppNotifications />
        <UpgradeRequestedModal isOpen={isOpen} close={closeModal} />
      </InAppNotificationsProvider>
    </div>
  );
};

export const ProtectedDefaultLayout = () => (
  <AuthProvider>
    <DefaultLayout />
  </AuthProvider>
);
