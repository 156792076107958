import { Placement } from '@popperjs/core';
import React, { FC, HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

interface DisabledToolTipProps extends HTMLAttributes<HTMLDivElement> {
  show: boolean;
  children: ReactNode;
  placement?: Placement;
  offset?: [number, number];
}
const DisabledToolTip: FC<DisabledToolTipProps> = ({
  show,
  children,
  placement = 'top',
  offset = [0, 10],
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const referenceRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement,
      strategy: 'absolute',
      modifiers: [{ name: 'offset', options: { offset: offset } }],
    },
  );
  return (
    <div
      {...props}
      {...(show
        ? {
            ref: referenceRef,
            onMouseEnter: () => {
              setVisible(true);
              forceUpdate?.();
            },
            onMouseLeave: () => {
              setVisible(false);
            },
          }
        : {})}
    >
      {show && (
        <div
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          className={`pointer-events-none z-[100] transition-opacity duration-150 ${
            visible ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <div className="relative whitespace-nowrap rounded bg-background-base-surface-0 p-4 text-content-base-default shadow-elevation3">
            This user role requires access to all accounts
            <div className="absolute -bottom-1 right-1/2 -translate-x-1/2 scale-x-[0.84] transform">
              <div className="mt -ml-[9px] h-[17px] w-[17px] rotate-45 bg-background-base-surface-0"></div>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DisabledToolTip;
