import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';

import Checkbox from 'components/Checkbox';
import Divider from 'components/Divider';
import RadioButton from 'components/RadioButton';
import { GraniteSelect } from 'components/Select/Select';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';

import { ProductOfferingType, ProductType } from './schemas';
import {
  TermType,
  buildNestedObjectWithIds,
  groupByMultipleKeys,
} from './utils';
import { ServiceSummary } from './ServiceSummary';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  MNS_Package,
  SelectedPackages,
  useOpenQuoteServiceManager,
} from 'context/OpenQuoteServiceManagerContext';
import { ReactComponent as Pin } from 'assets/images/pin.svg';
import clsx from 'clsx';
import CheckboxTooltip from './CheckboxTooltip';

const keys = [
  'provider',
  'term',
  'bandwidth_download',
  'bandwidth_upload',
  'ip_type',
  'ip_block',
];

const SEPARATOR = '|||';

interface ServiceDetailsProps {
  data: {
    address: string;
    id: string;
    offerings: ProductOfferingType[];
    type?: string;
  };
  isPinnedSummary?: boolean;
}

interface SelectOptions {
  [key: string]: Array<{ label: string; value: string }> | undefined;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  data,
  isPinnedSummary,
}) => {
  const {
    addToCart,
    removeFromCart,
    pinService,
    unpinService,
    cartMap,
    disabledSummaryMap,
    handleProductChange: onChange,
    selectedValuesMap,
    updateSelectedValues,
    productMap,
    setProductMap,
  } = useOpenQuoteServiceManager();

  const selectedValues = useMemo(() => {
    const compositeKey = `${data.id}_${data.type}_${!!isPinnedSummary}`;
    return selectedValuesMap[compositeKey] || {};
  }, [data.id, data.type, isPinnedSummary, selectedValuesMap]);

  const normData = data?.offerings
    ?.map((d) => ({
      ...d,
      ip_block: d.ip_block ?? 'N/A',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ip_type: d?.ip_type ?? 'STATIC',
    }))
    .map((d) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (d.bandwidth) {
        return {
          ...d,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_download: d.bandwidth,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_upload: d.bandwidth,
        };
      }
      return d;
    });

  const sorted = sortBy(normData, (item) =>
    keys.map((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (item as Record<string, any>)[key];
      if (key === 'term') {
        if (value === '3 Years') {
          return '';
        }
        return value.toLowerCase();
      } else {
        return value?.toString().toLowerCase();
      }
    }),
  );

  const grouped = groupByMultipleKeys(sorted, keys);

  const keysOptionsList = Object.entries(grouped).map(([key, values]) => {
    return { key, id: values[0].id };
  });

  const nestedList = buildNestedObjectWithIds(keysOptionsList);

  const [options, setOptions] = useState<SelectOptions>({
    provider: [],
    term: [],
    bandwidth_download: [],
    bandwidth_upload: [],
    ip_block: [],
  });

  useEffect(() => {
    preselectOptions('provider', {}, !isPinnedSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preselectOptions = (
    key: string,
    newSelectedValues: Record<string, string>,
    shouldRunUpdate: boolean = true,
  ) => {
    const start = keys.indexOf(key);
    const newList: Record<string, string> = {};

    const selectedOptions: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let next: any = nestedList;

    for (let i = 0; i < keys.indexOf(key); i++) {
      selectedOptions.push(newSelectedValues[keys[i]]);
      next = next[newSelectedValues[keys[i]]];
    }

    const newOptions: Record<
      string,
      Array<{ label: string; value: string }>
    > = {};

    for (let i = start; i < keys.length; i++) {
      newOptions[keys[i]] = Object.keys(next).map((opt) => ({
        value: opt,
        label: opt ?? 'N/A',
      }));

      newList[keys[i]] = Object.keys(next)[0];

      next = next[newList[keys[i]]];
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));

    if (shouldRunUpdate)
      updateSelectedValues(data.id, data.type!, !!isPinnedSummary, newList);
  };

  const handleSelectChange = (
    key: string,
    value: string,
    // autoSelectNext = false,
  ) => {
    const newSelectedValues = {
      ...selectedValues,
      [key]: value,
    };
    const nextIndex = keys.indexOf(key) + 1;

    if (nextIndex < keys.length) {
      preselectOptions(keys[nextIndex], newSelectedValues, true);
    }

    updateSelectedValues(data.id, data.type!, !!isPinnedSummary, {
      [key]: value,
    });
  };

  const initialMNS = useMemo(() => {
    return productMap[`${data.id},${data.type},${false}`]?.mns
      ? (productMap[`${data.id},${data.type},${false}`].mns as SelectedPackages)
      : {
          essential: false,
          enhanced: false,
        };
  }, [data.id, data.type, productMap]);

  useEffect(() => {
    setMns(initialMNS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const [mns, setMns] = useState<SelectedPackages>(initialMNS);

  const handleSelectMNSChange = useCallback(
    ({ mnsPackage }: { mnsPackage: MNS_Package }) => {
      setMns((current) => {
        if (mnsPackage === 'MNS Essentials') {
          return { ...current, essential: !current.essential, enhanced: false };
        } else if (mnsPackage === 'MNS Enhanced') {
          return { ...current, enhanced: !current.enhanced, essential: false };
        }
        return { ...current };
      });
    },
    [],
  );

  const summaryData = useMemo(() => {
    const key = keys.map((key) => selectedValues[key] || 'N/A').join(SEPARATOR);
    const product = grouped[key]?.[0] as unknown as ProductType;
    const term = grouped[key]?.[0].term as TermType;
    onChange(data.id, data.type!, isPinnedSummary!, product, mns);
    return {
      ...product,
      term,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, mns]);

  const accessTypeOptions = [
    { value: 'fe', label: 'FE' },
    {
      value: summaryData?.type === 'broadband' ? 'cable' : 'ge',
      label: summaryData?.type === 'broadband' ? 'Cable' : 'GE',
    },
  ];

  const handlePin = useCallback(() => {
    const defaultServiceKey = `${data.id}_${data.type}_false`;
    updateSelectedValues(
      data.id,
      data.type!,
      true,
      selectedValuesMap[defaultServiceKey] || {},
    );
    pinService(data.id, data.type!, data);
  }, [data, updateSelectedValues, selectedValuesMap, pinService]);

  const handleUnpin = useCallback(() => {
    unpinService(data?.id, data?.type ?? '');
    // replace default mns with the pinned one
    setProductMap((current) => {
      const defaultMNS = `${data.id},${data.type},${false}`;
      const pinnedMNS = `${data.id},${data.type},${true}`;
      return {
        ...current,
        [defaultMNS]: {
          ...current[defaultMNS],
          mns: current[pinnedMNS].mns,
        },
      };
    });
  }, [data.id, data.type, setProductMap, unpinService]);

  const handleAddToCart = useCallback(() => {
    addToCart(data?.id, data?.type ?? '', !!isPinnedSummary);
  }, [data, addToCart, isPinnedSummary]);

  const handleRemoveFromCart = useCallback(() => {
    removeFromCart(data.id, data.type!, !!isPinnedSummary);
  }, [data, removeFromCart, isPinnedSummary]);

  const isAddedToCart = useMemo(
    () => !!cartMap[`${data.id}_${data.type}_${isPinnedSummary}`],
    [cartMap, data.id, data.type, isPinnedSummary],
  );

  const isDisabled =
    disabledSummaryMap[`${data.id}_${data.type}_${isPinnedSummary}`];

  const pinnedSelectsClassName = {
    control: () =>
      isPinnedSummary
        ? 'hover:!border-[#9796f3] focus:!border-[#9796f3] focus-within:!border-[#9796f3]'
        : '',
  };

  return (
    <div
      className={clsx(
        'relative flex h-full max-h-full w-full max-w-full rounded border border-background-base-surface-3 outline outline-1',
        isAddedToCart && !isPinnedSummary
          ? 'outline-stroke-accent-default'
          : 'outline-transparent',
        !isPinnedSummary &&
          disabledSummaryMap[`${data.id}_${data.type}_${false}`] &&
          'pointer-events-none opacity-25',
      )}
    >
      <div className={clsx('flex h-full max-h-full w-full flex-col p-4')}>
        <div className="flex flex-col gap-6">
          <GraniteLabel
            className={summaryData?.type === 'broadband' ? 'hidden' : ''}
            label="Connection type"
            element="div"
          >
            <GraniteSelect
              className="w-full"
              value={accessTypeOptions[1]}
              options={accessTypeOptions}
              isDisabled
            />
          </GraniteLabel>
          <GraniteLabel label="Carrier name" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('provider', selectedOption?.value || '')
              }
              value={
                selectedValues.provider
                  ? {
                      value: selectedValues.provider,
                      label: selectedValues.provider,
                    }
                  : null
              }
              name="provider"
              options={options.provider}
              classNames={pinnedSelectsClassName}
            />
          </GraniteLabel>
          <GraniteLabel label="Term length" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('term', selectedOption?.value || '')
              }
              value={
                selectedValues.term
                  ? { value: selectedValues.term, label: selectedValues.term }
                  : null
              }
              name="term"
              options={sortBy(options.term, (option) =>
                option.label.toLowerCase(),
              )}
              isDisabled={!selectedValues.provider}
              classNames={pinnedSelectsClassName}
            />
          </GraniteLabel>
          <GraniteLabel label="Download" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_download',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_download
                  ? {
                      value: selectedValues.bandwidth_download,
                      label: selectedValues.bandwidth_download,
                    }
                  : null
              }
              name="bandwidth_download"
              options={options.bandwidth_download}
              isDisabled={!selectedValues.term}
              classNames={pinnedSelectsClassName}
            />
          </GraniteLabel>

          <GraniteLabel label="Upload" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_upload',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_upload
                  ? {
                      value: selectedValues.bandwidth_upload,
                      label: selectedValues.bandwidth_upload,
                    }
                  : null
              }
              name="bandwidth_upload"
              options={options.bandwidth_upload}
              classNames={pinnedSelectsClassName}
            />
          </GraniteLabel>
          <GraniteLabel label="IP type" element="div">
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'DYNAMIC')}
              options={[{ value: 'DYNAMIC', label: 'Dynamic IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'DYNAMIC')}
              className="mb-2"
              variant={isPinnedSummary ? 'tertiary' : 'primary'}
            />
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'STATIC')}
              options={[{ value: 'STATIC', label: 'Static IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'STATIC')}
              variant={isPinnedSummary ? 'tertiary' : 'primary'}
            />
          </GraniteLabel>
          {/* <GraniteLabel label="IP type" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('ip_type', selectedOption!.value)
              }
              value={
                selectedValues.ip_type
                  ? {
                      value: selectedValues.ip_type,
                      label: selectedValues.ip_type,
                    }
                  : null
              }
              name="ip_type"
              options={options.ip_type?.map((i) => ({
                ...i,
                label: i.label,
              }))}
              // isDisabled={!selectedValues.bandwidth_upload}
            />
          </GraniteLabel> */}
          <GraniteLabel
            label="IP block"
            element="div"
            className={selectedValues.ip_type === 'DYNAMIC' ? 'hidden' : ''}
          >
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('ip_block', selectedOption!.value)
              }
              value={
                selectedValues.ip_block
                  ? {
                      value: selectedValues.ip_block,
                      label: selectedValues.ip_block,
                    }
                  : null
              }
              name="ip_block"
              options={options.ip_block?.map((i) => ({
                ...i,
                label: i.label,
              }))}
              classNames={pinnedSelectsClassName}
            />
          </GraniteLabel>
          <Divider />
          <GraniteLabel
            label="Add Granite Managed Services bundle"
            element="div"
          >
            <div className="flex flex-col gap-2">
              <CheckboxTooltip
                tooltipText="Option is not available for DIA."
                isVisible={summaryData.type === 'dia'}
              >
                <Checkbox
                  label={'MNS Essentials'}
                  checked={mns.essential === true}
                  onChange={() =>
                    handleSelectMNSChange({ mnsPackage: 'MNS Essentials' })
                  }
                  disabled={summaryData.type === 'dia'}
                />
              </CheckboxTooltip>
              <Checkbox
                label={'MNS Enhanced'}
                checked={mns.enhanced === true}
                onChange={() =>
                  handleSelectMNSChange({ mnsPackage: 'MNS Enhanced' })
                }
              />
            </div>
          </GraniteLabel>
        </div>
      </div>

      <div className="!my-4 mx-8 w-1">
        <Divider layout="vertical" className="h-full" variant="dotted" />
      </div>
      <div className="flex h-auto w-full flex-col justify-between p-4">
        <div className="flex gap-2">
          <div className="w-full">
            <ServiceSummary
              data={summaryData}
              isPinnedSummary={isPinnedSummary}
              isDisabled={isDisabled}
              mns={mns}
            />
          </div>
        </div>
        <div className="mt-auto flex w-full flex-col gap-2 self-end">
          <GraniteButton
            variant="secondary"
            size="large"
            onClick={() => (isPinnedSummary ? handleUnpin() : handlePin())}
            disabled={!isPinnedSummary && isAddedToCart}
          >
            {!isPinnedSummary ? 'Pin to compare' : 'Unpin'}
            <Pin color="inherit" />
          </GraniteButton>
          <GraniteButton
            variant={isPinnedSummary ? 'tertiary' : 'primary'}
            size="large"
            onClick={() =>
              isAddedToCart ? handleRemoveFromCart() : handleAddToCart()
            }
            disabled={isDisabled}
            className="!cursor-pointer"
          >
            {isAddedToCart ? 'Remove from cart' : 'Add to cart'}
          </GraniteButton>
        </div>
      </div>
    </div>
  );
};
