export const EdgebootIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4696 3.112C11.7624 2.81911 12.2373 2.81911 12.5302 3.112L16.2802 6.862C16.5731 7.1549 16.5731 7.62977 16.2802 7.92266L12.5302 11.6727C12.2373 11.9656 11.7624 11.9656 11.4696 11.6727C11.1767 11.3798 11.1767 10.9049 11.4696 10.612L14.052 8.02957C13.6145 7.98567 12.961 7.95483 11.9999 7.95483C10.6649 7.95483 9.35981 8.35071 8.24978 9.09241C7.13975 9.83411 6.27459 10.8883 5.7637 12.1217C5.2528 13.3551 5.11913 14.7123 5.37958 16.0217C5.64003 17.3311 6.28291 18.5338 7.22691 19.4778C8.17092 20.4218 9.37365 21.0647 10.683 21.3251C11.9924 21.5856 13.3496 21.4519 14.583 20.941C15.8164 20.4301 16.8706 19.565 17.6123 18.4549C18.354 17.3449 18.7499 16.0399 18.7499 14.7048C18.7499 14.2906 19.0857 13.9548 19.4999 13.9548C19.9141 13.9548 20.2499 14.2906 20.2499 14.7048C20.2499 16.3365 19.766 17.9316 18.8595 19.2883C17.953 20.645 16.6645 21.7024 15.157 22.3268C13.6495 22.9513 11.9907 23.1146 10.3904 22.7963C8.79004 22.478 7.32003 21.6922 6.16625 20.5385C5.01247 19.3847 4.22673 17.9147 3.9084 16.3143C3.59008 14.714 3.75345 13.0552 4.37788 11.5477C5.0023 10.0402 6.05972 8.75173 7.41643 7.84521C8.77313 6.93869 10.3682 6.45483 11.9999 6.45483C12.7484 6.45483 13.3378 6.47288 13.8009 6.50398L11.4696 4.17266C11.1767 3.87977 11.1767 3.4049 11.4696 3.112Z"
        fill="#94A3B8"
      />
    </svg>
  );
};
