import React, { createContext, useContext, useState, useCallback } from 'react';
import { ProductType } from 'screens/SelectServices/schemas';
import { ProductDetails } from 'screens/SelectServices/utils';

export type MNS_Package = 'MNS Essentials' | 'MNS Enhanced';

export type SelectedPackages = {
  essential: boolean;
  enhanced: boolean;
};

interface OpenQuoteServiceManagerContextType {
  pinnedServices: Record<string, ProductDetails | null>;
  cartMap: Record<string, boolean>;
  disabledSummaryMap: Record<string, boolean>;
  pinService: (id: string, type: string, product: ProductDetails) => void;
  unpinService: (id: string, type: string) => void;
  addToCart: (id: string, type: string, isPinnedService: boolean) => void;
  removeFromCart: (id: string, type: string, isPinnedService: boolean) => void;
  isUnpinning: boolean | null;
  productMap: Record<
    string,
    {
      product: ProductType;
      mns: SelectedPackages | null;
    }
  >;
  selectedMap: Record<string, boolean>;
  handleProductChange: (
    id: string,
    type: string,
    isPinned: boolean,
    product: ProductType,
    mns: SelectedPackages | null,
  ) => void;
  handleSelectChange: (
    id: string,
    type: string,
    isPinned: boolean,
    checked: boolean,
  ) => void;
  selectedValuesMap: Record<string, Record<string, string>>;
  setSelectedValuesMap: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, string>>>
  >;
  updateSelectedValues: (
    id: string,
    type: string,
    isPinnedSummary: boolean,
    newSelectedValues: Record<string, string>,
  ) => void;
  resetSelectedValues: (
    id: string,
    type: string,
    isPinnedSummary: boolean,
  ) => void;
  setProductMap: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          product: ProductType;
          mns: SelectedPackages | null;
        }
      >
    >
  >;
}

const OpenQuoteServiceManagerContext = createContext<
  OpenQuoteServiceManagerContextType | undefined
>(undefined);

export const OpenQuoteServiceManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isUnpinning, setIsUnpinning] = useState<boolean | null>(null);
  const [pinnedServices, setPinnedServices] = useState<
    Record<string, ProductDetails | null>
  >({});
  const [productMap, setProductMap] = useState<
    Record<
      string,
      {
        product: ProductType;
        mns: SelectedPackages | null;
      }
    >
  >({});
  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});
  const [selectedValuesMap, setSelectedValuesMap] = useState<
    Record<string, Record<string, string>>
  >({});
  const [cartMap, setCartMap] = useState<Record<string, boolean>>({});
  const [disabledSummaryMap, setDisabledSummaryMap] = useState<
    Record<string, boolean>
  >({});

  const updateSelectedValues = useCallback(
    (
      id: string,
      type: string,
      isPinnedSummary: boolean,
      newSelectedValues: Record<string, string>,
    ) => {
      const key = `${id}_${type}_${isPinnedSummary}`;

      setSelectedValuesMap((prev) => {
        const currentValues = prev[key] || {};
        const updated = {
          ...prev,
          [key]: { ...currentValues, ...newSelectedValues },
        };
        return updated;
      });
    },
    [],
  );

  const resetSelectedValues = useCallback(
    (id: string, type: string, isPinnedSummary: boolean) => {
      const key = `${id}_${type}_${isPinnedSummary}`;
      setSelectedValuesMap((prev) => {
        const { [key]: _, ...rest } = prev;
        return rest;
      });
    },
    [],
  );

  const handleProductChange = useCallback(
    (
      id: string,
      type: string,
      isPinned: boolean,
      product: ProductType,
      mns: SelectedPackages | null,
    ) => {
      setProductMap((prev) => ({
        ...prev,
        [[id, type, isPinned].join(',')]: { product, mns },
      }));
    },
    [],
  );

  const handleSelectChange = useCallback(
    (id: string, type: string, isPinned: boolean, checked: boolean) => {
      if (checked) {
        setSelectedMap((prev) => ({
          ...prev,
          [[id, type, isPinned].join(',')]: checked,
        }));
      } else {
        setSelectedMap((prev) => {
          const { [[id, type, isPinned].join(',')]: _, ...rest } = prev;
          return rest;
        });
      }
    },
    [],
  );

  const pinService = useCallback(
    (id: string, type: string, product: ProductDetails) => {
      const compositeKey = `${id}_${type}`;
      setPinnedServices((prev) => ({
        ...prev,
        [compositeKey]: product,
      }));
    },
    [],
  );

  const addToCart = useCallback(
    (id: string, type: string, isPinnedService: boolean) => {
      const compositeKey = `${id}_${type}_${isPinnedService}`;
      setCartMap((prev) => ({ ...prev, [compositeKey]: true }));
      if (isPinnedService) {
        setDisabledSummaryMap((prev) => ({
          ...prev,
          [`${id}_${type}_false`]: true,
        }));
      } else {
        setDisabledSummaryMap((prev) => ({
          ...prev,
          [`${id}_${type}_true`]: true,
        }));
      }

      handleSelectChange(id, type, isPinnedService, true);
    },
    [handleSelectChange],
  );

  const removeFromCart = useCallback(
    (id: string, type: string, isPinnedService: boolean) => {
      setTimeout(() => {
        const compositeKey = `${id}_${type}_${isPinnedService}`;
        setCartMap((prev) => {
          const { [compositeKey]: _, ...rest } = prev;
          return rest;
        });
        if (isPinnedService) {
          setDisabledSummaryMap((prev) => {
            const { [`${id}_${type}_false`]: _, ...rest } = prev;
            return rest;
          });
        } else {
          setDisabledSummaryMap((prev) => {
            const { [`${id}_${type}_true`]: _, ...rest } = prev;
            return rest;
          });
        }
      }, 500);
      setTimeout(() => {
        handleSelectChange(id, type, isPinnedService, false);
      }, 900);
    },
    [handleSelectChange],
  );

  const updateCartItem = useCallback(
    (id: string, type: string, isPinnedService: boolean) => {
      setTimeout(() => {
        const defaultKey = `${id}_${type}_${!isPinnedService}`;
        const pinnedKey = `${id}_${type}_${isPinnedService}`;

        setCartMap((prev) => {
          const updatedMap = { ...prev };

          if (updatedMap[pinnedKey]) {
            updatedMap[defaultKey] = updatedMap[pinnedKey];
            delete updatedMap[pinnedKey];
          }

          return updatedMap;
        });

        // replace default selected with pinned one
        setSelectedMap((prev) => {
          const pinnedSelectedKey = [id, type, true].join(',');
          const defaultSelectedKey = [id, type, false].join(',');
          if (prev[pinnedSelectedKey]) {
            prev[defaultSelectedKey] = prev[pinnedSelectedKey];
            delete prev[pinnedSelectedKey];
          }
          return prev;
        });
      }, 100);
    },
    [],
  );

  const unpinService = useCallback(
    (id: string, type: string) => {
      const compositeKey = `${id}_${type}`;
      const pinnedKey = `${id}_${type}_true`;
      setTimeout(() => {
        setPinnedServices((prev) => {
          const { [compositeKey]: pinnedProduct, ...rest } = prev;
          if (pinnedProduct) {
            updateSelectedValues(
              id,
              type,
              false,
              selectedValuesMap[pinnedKey] || {},
            );
          }
          return rest;
        });

        setDisabledSummaryMap((prev) => ({
          ...prev,
          [`${compositeKey}_false`]: false,
        }));

        setPinnedServices((prev) => {
          const { [compositeKey]: _, ...rest } = prev;
          return rest;
        });
        setIsUnpinning(false);
      }, 450);

      updateCartItem(id, type, true);

      setIsUnpinning(true);
    },
    [selectedValuesMap, updateCartItem, updateSelectedValues],
  );

  return (
    <OpenQuoteServiceManagerContext.Provider
      value={{
        pinnedServices,
        cartMap,
        pinService,
        unpinService,
        addToCart,
        removeFromCart,
        disabledSummaryMap,
        isUnpinning,
        productMap,
        selectedMap,
        handleProductChange,
        handleSelectChange,
        selectedValuesMap,
        setSelectedValuesMap,
        updateSelectedValues,
        resetSelectedValues,
        setProductMap,
      }}
    >
      {children}
    </OpenQuoteServiceManagerContext.Provider>
  );
};

export const useOpenQuoteServiceManager = () => {
  const context = useContext(OpenQuoteServiceManagerContext);
  if (!context) {
    throw new Error(
      'useOpenQuoteServiceManager must be used within a OpenQuoteServiceManagerProvider',
    );
  }
  return context;
};
