import { getInventoryStaticData } from 'api/inventory/api';
import { useQuery } from 'react-query';

const useInventoryStaticData = () => {
  return useQuery(['inventory-static-data'], getInventoryStaticData, {
    staleTime: Infinity,
  });
};

export default useInventoryStaticData;
