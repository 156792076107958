import { useState, createContext, useContext, useEffect } from 'react';
import { Close } from 'react-ionicons';
import clsx from 'clsx';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import { GraniteButton } from 'components/V2/Button/GraniteButton';

interface ResizableRightSideBarProps {
  children: React.ReactNode;
}

const MIN_WIDTH = 500;
const MAX_WIDTH = 800;
const COLLAPSED_WIDTH = 84;
export const RESIZABLE_SIDEBAR_TRANSITION_CLASSES =
  'transition-all duration-700 ease-[cubic-bezier(.01,1.4,.63,.99)]';

const SidebarContext = createContext<{
  toggleSidebarWidth: () => void;
  isExpanded: boolean;
} | null>(null);

export const useResizableSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within ResizableRightSideBar');
  }
  return context;
};

const ResizableRightSideBar = ({ children }: ResizableRightSideBarProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(MIN_WIDTH);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleResize = (width: number) => {
    setWidth(width);
  };

  const toggleSidebarWidth = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      return;
    }
    if (width > MIN_WIDTH) {
      setWidth(MIN_WIDTH);
    } else {
      setWidth(MAX_WIDTH);
    }
  };

  const handleOpenIfCollapsed = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (!isExpanded && window.innerWidth < 1024) {
        setIsExpanded(true);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isExpanded]);

  return (
    <SidebarContext.Provider value={{ toggleSidebarWidth, isExpanded }}>
      <ResizableBox
        width={width}
        height={Infinity}
        axis="x"
        resizeHandles={['w']}
        minConstraints={[MIN_WIDTH, Infinity]}
        maxConstraints={[MAX_WIDTH, Infinity]}
        className={clsx(
          !isResizing && RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
          !isExpanded && '!w-[84px]',
          'max-lg:!w-auto',
        )}
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={() => setIsResizing(false)}
        onResize={(e, data) => handleResize(data.size.width)}
        handle={
          <span
            className={clsx(
              'react-resizable-handle react-resizable-handle-se from-[rgba(0,0,0,0.25))] absolute -left-[32px] bottom-0 h-full w-10 cursor-ew-resize bg-gradient-to-r to-transparent',
              'hidden lg:block',
              !isExpanded && '!hidden',
            )}
          />
        }
      >
        <div
          className={clsx(
            'sticky -mt-4 flex-none lg:shadow-[-15px_4px_24px_0px_#00000025]',
            'top-16 xl:top-0',
            !isExpanded && '!w-[84px] cursor-pointer',
            !isResizing && RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
            'max-lg:!w-auto',
          )}
          onClick={handleOpenIfCollapsed}
          style={{ width }}
        >
          <div
            className={clsx(
              'overflow-hidden',
              'relative pt-1',
              'h-[calc(100vh-64px)] xl:h-screen',
              !isResizing && RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
              'max-lg:!w-auto',
            )}
            style={{
              width: isExpanded ? width : COLLAPSED_WIDTH,
            }}
          >
            <div
              className={clsx(
                'h-full',
                isExpanded ? 'p-6' : 'p-5',
                !isResizing && RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
                !isExpanded &&
                  'width-[9999px] pointer-events-none overflow-hidden',
                'max-lg:!w-auto',
              )}
              style={{ width }}
            >
              {children}
            </div>
          </div>
          <div className="absolute right-2 top-8 z-10 max-lg:hidden">
            <GraniteButton
              className={clsx(
                'absolute right-3 top-4 h-6 w-6 px-2 py-2 !text-white !outline-none transition-opacity',
                !isExpanded && 'opacity-0',
              )}
              size="small"
              style={{
                color: '#f00 !important',
                outline: 'none !important',
                border: 'none !important',
              }}
              variant="unstyled"
              onClick={handleToggle}
            >
              <Close width="24px" height="24px" color="#94A3B8" />
            </GraniteButton>
          </div>
        </div>
      </ResizableBox>
    </SidebarContext.Provider>
  );
};

export default ResizableRightSideBar;
