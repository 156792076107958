import PageTitle from 'components/PageTitle';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import { InventoryAccountProvider } from './InventoryAccountContext';

const InventoryAccount = () => {
  const navigate = useNavigate();

  return (
    <ContentLayout>
      <div className="flex items-center justify-between">
        <PageTitle
          className="flex !items-end"
          title="HQ"
          breadcrumbs={[
            {
              icon: 'home',
              label: 'Inventory',
              onClick: () => navigate('/inventory'),
            },
          ]}
        >
          <div className="flex rounded-lg bg-blue-600 px-4 py-1.5">
            <span className="text-center text-base font-bold text-content-base-default">
              #02447738
            </span>
          </div>
        </PageTitle>
        {/* comment until we have this functionality */}
        {/* <GraniteButton size="large">Edit account</GraniteButton> */}
      </div>
      <InventoryAccountProvider>
        <Outlet />
      </InventoryAccountProvider>
    </ContentLayout>
  );
};

export default InventoryAccount;
