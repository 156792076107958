import { useMemo } from 'react';
import clsx from 'clsx';
import { CloseCircle } from 'react-ionicons';
import Divider from 'components/Divider';
import { ServiceDetails } from './ServiceDetails';
import { ProductOfferingType } from './schemas';
import { useOpenQuoteServiceManager } from 'context/OpenQuoteServiceManagerContext';
import DetailsSummary from 'screens/OpenQuote/BaseComponents/LocationSection/DetailsSummary';

interface ServiceProps {
  data: {
    address: string;
    id: string;
    offerings: ProductOfferingType[];
    type?: string;
  };
}

export const Service = ({ data }: ServiceProps) => {
  const {
    pinnedServices,
    cartMap,
    isUnpinning,
    selectedMap,
    productMap,
    disabledSummaryMap,
  } = useOpenQuoteServiceManager();

  const isPinned = useMemo(() => {
    return !!pinnedServices[`${data.id}_${data.type}`];
  }, [data.id, data.type, pinnedServices]);

  const isAddedToCart = useMemo(
    () =>
      !!cartMap[`${data.id}_${data.type}_${false}`] ||
      !!cartMap[`${data.id}_${data.type}_${true}`],
    [cartMap, data.id, data.type],
  );

  const productData = useMemo(() => {
    const filteredProducts = Object.entries(productMap).filter(([key]) =>
      Object.keys(selectedMap).includes(key),
    );
    return filteredProducts?.[0]?.[1]?.product || null;
  }, [productMap, selectedMap]);

  const totalMRC = useMemo(() => {
    return Object.entries(productMap)
      .filter(([key]) => Object.keys(selectedMap).includes(key))
      .reduce((acc, [_, product]) => {
        return (
          acc +
          (product?.product?.formal_broadband_pricing?.total_mrc ?? 0) +
          (product?.product?.formal_dia_pricing?.total_mrc ?? 0) +
          (product.mns?.essential ? 19.99 : 0) +
          (product.mns?.enhanced ? 34.99 : 0)
        );
      }, 0);
  }, [productMap, selectedMap]);

  return (
    <div className={clsx('max-w-1/2 w-1/2 rounded', isPinned && 'w-full')}>
      <div className="flex w-full flex-col gap-4 rounded-b bg-background-base-surface-1 p-4">
        <div
          className={clsx(
            'flex justify-between',
            isPinned ? 'items-center' : 'flex-col',
          )}
        >
          <div
            className={clsx(
              'flex h-full min-w-0 flex-grow flex-row justify-between',
              !isPinned ? 'flex-start max-w-full' : 'max-w-[49%]',
            )}
          >
            <div className="truncate text-xl font-bold text-content-base-default">
              {data.address}
            </div>
            <div
              className={clsx(
                'absolute right-[51%] whitespace-nowrap rounded-lg px-2 py-1 text-xs font-bold text-content-flip-default',
                data.type !== 'broadband' && data.type !== 'dia' && 'hidden',
                data.type === 'broadband'
                  ? 'bg-blue-400'
                  : data.type === 'dia'
                    ? 'bg-green-300'
                    : 'bg-neutral-500',
              )}
            >
              {data.type === 'broadband'
                ? 'Broadband circuit'
                : data.type === 'dia'
                  ? 'DIA circuit'
                  : ''}
            </div>
          </div>

          <div
            className={clsx(
              'origin-top transition-all duration-500 ease-in-out',
              isAddedToCart
                ? 'h-4 translate-y-0 opacity-100'
                : 'h-0 translate-y-[-20px] opacity-0',
              cartMap[`${data.id}_${data.type}_${false}`] &&
                !isPinned &&
                'mt-4',
            )}
          >
            <DetailsSummary
              //@ts-expect-error err
              term={`${productData?.term}`}
              ipType={`${
                productData?.ip_type?.toLocaleLowerCase() === 'static'
                  ? //@ts-expect-error err

                    `Static IP, ${productData?.ip_block} IP block`
                  : 'Dynamic'
              }`}
              price={totalMRC.toString()}
              isSimpleFlow={false}
              //@ts-expect-error err
              provider={`${productData?.provider} (${
                productData?.bandwidth_download?.split?.(' ')[0] ?? ''
              }/${productData?.bandwidth_upload?.split?.(' ')[0] ?? ''} Mb/s)`}
            />
          </div>
        </div>
        <Divider className="mb-2" />
        {data.offerings.length > 0 ? (
          <div className="flex h-full w-full items-start gap-4">
            <ServiceDetails data={data} isPinnedSummary={false} />
            {!!pinnedServices[`${data.id}_${data.type}`] && (
              <div
                className={clsx(
                  '!pinned-summary-radial-gradient relative flex flex-col rounded outline outline-1 transition-all',
                  isUnpinning
                    ? 'animate-poof'
                    : 'animate-slide-in-left-to-right',
                  cartMap[`${data.id}_${data.type}_true`]
                    ? 'outline-[#9796F3]'
                    : 'outline-transparent',
                  disabledSummaryMap[`${data.id}_${data.type}_${true}`] &&
                    'pointer-events-none opacity-40',
                )}
                style={{
                  minWidth: '50%',
                  maxWidth: '50%',
                  height: '100%',
                }}
              >
                <ServiceDetails
                  data={pinnedServices[`${data.id}_${data.type}`]!}
                  isPinnedSummary={true}
                />
              </div>
            )}
          </div>
        ) : (
          <NotAvailable />
        )}
      </div>
    </div>
  );
};

const NotAvailable = () => {
  return (
    <div>
      <div className="flex h-full w-full flex-col gap-2 rounded bg-background-base-surface-3 p-4">
        <div className="flex items-center gap-2">
          <p className="text-xl font-bold text-content-base-default">
            There are no carriers available at this location.
          </p>
          <CloseCircle color="#FF315E" />
        </div>
        <p className="text-base font-semibold text-content-base-subdued">
          This location will be excluded from this quote request.
        </p>
      </div>
    </div>
  );
};
