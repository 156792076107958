import React, { ComponentProps, forwardRef } from 'react';
import { TextLegend, Circle } from './Charts.styles';
import { clsx } from 'clsx';

export interface LegendData extends ComponentProps<'div'> {
  x: string;
  y: number;
  backgroundColor: string;
  active?: boolean;
}

const Legend = forwardRef<HTMLDivElement, LegendData>(
  ({ backgroundColor, x, y, active, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className="flex w-full items-center justify-between gap-2 border-b border-stroke-base-subdued py-1.5 last:border-none sm:w-40 sm:border-none sm:py-1 sm:hover:text-content-base-default"
        {...props}
      >
        <div className="flex w-full items-center justify-start gap-2">
          <Circle fill={backgroundColor} />
          <TextLegend
            className={clsx(
              'sm:hover:text-content-base-default',
              active
                ? 'text-content-base-default'
                : 'text-content-base-subdued',
            )}
          >
            {x}
          </TextLegend>
          <span className="ml-auto font-bold text-content-base-default sm:hidden">
            {y}
          </span>
        </div>
      </div>
    );
  },
) as React.FC<LegendData>;

Legend.displayName = 'Legend';

export default Legend;
