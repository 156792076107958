export const AccessExpressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42054 14.9602C9.54457 13.9611 10.9962 13.4092 12.5 13.4092C14.0039 13.4092 15.4555 13.9611 16.5796 14.9602C16.8892 15.2354 16.917 15.7094 16.6419 16.019C16.3667 16.3286 15.8926 16.3565 15.583 16.0813C14.7336 15.3263 13.6366 14.9092 12.5 14.9092C11.3635 14.9092 10.2665 15.3263 9.41706 16.0813C9.10746 16.3565 8.63341 16.3286 8.35823 16.019C8.08305 15.7094 8.11094 15.2354 8.42054 14.9602Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54389 12.1112C7.43043 10.3471 9.91677 9.36579 12.4996 9.36579C15.0824 9.36579 17.5687 10.3471 19.4553 12.1112C19.7578 12.3941 19.7737 12.8687 19.4908 13.1713C19.2079 13.4738 18.7333 13.4897 18.4308 13.2068C16.8221 11.7026 14.702 10.8658 12.4996 10.8658C10.2972 10.8658 8.17706 11.7026 6.56839 13.2068C6.26584 13.4897 5.79123 13.4738 5.50832 13.1713C5.22542 12.8687 5.24134 12.3941 5.54389 12.1112Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.01343 8.93857C5.6243 6.60945 9.0008 5.32227 12.4996 5.32227C15.9984 5.32227 19.3749 6.60945 21.9857 8.93857C22.2948 9.21431 22.3219 9.68841 22.0461 9.99751C21.7704 10.3066 21.2963 10.3336 20.9872 10.0579C18.6511 7.97396 15.6301 6.82227 12.4996 6.82227C9.36909 6.82227 6.34802 7.97396 4.01197 10.0579C3.70288 10.3336 3.22877 10.3066 2.95303 9.99751C2.67729 9.68841 2.70434 9.21431 3.01343 8.93857Z"
      />
      <path d="M12.4996 20.4618C12.2029 20.4618 11.9129 20.3739 11.6662 20.209C11.4195 20.0442 11.2273 19.8099 11.1138 19.5359C11.0002 19.2618 10.9705 18.9602 11.0284 18.6692C11.0863 18.3782 11.2291 18.1109 11.4389 17.9012C11.6487 17.6914 11.916 17.5485 12.2069 17.4907C12.4979 17.4328 12.7995 17.4625 13.0736 17.576C13.3477 17.6895 13.582 17.8818 13.7468 18.1285C13.9116 18.3751 13.9996 18.6652 13.9996 18.9618C13.9996 19.3597 13.8415 19.7412 13.5602 20.0225C13.2789 20.3038 12.8974 20.4618 12.4996 20.4618Z" />
    </svg>
  );
};
