import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import uniq from 'lodash/uniq';
import clsx from 'clsx';

import { getQuoteFormalPricing, submitQuoteOrder } from 'api/accessexpress/api';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { formatCurrency } from 'shared/util/formatCurrency';
import { ReviewOrderConfirmationDialog } from 'screens/OpenQuote/BaseComponents/ReviewOrderConfirmationDialog';
import { useModal } from 'hooks/useModal';
import BasicPagination, {
  useBasicPagination,
} from 'components/BasicPagination';

import { Service } from './Service';
import { groupProducts } from './utils';
import { useOpenQuoteContext } from 'screens/OpenQuote/Wizard/OpenQuoteWizardReducer';
import DiscardProgressModal from 'screens/OpenQuote/DiscardProgressModal/DiscardProgressModal';
import Loader from 'components/Loader';
import { useOpenQuoteServiceManager } from 'context/OpenQuoteServiceManagerContext';
import Skeleton from 'components/Skeleton/Skeleton';
import showToast from 'components/Toast/Toast';

const SelectServices = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery(
    ['access-express-quote-formal-pricing', quoteId],
    () => getQuoteFormalPricing(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  );

  const products = useMemo(
    () => groupProducts(data?.locations || []) || [],
    [data?.locations],
  );

  const flattenedProducts = useMemo(() => products.flat(), [products]);

  const { from, to, ...paginationProps } = useBasicPagination(
    flattenedProducts.length,
    1,
  );

  const { selectedMap, productMap } = useOpenQuoteServiceManager();

  const anySelected = useMemo(
    () => Object.keys(selectedMap).length > 0,
    [selectedMap],
  );

  const totalMRC = useMemo(() => {
    return Object.entries(productMap)
      .filter(([key]) => Object.keys(selectedMap).includes(key))
      .reduce((acc, [_, product]) => {
        return (
          acc +
          (product?.product?.formal_broadband_pricing?.total_mrc ?? 0) +
          (product?.product?.formal_dia_pricing?.total_mrc ?? 0) +
          (product.mns?.essential ? 19.99 : 0) +
          (product.mns?.enhanced ? 34.99 : 0)
        );
      }, 0);
  }, [productMap, selectedMap]);

  const { ...discardModalProps } = useModal();
  const { isOpen, open, close } = useModal();

  const handleSubmit = useCallback(() => {
    if (loading) return;
    open();
  }, [loading, open]);

  const handleConfirmation = useCallback(async () => {
    setLoading(true);
    const result = Object.entries(productMap).filter(([key]) =>
      Object.keys(selectedMap).includes(key),
    );
    try {
      const submitResult = await submitQuoteOrder(quoteId!, {
        ordered_products: result
          .map((r) => ({
            id: r[1].product.id,
            mns: r[1].mns,
            type: r[1].product.type,
          }))
          .map(({ id, mns, type }) => ({
            id: id,
            mns_enhanced: mns?.enhanced ?? false,
            ...(type !== 'dia' && { mns_essential: mns?.essential }),
          })),
      });
      showToast.confirmation({
        title: 'Order submitted!',
        message: `Request #${submitResult.quote_request_id}`,
      });
      navigate(`/access-express/index`);
    } catch (error) {
      console.log(error);
      showToast.error({ message: 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  }, [navigate, productMap, quoteId, selectedMap]);

  const handleClose = useCallback(() => close(), [close]);

  const formData = useMemo(() => {
    const productIds = Object.keys(selectedMap).map((id) => id.split(','));
    const productLocationIds = uniq(productIds.map((id) => id[0]));
    const productTypes = uniq(productIds.map((id) => id[1])).map((type) => {
      if (type === 'broadband') {
        return 'Broadband';
      }
      if (type === 'dia') {
        return 'DIA';
      }
      return '';
    });

    return {
      id: data?.qe_quote_request_id ? data?.qe_quote_request_id.toString() : '',
      name: data?.quote_name || '',
      creation_date: data?.qe_quote_created_at,
      expiration_date: data?.formal_quote_expires_at,
      service_categories: productTypes.join(', '),
      total_services: productIds.length.toString(),
      total_locations: productLocationIds.length.toString(),
      total_nonrecurring_charges: 0,
      total_monthly_charges: totalMRC,
    };
  }, [
    data?.formal_quote_expires_at,
    data?.qe_quote_created_at,
    data?.qe_quote_request_id,
    data?.quote_name,
    selectedMap,
    totalMRC,
  ]);

  const { wizardStore } = useOpenQuoteContext();
  useEffect(() => {
    if (data?.qe_quote_request_id)
      wizardStore.setQeQuoteRequestId(data.qe_quote_request_id);
  }, [data, wizardStore]);

  const quoteTitle = useMemo(() => {
    return (
      <div className="relative h-[50px] w-full">
        <Skeleton
          className={clsx(
            'absolute inset-0 w-[310px] transition-opacity duration-500 ease-out',
            isLoading ? 'opacity-100' : 'pointer-events-none !opacity-0',
          )}
        />
        <div
          className={clsx(
            'flex items-center justify-center gap-6 transition-opacity duration-500 ease-out',
            isLoading
              ? 'pointer-events-none absolute inset-0 opacity-0'
              : 'opacity-100',
          )}
        >
          <h1 className="text-4xl font-bold text-content-base-default">
            {data?.quote_name}
          </h1>
          <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
            <span className="h-auto text-center text-base font-bold text-content-base-default">
              QR #{data?.qe_quote_request_id}
            </span>
          </div>
        </div>
      </div>
    );
  }, [data?.qe_quote_request_id, data?.quote_name, isLoading]);
  useEffect(() => {
    wizardStore.setStep(2, quoteTitle);
  }, [quoteTitle, wizardStore]);

  return (
    <>
      <div className="select-services flex w-full flex-col items-start gap-8 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full justify-between">
          <h2 className="mb-2 w-full text-2xl font-bold text-content-base-default">
            Select services to order for your locations
          </h2>
          <BasicPagination {...paginationProps} />
        </div>

        <div
          className={clsx(
            'relative w-full',
            isLoading ? 'h-[740px]' : 'h-auto',
          )}
        >
          <div
            className={clsx(
              'absolute inset-0 w-full transition-opacity duration-500 ease-out',
              isLoading ? 'opacity-100' : 'pointer-events-none opacity-0',
            )}
          >
            <Skeleton className="h-full w-1/2" />
          </div>
          <div
            className={clsx(
              'transition-all duration-500 ease-out',
              isLoading
                ? 'pointer-events-none absolute inset-0 opacity-0'
                : 'opacity-100',
            )}
          >
            <div className="relative grid w-full grid-cols-1 gap-5">
              {flattenedProducts.slice(from, to).map((product) => (
                <React.Fragment key={[product.id, product.type].join(',')}>
                  <Service data={product} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 flex w-full grow  flex-row gap-10 rounded bg-background-base-surface-0 px-6 py-4 font-bold">
        <div className="flex flex-1">Total charges</div>
        <div className="flex flex-none flex-col items-end">
          <div>Non-recurring charges</div>
          <div className="text-3xl text-content-accent-default">$0.00</div>
        </div>
        <div className="flex flex-none flex-col items-end">
          <div>Monthly recurring charges</div>
          <div className="text-3xl text-content-accent-default">
            {formatCurrency(totalMRC)}
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4 self-end">
        <GraniteButton
          variant="secondary"
          size="large"
          onClick={discardModalProps.open}
        >
          Cancel
        </GraniteButton>
        <GraniteButton
          variant="primary"
          size="large"
          disabled={!anySelected}
          onClick={handleSubmit}
          className={clsx(loading && 'loading-button')}
        >
          Submit order
          {loading && <Loader animationClassname="!w-3 !h-3" />}
        </GraniteButton>
      </div>
      <DiscardProgressModal {...discardModalProps} />
      <ReviewOrderConfirmationDialog
        onConfirmation={handleConfirmation}
        isOpen={isOpen}
        close={handleClose}
        formData={formData}
      />
    </>
  );
};

export default SelectServices;
