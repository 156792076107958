import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import {
  LocationType,
  ProductLocationType,
  ProductOfferingType,
  ProductType,
} from './schemas';

export type TermType = '1 Year' | '2 Years' | '3 Years';

export const formatLocation = (location: LocationType) => {
  const { address1, address2, city, state, zip_code } = location;
  return `${address1}${
    (address2 || '').trim().length > 0 ? ', ' + address2 : ''
  }, ${city}, ${state} ${zip_code}`;
};

export const groupByMultipleKeys = <T>(
  array: T[],
  keys: (keyof T | string)[],
): { [key: string]: T[] } => {
  return groupBy(array, (item) => {
    return keys.map((key) => get(item, key, 'undefined')).join('|||');
  });
};

export type ProductDetails = {
  address: string;
  id: string;
  offerings: ProductOfferingType[];
  type?: string;
};

export const groupProducts = (
  productLocations: ProductLocationType[],
): Array<ProductDetails>[] => {
  const result = map(
    productLocations,
    (productLocation: ProductLocationType) => {
      const {
        address1,
        address2,
        city,
        formal_products_offerings,
        id,
        state,
        zip_code,
      } = productLocation;

      const address = formatLocation({
        address1,
        address2,
        city,
        state,
        zip_code,
      });

      if (formal_products_offerings.length === 0) {
        return {
          address,
          id,
          offerings: [] as ProductOfferingType[],
        } as ProductDetails;
      }

      const products = flatMap(formal_products_offerings, (product) => {
        const {
          formal_broadband_product,
          formal_dia_product,
          ...productDetails
        } = product;

        const offerings = [
          formal_broadband_product
            ? {
                type: 'broadband',
                ...formal_broadband_product,
                ...productDetails,
              }
            : null,
          formal_dia_product
            ? {
                type: 'dia',
                ...formal_dia_product,
                ...productDetails,
              }
            : null,
        ].filter(Boolean);

        return offerings;
      });

      const grouped = groupBy(products, 'type');

      return Object.entries(grouped).map(
        ([type, offerings]) =>
          ({
            address,
            id,
            type,
            offerings: offerings as unknown as ProductOfferingType[],
          }) as ProductDetails,
      );
    },
  );

  const flattened = result.flat();

  const typeCounts = [...new Set(flattened.map((f) => f.type))].filter(
    Boolean,
  ).length;

  const groupedByAddress = groupBy(flattened, 'address');

  const sortedGroups = sortBy(
    Object.entries(groupedByAddress),
    ([address, items]) => {
      const hasOfferings = items.some((item) => item.offerings.length > 0);
      return [!hasOfferings, address];
    },
  );

  const finalResult = sortedGroups.map(([, items]) => {
    if (typeCounts === 1) {
      return [items[0]];
    }

    const notAvailable = items.find(
      (item) => !['broadband', 'dia'].includes(item.type || ''),
    );

    if (notAvailable) {
      return [notAvailable];
    }

    const broadband = items.find((item) => item.type === 'broadband') || {
      ...items[0],
    };
    const dia = items.find((item) => item.type === 'dia') || {
      ...items[0],
    };

    return [broadband, dia];
  });

  return finalResult;
};

interface DataEntry {
  key: string;
  id: string;
}

type NestedObject = {
  [key: string]: NestedObject | string[];
};

export function buildNestedObjectWithIds(dataArray: DataEntry[]): NestedObject {
  const root: NestedObject = {};

  dataArray.forEach((entry) => {
    const { key, id } = entry;
    const parts = key.split('|||');

    let currentLevel = root;

    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        if (!currentLevel[part]) {
          currentLevel[part] = [];
        }
        (currentLevel[part] as string[]).push(id);
      } else {
        if (!currentLevel[part]) {
          currentLevel[part] = {};
        }
        currentLevel = currentLevel[part] as NestedObject;
      }
    });
  });

  return root;
}

const BROADBAND_TERM_TO_SERVICE_ACTIVATION_FEE_MAP: Record<TermType, number> = {
  '1 Year': 199.99,
  '2 Years': 99.99,
  '3 Years': 0,
};

const DIA_TERM_TO_SERVICE_ACTIVATION_FEE_MAP: Record<TermType, number> = {
  '1 Year': 1300.0,
  '2 Years': 650.0,
  '3 Years': 0,
};

export const getServiceActivationFee = (
  data: (ProductType & { term: TermType }) | undefined,
) => {
  if (data?.type === 'broadband') {
    return (
      data?.formal_broadband_pricing?.service_activation ??
      BROADBAND_TERM_TO_SERVICE_ACTIVATION_FEE_MAP[data.term]
    );
  } else if (data?.type === 'dia') {
    return (
      data.formal_dia_pricing?.service_activation ??
      DIA_TERM_TO_SERVICE_ACTIVATION_FEE_MAP[data.term]
    );
  }
  return 0;
};
