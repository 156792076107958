import { useQuery } from 'react-query';
import { getEmbeddedDashboard } from '../../api/nocexpress/api';
import { useAuthUser } from '../../hooks/useAuthUser';
import { ComponentProps, useState } from 'react';
import Skeleton from 'components/Skeleton/Skeleton';

export type DashboardIframeProps = ComponentProps<'iframe'>;

const Loading = () => (
  <div className="ml-[9px] mr-2 mt-[52px] flex flex-col gap-4">
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="flex h-full w-full flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row">
          <Skeleton className="h-[164px] w-full !rounded md:h-[175px]" />
          <Skeleton className="h-[164px] w-full !rounded md:h-[175px]" />
        </div>
        <Skeleton className="h-[288px] w-full !rounded md:h-[307px]" />
      </div>
      <Skeleton className="h-[468px] w-full !rounded md:h-[500px] lg:w-[66%]" />
    </div>
    <div className="flex flex-col gap-4 lg:flex-row">
      <div className="flex h-full w-full flex-col gap-4">
        <div className="flex flex-col gap-4 lg:flex-row">
          <Skeleton className="h-[95px] w-full !rounded md:h-[120px]" />
          <Skeleton className="h-[95px] w-full !rounded md:h-[120px]" />
          <Skeleton className="h-[95px] w-full !rounded md:h-[120px]" />
        </div>
        <Skeleton className="h-[205px] w-full !rounded md:h-[263px]" />
      </div>
      <Skeleton className="h-[316px] w-full !rounded md:h-[400px] lg:!w-[66%]" />
    </div>
    <div className="flex flex-col gap-4 lg:flex-row">
      <Skeleton className="h-[247px] w-full !rounded md:h-[300px]" />
      <Skeleton className="h-[247px] w-full !rounded md:h-[300px] lg:!w-[66%]" />
    </div>
    <Skeleton className="h-[413px] w-full !rounded md:h-[500px]" />
  </div>
);

export const DashboardIframe = (props: DashboardIframeProps) => {
  const { sub } = useAuthUser();
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const { data, isLoading } = useQuery(
    ['noc', 'dashboard', sub],
    getEmbeddedDashboard,
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      {(isLoading || isIframeLoading) && <Loading />}
      <iframe
        {...props}
        title="Noc Dashboard Content"
        srcDoc={data}
        style={{
          display: !isIframeLoading ? 'block' : 'none',
        }}
        onLoad={() => setIsIframeLoading(false)}
      />
    </>
  );
};
