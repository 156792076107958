import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink, To, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ChevronDown, ChevronUp, OpenOutline } from 'react-ionicons';

import { HomeMenuSubItem, HomeSubMenu } from './HomeSubMenu';
import NavbarTooltip from './NavbarTooltip';

export type HomeMenuItem = {
  title: string;
  icon: (props: { width: string; height: string }) => JSX.Element;
  to?: To;
  show?: boolean;
  subItems?: HomeMenuSubItem[];
  target?: React.HTMLAttributeAnchorTarget;
  isActive?: boolean;
};

interface HomeMenuProps {
  isWide: boolean;
  items: HomeMenuItem[];
  collapsibleButtonRef: React.RefObject<HTMLDivElement>;
  className?: string;
}

const HomeMenu = ({
  isWide,
  items,
  collapsibleButtonRef,
  className,
}: HomeMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [openedMenuTitle, setOpenMenuTitle] = useState('');

  const handleItemSelect = useCallback((title: string) => {
    setOpenMenuTitle((prev) => (prev === title ? '' : title));
  }, []);

  useEffect(() => {
    setOpenMenuTitle('');
  }, [isWide]);

  useEffect(() => {
    if (!isWide) {
      setOpenMenuTitle('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const filteredMenuItems = useMemo(
    () =>
      items
        .filter((item) => item.show)
        .map((item) => ({
          ...item,
          subItems: item.subItems?.filter((i) =>
            i.show === undefined ? true : i.show,
          ),
        })),
    [items],
  );

  return (
    <div
      ref={menuRef}
      className={clsx('flex flex-col gap-2 lg:gap-1', className)}
    >
      {filteredMenuItems.map((item) => {
        const isSelected = item.title === openedMenuTitle;
        return (
          <div key={item.title}>
            <NavbarTooltip title={item.title} hide={isWide}>
              <MenuItem
                isWide={isWide}
                hasChildren={!!item.subItems?.length}
                onClick={() => handleItemSelect(item.title)}
                {...item}
              >
                {item.title}
                {item.subItems ? (
                  <span
                    className={clsx(
                      'opacity-100 transition-opacity duration-300 ease-out group-hover:opacity-100 xl:opacity-0',
                      isSelected && '!opacity-100',
                    )}
                  >
                    {isSelected ? (
                      <ChevronUp
                        width="16px"
                        height="16px"
                        color="currentColor"
                      />
                    ) : (
                      <ChevronDown
                        width="16px"
                        height="16px"
                        color="currentColor"
                      />
                    )}
                  </span>
                ) : item.target === '_blank' ? (
                  <span className="opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100">
                    <OpenOutline
                      width="16px"
                      height="16px"
                      color="currentColor"
                    />
                  </span>
                ) : null}
              </MenuItem>
            </NavbarTooltip>

            {item.subItems?.length && (
              <HomeSubMenu
                items={item.subItems}
                isWide={isWide}
                onClickOutside={() => setOpenMenuTitle('')}
                outsideRefs={[menuRef, collapsibleButtonRef]}
                show={isSelected}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HomeMenu;

interface MenuItemProps extends HomeMenuItem {
  children: React.ReactNode;
  isWide: boolean;
  hasChildren: boolean;
  onClick?: () => void;
}

const MenuItem = ({
  icon,
  children,
  isWide,
  to,
  target,
  onClick,
  hasChildren,
  isActive,
}: MenuItemProps) => {
  const { pathname } = useLocation();

  const isSelected = useMemo(
    () => isActive || (to && pathname.startsWith(to.toString())),
    [isActive, pathname, to],
  );

  const content = (
    <>
      <div
        className={clsx(
          'w-0 flex-none rounded-full bg-[#7E77B3]',
          'opacity-0 transition-all duration-150 ease-out',
          !isWide && 'mr-1',
          isSelected && 'mr-2 w-0.5 opacity-100',
        )}
      />
      <div
        className={clsx(
          'flex-none transition-all duration-150 ease-out',
          'text-content-base-default xl:text-content-base-subdued',
          isSelected && '!text-content-base-default',
        )}
      >
        {icon({ width: '24px', height: '24px' })}
      </div>
      <div
        className={clsx(
          'ml-2 flex flex-1 flex-row items-center justify-between whitespace-nowrap',
          'transition-all duration-300 ease-out',
          isWide ? '' : ' opacity-0',
          isSelected ? 'font-bold' : 'font-semibold',
        )}
      >
        {children}
      </div>
    </>
  );

  const className = clsx(
    'flex flex-row items-center items-stretch rounded p-2 transition-all duration-300 ease-out hover:bg-[#2B2B3C] cursor-pointer group',
    'xl:text-content-base-subdued text-content-base-default',
    isSelected && '!bg-[#333346] !text-content-base-default',
  );

  return hasChildren ? (
    <div className={className} onClick={onClick}>
      {content}
    </div>
  ) : (
    <NavLink
      className={className}
      to={to || ''}
      target={target}
      onClick={onClick}
    >
      {content}
    </NavLink>
  );
};
