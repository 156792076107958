import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

interface ScrollableContentProps {
  children: React.ReactNode;
  className?: string;
}

export const ScrollableContent = ({
  children,
  className,
}: ScrollableContentProps) => {
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setShowTopShadow(scrollTop > 0);
      setShowBottomShadow(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    handleScroll();
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      if (containerRef.current) {
        const { scrollHeight, clientHeight } = containerRef.current;
        setIsScrollable(scrollHeight > clientHeight);
      }
    };

    checkScrollable();

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', checkScrollable);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.removeEventListener('scroll', checkScrollable);
      }
    };
  }, []);

  return (
    <div className="relative -ml-4 -mr-4 h-full overflow-hidden pl-4 pr-4">
      <div
        ref={containerRef}
        className={clsx(
          'vertical-scrollbar relative flex h-full overflow-x-hidden bg-[#262636] !scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued',
          '-mr-[50px] pr-[50px]',
          isScrollable && 'hover:-mr-[13px] hover:pr-[13px]',
          className,
        )}
      >
        {children}
      </div>
      <div
        className={clsx(
          'absolute -top-5 left-3 right-3 h-5 opacity-0 shadow-elevation5 transition-opacity duration-300 ease-out',
          showTopShadow && 'opacity-50',
        )}
      />
      <div
        className={clsx(
          'absolute -bottom-5 left-3 right-3 h-5 rotate-180 opacity-0 shadow-elevation5 transition-opacity duration-300 ease-out',
          showBottomShadow && 'opacity-50',
        )}
      />
    </div>
  );
};
