import { ReactComponent as EdgeBootLogo } from 'assets/images/edgeboot_logo.svg';
import { ReactComponent as AccessExpressLogo } from 'assets/images/accesexpress_landing-page/access_express_logo.svg';
import { ReactComponent as WirelessLogo } from 'assets/images/accesexpress_landing-page/wireless_logo.svg';
import { ReactComponent as CheckAvailabilityVector } from 'assets/images/accesexpress_landing-page/check_availability_vector.svg';
import { ReactComponent as OrdersVevtor } from 'assets/images/accesexpress_landing-page/orders_vector.svg';
import { ReactComponent as QuotesVector } from 'assets/images/accesexpress_landing-page/quotes_vector.svg';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OpenOutline } from 'react-ionicons';
import phoneSrc from 'assets/images/accesexpress_landing-page/phone.png';
import edgebootSrc from 'assets/images/edgeboot.png';

const EdgeBoot = () => {
  const onLearnMoreClick = () => {
    window.open('https://edgeboot.io', '_blank');
  };
  return (
    <div className="flex flex-row items-start justify-start gap-8">
      <img
        src={edgebootSrc}
        alt="edgeboot"
        className="z-10 box-border h-auto w-[320px] object-contain"
      />
      <div className="z-10 flex flex-1 flex-col gap-2">
        <EdgeBootLogo />
        <h1 className="z-10 text-[28px] font-bold leading-9 text-content-base-default">
          Device control made simple.
        </h1>
        <p className="z-10 text-xs font-semibold text-content-base-default">
          Leverage AI-powered technology to reduce downtime and deliver cost
          savings with <i className="font-bold">edgeboot</i>,{' '}
          <i>coming soon to Granite360.</i>
        </p>
        <GraniteButton
          size="small"
          className="z-10 w-[114px]"
          onClick={onLearnMoreClick}
        >
          Learn more <OpenOutline width="18px" height="18px" color="inherit" />
        </GraniteButton>
      </div>
    </div>
  );
};

const CheckAvailability = () => {
  return (
    <div className="flex w-[75%] flex-col gap-4 overflow-hidden p-8">
      <div className="absolute right-0 top-0 z-0">
        <CheckAvailabilityVector />
      </div>
      <AccessExpressLogo />
      <h1 className="z-10 text-[28px] font-bold leading-9 text-content-base-default">
        More services.
        <br />
        More customization.
      </h1>
      <p className="z-10 text-xs font-semibold text-content-base-default">
        Check availability & generate quotes for all Granite services, including
        DIA, SDWAN, GRID, Managed Services, and more!
      </p>
      <div className="z-10 mt-4 flex h-6 w-[125px] items-center justify-center rounded-lg bg-background-base-surface-3/20 text-center">
        <p className="text-center text-xs font-bold text-content-base-default">
          COMING SOON
        </p>
      </div>
    </div>
  );
};

const Mobility = () => {
  const onLetsGoClick = () => {
    window.open('/mobility', '_blank');
  };
  return (
    <div className="flex flex-row items-center gap-8">
      <div className="flex w-[75%] flex-col gap-4 p-8">
        <WirelessLogo />
        <h1 className="z-10 text-[36px] font-bold leading-9 text-content-base-default">
          Wishing for wireless?
        </h1>
        <p className="z-10 font-semibold text-content-base-default">
          Check out Granite’s Mobility Marketplace for all wireless solutions,
          including broadband, mobile phones, and more!
        </p>
        <GraniteButton
          size="small"
          className="z-10 mt-4 w-[91px]"
          onClick={onLetsGoClick}
        >
          Let&apos;s go{' '}
          <OpenOutline width="18px" height="18px" color="inherit" />
        </GraniteButton>
      </div>
      <img
        src={phoneSrc}
        alt="phone"
        className="h-full w-auto object-contain"
      />
    </div>
  );
};

const Quotes = () => {
  return (
    <>
      <div className="absolute right-0 top-0 z-0">
        <QuotesVector />
      </div>
      <div className="flex w-[75%] flex-col gap-4 overflow-hidden p-8">
        <AccessExpressLogo />
        <h1 className="z-10 text-[28px] font-bold leading-9 text-content-base-default">
          Quote. Sign, Go!
        </h1>
        <p className="z-10 text-xs font-semibold text-content-base-default">
          Generate real-time quotes for Granite services in just a few clicks.
          Review & sign agreements digitally, and place your order on the spot.
        </p>
        <p className="z-10 text-xs font-semibold text-content-base-default">
          Whether you&apos;re upgrading your current plan or ordering new
          services, Granite makes it easy to move from quote to order in no time
          with AccessExpress!{' '}
        </p>
        <div className="z-10 mt-4 flex h-6 w-[125px] items-center justify-center rounded-lg bg-background-base-surface-3/20 text-center">
          <p className="text-center text-xs font-bold text-content-base-default">
            COMING SOON
          </p>
        </div>
      </div>
    </>
  );
};

const Orders = () => {
  const onLetsGoClick = () => {
    window.open('https://roadmap.granitemsp.com/', '_blank');
  };
  return (
    <>
      <div className="absolute right-0 top-0 z-0">
        <OrdersVevtor />
      </div>
      <div className=" flex w-[100%] flex-col gap-4 overflow-hidden p-8">
        <AccessExpressLogo />
        <h1 className="z-10 mt-4 text-4xl font-bold leading-9 text-content-base-default">
          Eager to see what’s next?{' '}
        </h1>
        <p className="z-10 font-semibold text-content-base-default">
          Get a sneak peek into our roadmap to see what&apos;s in the pipeline
          for future releases and features!
        </p>
        <GraniteButton
          size="small"
          className="z-10 mt-4 w-[91px]"
          onClick={onLetsGoClick}
        >
          Let&apos;s go{' '}
          <OpenOutline width="18px" height="18px" color="inherit" />
        </GraniteButton>
      </div>
    </>
  );
};

export { EdgeBoot, CheckAvailability, Mobility, Quotes, Orders };
