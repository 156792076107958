import clsx from 'clsx';
import { CSSProperties } from 'react';

export interface DividerProps {
  variant?: 'default' | 'subdued' | 'dotted';
  layout?: 'horizontal' | 'vertical';
  className?: string;
  style?: CSSProperties;
}

const Divider = ({
  variant = 'subdued',
  layout = 'horizontal',
  className,
  style,
}: DividerProps) => {
  const horizontalStyles = 'h-[1px] flex-shrink-0';
  const verticalStyles = 'w-[1px] h-[57.5px] flex-shrink-0';

  const defaultVariant = 'bg-stroke-base-default';
  const subduedVariant = 'bg-stroke-base-subdued';

  const dottedVariant =
    layout === 'horizontal'
      ? 'border-none h-[1px] w-full'
      : 'border-none h-full w-[1.5px]';

  return (
    <div
      data-testid="divider"
      style={
        variant === 'dotted'
          ? {
              background:
                'repeating-linear-gradient(to bottom, #2B2B3F 0, #2B2B3F 10px, transparent 10px, transparent 20px)',
            }
          : style
      }
      className={clsx(
        'border-solid border-opacity-[.5]',
        layout === 'horizontal' ? horizontalStyles : verticalStyles,
        variant === 'default'
          ? defaultVariant
          : variant === 'dotted'
            ? dottedVariant
            : subduedVariant,
        className,
      )}
    />
  );
};

export default Divider;
