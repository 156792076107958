import { GraniteButton } from '../../components/V2/Button/GraniteButton';
import { FilterCircle, Tv } from 'react-ionicons';
import { GraniteSelect } from '../../components/Select/Select';
import { Fragment, useState } from 'react';
import { dateOptions } from './filterConstantOptions';
import { Chip } from '../../components/Chip/Chip';
import { ActionMeta } from 'react-select';
import {
  FilterOption,
  NOCDashboardFilterStore,
} from './NOCDashboardFilterStore';
import { observer, Observer } from 'mobx-react-lite';

export interface NOCExpressFiltersProps {
  filterStore: NOCDashboardFilterStore;
}

interface DomoFilterProps {
  value: FilterOption[];
  onFilterChange: (action: ActionMeta<FilterOption>) => void;
  options: FilterOption[];
  placeholder: string;
}

const DomoFilter = observer(
  ({ value, onFilterChange, options, placeholder }: DomoFilterProps) => {
    return (
      <GraniteSelect
        options={options}
        isMulti
        placeholder={placeholder}
        value={value}
        controlShouldRenderValue={false}
        isSearchable={false}
        onChange={(_, actionMeta) => {
          onFilterChange(actionMeta);
        }}
      />
    );
  },
);

const FilterChip = observer(
  ({
    label,
    onDelete,
  }: {
    label: string;
    onDelete: (option: ActionMeta<FilterOption>) => void;
  }) => (
    <Chip
      label={label}
      className="mt-4"
      onDelete={() => {
        onDelete({
          action: 'deselect-option',
          option: { label, value: label },
        });
      }}
    />
  ),
);

interface FilterChipListProps {
  filterStore: NOCDashboardFilterStore;
}

const FilterChipList = observer(({ filterStore }: FilterChipListProps) => {
  return (
    <Fragment>
      {filterStore.currentDateFilter &&
        filterStore.currentDateFilter.value !== 'Default' && (
          <FilterChip
            label={
              dateOptions.find(
                (opt) => opt.value === filterStore.currentDateFilter.value,
              )?.label ?? ''
            }
            onDelete={() => filterStore.applyDateFilter()}
          />
        )}
      {filterStore.currentSelectionPriority.map((option, i) => (
        <FilterChip
          key={option.label + i}
          label={option.label}
          onDelete={filterStore.togglePriorityOption.bind(filterStore)}
        />
      ))}
      {filterStore.currentSelectionStatus.map((option, i) => (
        <FilterChip
          key={option.label + i}
          label={option.label}
          onDelete={filterStore.toggleStatusOption.bind(filterStore)}
        />
      ))}
      {filterStore.currentSelectionTicketType.map((option, i) => (
        <FilterChip
          key={option.label + i}
          label={option.label}
          onDelete={filterStore.toggleTicketTypeOption.bind(filterStore)}
        />
      ))}
      {filterStore.currentSelectionTicketSubtype.map((option, i) => (
        <FilterChip
          key={option.label + i}
          label={option.label}
          onDelete={filterStore.toggleTicketSubtypeOption.bind(filterStore)}
        />
      ))}
    </Fragment>
  );
});

export const NOCExpressFilters = observer(
  ({ filterStore }: NOCExpressFiltersProps) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
      <div className="">
        <div className="mb-4 flex flex-col gap-4 sm:flex-row">
          <h1 className="text-3xl font-bold text-content-base-default">
            Active repair tickets
          </h1>
          <GraniteButton
            className="ml-auto w-full sm:w-auto"
            onClick={() => setIsVisible((v) => !v)}
            variant="secondary"
            size="large"
            disabled={!filterStore.isInitialized}
          >
            {isVisible ? 'Hide' : 'Show'} filters <FilterCircle />
          </GraniteButton>
          <div className="my-auto ml-0 mr-0 hidden fill-content-base-default text-content-base-default">
            <Tv width="22px" height="22px" color="inherit" />
          </div>
        </div>
        {isVisible && (
          <div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
              <Observer>
                {() => (
                  <GraniteSelect
                    placeholder="Filter by date"
                    isSearchable={false}
                    controlShouldRenderValue={false}
                    options={dateOptions}
                    value={filterStore.currentDateFilter}
                    onChange={(option) => {
                      filterStore.applyDateFilter(option ?? undefined);
                    }}
                  />
                )}
              </Observer>
              <DomoFilter
                placeholder="Filter ticket priority"
                value={filterStore.currentSelectionPriority}
                onFilterChange={filterStore.togglePriorityOption.bind(
                  filterStore,
                )}
                options={filterStore.priorityOptions}
              />
              <DomoFilter
                placeholder="Filter ticket status"
                value={filterStore.currentSelectionStatus}
                onFilterChange={filterStore.toggleStatusOption.bind(
                  filterStore,
                )}
                options={filterStore.statusOptions}
              />
              <DomoFilter
                placeholder="Filter ticket type"
                value={filterStore.currentSelectionTicketType}
                onFilterChange={filterStore.toggleTicketTypeOption.bind(
                  filterStore,
                )}
                options={filterStore.ticketTypeOptions}
              />
              <DomoFilter
                placeholder="Filter ticket subtype"
                value={filterStore.currentSelectionTicketSubtype}
                onFilterChange={filterStore.toggleTicketSubtypeOption.bind(
                  filterStore,
                )}
                options={filterStore.ticketSubTypeOptions}
              />
            </div>
            <div className="flex flex-wrap gap-x-4">
              <FilterChipList filterStore={filterStore} />
            </div>
          </div>
        )}
      </div>
    );
  },
);
