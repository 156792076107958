import clsx from 'clsx';
import IconButton from 'components/IconButton';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useInventoryAccount } from '../InventoryAccountContext';
import { motion } from 'framer-motion';

interface AccordionServiceProps {
  serviceType: string;
  activeNumber: number;
  defaultExpand?: boolean;
  children: ReactNode;
}

const AccordionService: FC<AccordionServiceProps> = ({
  serviceType,
  activeNumber,
  defaultExpand = false,
  children,
}) => {
  const { mode } = useInventoryAccount();
  const [, setPrev] = useState(defaultExpand);
  const [expand, setExpand] = useState(defaultExpand);

  useEffect(() => {
    if (mode === 'edit') {
      setExpand((prev) => {
        setPrev(prev);
        return true;
      });
    } else {
      setPrev((prev) => {
        setExpand(prev);
        return prev;
      });
    }
  }, [mode]);

  return (
    <div
      className="flex cursor-pointer flex-col rounded bg-blue-800 p-4"
      onClick={() => setExpand((prev) => !prev)}
    >
      <div className="flex justify-between gap-4">
        <p className="font-bold">
          <span className="text-white">{serviceType}</span> ({activeNumber}{' '}
          active)
        </p>
        <IconButton
          icon={'chevronDown'}
          className={clsx({ 'rotate-180 text-white': expand })}
          buttonType="ghost"
          color="currentColor"
        />
      </div>
      <motion.div
        className="overflow-hidden"
        initial={
          defaultExpand
            ? { height: 'auto', opacity: 1, marginTop: `16px` }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        animate={
          expand
            ? { height: 'auto', opacity: 1, marginTop: `16px` }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 15,
        }}
      >
        <div className="flex flex-col gap-4">{children}</div>
      </motion.div>
    </div>
  );
};

export default AccordionService;
