import Filters from 'components/Filters';
import { GraniteSelect } from 'components/Select/Select';
import Searchbar from 'components/Table/SearchBar';
import { startOfDay, subMonths } from 'date-fns';
import { useState } from 'react';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import Activities from './Activities';

const filterByDate = [
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 1))),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 3))),
    label: 'Past 3 months',
  },
];

const activityOptions = [
  {
    value: 'quote_request_created',
    label: 'Quote request created',
  },
  {
    value: 'new_services_ordered',
    label: 'New services ordered',
  },
  {
    value: 'dispatch_ticket_opened',
    label: 'Dispatch ticket opened',
  },
  {
    value: 'repair_ticket_opened',
    label: 'Repair ticket opened',
  },
  {
    value: 'service_added',
    label: 'Service added',
  },
  {
    value: 'service_end_dated',
    label: 'Service end dated',
  },
];

const InventoryActivities = () => {
  const [search, setSearch] = useState('');

  const clearAllFilters = () => {};
  return (
    <>
      <div className="flex w-full flex-wrap items-start justify-between gap-4">
        <div className="w-full sm:flex-1">
          <Searchbar
            placeholder="Search by user, ID, or activity"
            clearAllValues={search === ''}
            onQueryClear={() => setSearch('')}
            onSearch={(query: string) => {
              setSearch(query);
            }}
          />
        </div>
        <Filters
          clearFilters={clearAllFilters}
          className="flex w-full flex-col items-start justify-start gap-4 sm:flex-row"
          clearFilterClassName="w-full"
        >
          <GraniteSelect
            options={activityOptions}
            className={''}
            placeholder="Filter activity type"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={() => {}}
            value={{}}
          />
          <GraniteSelect
            options={[]}
            className={''}
            placeholder="Filter by user"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={() => {}}
            value={{}}
          />
          <GraniteSelect
            options={filterByDate}
            className={''}
            placeholder="Filter by date"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={() => {}}
            value={{}}
          />
        </Filters>
      </div>
      <Activities />
    </>
  );
};

export default InventoryActivities;
