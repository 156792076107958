import { OktaGroup } from 'api/companies/schema';
import { HTMLProps } from 'react';
import { Business } from 'react-ionicons';

export const Card = ({
  group,
  onClick,
  showMacnum = false,
}: {
  group: OktaGroup;
  showMacnum?: boolean;
} & HTMLProps<HTMLButtonElement>) => (
  <button
    className="flex w-full items-center gap-x-4 rounded fill-content-base-subdued px-4 py-3 ring-1 ring-button-stroke-secondary-default"
    onClick={onClick}
  >
    <Business width="24px" height="24px" color="inherit" />
    <span className="truncate font-semibold text-content-base-default">
      {group.profile.display_name ?? group.profile.name}
    </span>
    {showMacnum && group.profile.macnum && (
      <div
        className="ml-auto flex h-6 w-20 items-center justify-center rounded-[8px] bg-teal-400 px-2 py-2 text-xs font-bold text-content-flip-default"
        title={group.profile.macnum}
      >
        <span className="truncate">{group.profile.macnum}</span>
      </div>
    )}
  </button>
);
