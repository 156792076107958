import React, { FC } from 'react';
import { ActivityProps } from 'screens/InventoryAccount/inventoryAccount.types';

const Insert: FC<Pick<ActivityProps, 'date' | 'id' | 'type' | 'user'>> = ({
  id,
  user,
  date,
  type,
}) => {
  return (
    <div className="flex justify-between">
      {type === 'tech_express_ticket' && (
        <p>
          <span className="font-bold text-white">{user}</span> opened{' '}
          <span className="font-bold text-white">Tech Express</span> ticket{' '}
          <span className="font-bold text-white">#{id}</span>
        </p>
      )}
      {type === 'invoice_added' && (
        <p>
          <span className="font-bold text-white">Invoice {id}</span> has been{' '}
          <span className="font-bold text-white">added</span>
        </p>
      )}
      {type === 'added_service' && (
        <p>
          <span className="font-bold text-white">Service ID {id}</span> has been{' '}
          <span className="font-bold text-white">added</span>
        </p>
      )}
      {type === 'new_quote_request' && (
        <p>
          <span className="font-bold text-white">{user}</span> created new{' '}
          <span className="font-bold text-white">Quote request #{id}</span>
        </p>
      )}
      {type === 'ordered_servies' && (
        <p>
          <span className="font-bold text-white">{user}</span> ordered services
          via <span className="font-bold text-white">Quote request #{id}</span>
        </p>
      )}
      {type === 'end_dated_service' && (
        <p>
          <span className="font-bold text-white">Service ID {id}</span> has been{' '}
          <span className="font-bold text-white">end dated</span>
        </p>
      )}
      <p className="text-xs font-semibold">{date}</p>
    </div>
  );
};

export default Insert;
