import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { CloseCircle } from 'react-ionicons';
import { clsx } from 'clsx';

export interface ModalProps {
  isVisible: boolean;
  close: () => void;
  className?: string;
  children: ReactNode;
  enableScrolling?: boolean;
  hideCloseButton?: boolean;
  closeOnlyOnIconClick?: boolean;
  overlayClassName?: string;
  scrollableClassName?: string;
}

export const Modal = ({
  className,
  isVisible,
  close,
  children,
  closeOnlyOnIconClick = false,
  enableScrolling = false,
  hideCloseButton = false,
  overlayClassName,
  scrollableClassName,
}: ModalProps) => {
  useEffect(() => {
    if (isVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  return isVisible
    ? createPortal(
        <div
          className={clsx(
            'fixed left-0 top-0 z-50 flex h-full w-full cursor-pointer items-center justify-center ',
            overlayClassName,
          )}
        >
          <div
            className="absolute inset-0 bg-background-base-overlay"
            {...(!closeOnlyOnIconClick && { onMouseDown: close })}
          />
          <div
            className={clsx(
              'relative mx-4 flex cursor-default flex-col rounded-lg bg-background-base-surface-2 shadow',
              className,
              {
                'max-h-[90vh]': enableScrolling,
              },
            )}
            role="dialog"
          >
            {!hideCloseButton && (
              <button
                className="absolute -right-2 mt-[-3rem] flex h-12 w-12 items-center justify-center self-end fill-button-content-ghost-default"
                title="close dialog"
                onClick={close}
              >
                <CloseCircle
                  color="inherit"
                  width="32px"
                  height="32px"
                  style={{ textAlign: 'center' }}
                />
              </button>
            )}
            <div
              className={clsx(
                {
                  '!overflow-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued':
                    enableScrolling,
                },
                scrollableClassName,
              )}
            >
              {children}
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};
