import React, { ComponentProps, ReactNode } from 'react';
import { clsx } from 'clsx';

export interface FormSectionProps extends ComponentProps<'div'> {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  className?: string;
  subtitleClassName?: string;
  gridClassName?: string;
}

export const FormSection: React.FC<FormSectionProps> = ({
  children,
  title,
  subtitle,
  className,
  subtitleClassName,
  gridClassName,
  ...props
}) => {
  return (
    <div {...props}>
      {title && (
        <h2
          className={clsx(
            'm-0 text-2xl font-bold text-white',
            !subtitle && 'mb-4',
          )}
        >
          {title}
        </h2>
      )}
      {subtitle && (
        <p
          className={clsx(
            'mb-4 text-base font-bold text-content-base-subdued',
            subtitleClassName,
          )}
        >
          {subtitle}
        </p>
      )}
      <div className={clsx('grid gap-x-4 gap-y-6', gridClassName)}>
        {children}
      </div>
    </div>
  );
};

export default FormSection;
