export const dummyData = {
  step: 2,
  state: 'TicketDetails',
  findLocationOrServiceForm: {
    identifier: { label: 'Address', value: 'Address' },
    service: [
      { label: 'Broadband', value: '3125107', service_id: '8362200640048824' },
    ],
    service_identifier: [
      {
        label: 'Broadband - 8362200640048824',
        value: '3125107',
        service_id: '8362200640048824',
        service_id_label: 'Circuit ID',
      },
    ],
    service_category: null,
    type: 'site',
    selectedIdentifier: {
      name: '04683868 - 060087 - Grove City, OH [120801]',
      address_line_1: '2997 Meadow Pond Ct',
      address_line_2: null,
      parent_macnum: 'Valvoline',
      parent_name: '03479292',
      city: 'Grove City',
      state: 'OH',
      zip: '43123',
      id: 3570048,
      account_name: '04683868',
      site_name: '60087',
      is_chronic: false,
    },
    site_type: null,
  },
  services: [
    {
      company: {
        _info: {
          company_href:
            'https://api-na.myconnectwise.net/v4_6_release/apis/3.0//company/companies/204915',
        },
        id: 204915,
        identifier: '04718434',
        name: 'Valvoline',
      },
      company_id: 204915,
      company_name: 'Valvoline',
      id: 3125107,
      name: '8362200640048824',
      product_offering: 'COAX-CABLE|200M/10M',
      service: 'Broadband',
      service_id: '8362200640048824',
      service_id_label: 'Circuit ID',
      site: {
        account_name: '04683868',
        address_line_1: '2997 Meadow Pond Ct',
        city: 'Grove City',
        id: 3570048,
        name: '04683868 - 060087 - Grove City, OH [120801]',
        parent_account: '04718434',
        parent_macnum: 'Valvoline',
        parent_name: '03479292',
        site_name: '60087',
        state: 'OH',
        zip: '43123',
      },
      site_id: 3570048,
      site_name: '04683868 - 060087 - Grove City, OH [120801]',
      status: 'Active',
      ticket_subtype: 'CABLE',
      ticket_type: 'Broadband',
      type: 'Broadband Services',
    },
  ],
  bulkLocations: [],
  serviceOptions: [
    { label: 'Select all', value: '*' },
    {
      label: 'Mobility Access / M2M',
      value: '3120409',
      service_id: '89148000007604833091',
    },
    {
      label: 'Managed SD-WAN',
      value: '3122684',
      service_id: 'VAL-OHGRO-060087',
    },
    {
      label: 'Managed Switch',
      value: '3122685',
      service_id: 'VAL-OHGRO-060087-SW1',
    },
    {
      label: 'Managed Wi-Fi',
      value: '3122686',
      service_id: 'VAL-OHGRO-060087-AP1',
    },
    { label: 'Broadband', value: '3125107', service_id: '8362200640048824' },
  ],
  details: {
    local_contact_name: 'John',
    local_contact_number: '4444444444',
    maintenance_window: 'Mon-Fri 8:00AM-5:00PM',
    access_hours: 'Mon-Fri 8:00AM-5:00PM',
    technician_dispatch_approved: undefined,
    canTestingBeDoneAnytime: undefined,
  },
};
