import { Row } from '@tanstack/react-table';
import { isAfter, isValid } from 'date-fns';

export const isAfterDate = <T>(row: Row<T>, columnId: string, value: Date) => {
  const date: Date | string | undefined = row.getValue(columnId);
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  if (!parsedDate || !isValid(parsedDate)) return false;
  return isAfter(parsedDate, new Date(value));
};
