export const TechExpressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="currentColor"
    >
      <g clipPath="url(#clip0_3505_1302)">
        <path d="M6.27182 17.8959C5.21245 17.8959 4.34995 17.5116 3.70307 16.7522C3.05151 15.9834 2.63432 14.8631 2.38588 13.2225C2.02026 10.8038 2.3812 8.7647 3.40307 7.48032C4.00307 6.72564 4.79057 6.2897 5.6812 6.2147C6.44057 6.15376 7.57963 6.46314 8.6812 8.2772C9.37026 9.41157 9.89526 10.9209 10.1203 12.4163C10.3921 14.2209 10.1906 15.5522 9.50151 16.4803C8.95776 17.2163 8.1187 17.6756 7.00776 17.8397C6.74995 17.8772 6.5062 17.8959 6.27182 17.8959ZM5.84995 7.71001C5.8312 7.71001 5.81713 7.71001 5.80307 7.71001C5.32963 7.74751 4.92182 7.98189 4.57963 8.41314C3.83432 9.35064 3.57651 11.0241 3.87182 12.9975C4.33588 16.0725 5.37182 16.5647 6.78745 16.3538C7.49995 16.2506 7.99213 15.9975 8.29682 15.585C8.72807 15.0038 8.84057 14.01 8.63901 12.6366C8.44682 11.3475 7.98276 10.0069 7.40151 9.05064C6.8812 8.20689 6.30932 7.71001 5.84995 7.71001Z" />
        <path d="M8.11405 24.21C7.4953 24.21 6.86718 24.0131 6.29999 23.6287C5.48905 23.0803 4.91718 22.2178 4.72968 21.2569C4.59843 20.5678 4.67811 20.024 4.97343 19.5881C5.45624 18.8803 6.32811 18.7162 7.24686 18.5475C7.3453 18.5287 7.44374 18.51 7.54218 18.4912C7.75311 18.449 7.96874 18.3975 8.17968 18.3459C8.96718 18.1537 9.77811 17.9522 10.4531 18.4256C10.9031 18.7444 11.1562 19.2881 11.2312 20.0803C11.3156 20.999 11.1187 21.9131 10.6781 22.6584C10.2 23.46 9.4828 23.9897 8.66249 24.1537C8.47968 24.1912 8.29686 24.21 8.11405 24.21ZM9.46405 19.635C9.23905 19.635 8.79843 19.7428 8.54061 19.8084C8.31093 19.8647 8.07655 19.9256 7.82343 19.9725C7.7203 19.9912 7.61718 20.01 7.51874 20.0287C6.9703 20.1319 6.34687 20.2444 6.21562 20.4365C6.15936 20.5209 6.15468 20.7225 6.20624 20.9756C6.31874 21.5475 6.66093 22.0631 7.14374 22.3912C7.53749 22.6584 7.98749 22.7662 8.37655 22.6912C9.23905 22.5225 9.85312 21.4162 9.74061 20.2303C9.69843 19.7615 9.58593 19.6584 9.58593 19.6584C9.5578 19.6397 9.51561 19.635 9.46405 19.635Z" />
        <path d="M17.7282 13.396C17.4938 13.396 17.25 13.3772 16.9969 13.3397C15.886 13.1757 15.0469 12.721 14.5032 11.9804C13.8141 11.0522 13.6125 9.72566 13.8844 7.91629C14.1094 6.42098 14.6297 4.9116 15.3188 3.78191C16.411 1.97254 17.5547 1.65848 18.3188 1.71473C19.2047 1.78035 19.9922 2.2116 20.5922 2.9616C21.6188 4.24598 21.9797 6.28973 21.6188 8.71785C21.3703 10.3585 20.9532 11.4788 20.3016 12.2475C19.6453 13.0116 18.7875 13.396 17.7282 13.396ZM15.3657 8.14129C15.1594 9.51473 15.2719 10.5038 15.7078 11.0897C16.0125 11.5022 16.5047 11.7507 17.2172 11.8585C18.6328 12.0694 19.6688 11.5772 20.1328 8.50223C20.4328 6.51941 20.1703 4.84129 19.4203 3.90379C19.0828 3.48191 18.675 3.25223 18.211 3.21473C17.7375 3.18191 17.1375 3.68348 16.6032 4.56004C16.0219 5.5116 15.5625 6.85223 15.3657 8.14129Z" />
        <path d="M15.8859 19.71C15.703 19.71 15.5202 19.6913 15.3374 19.6585C14.5124 19.4991 13.7999 18.9647 13.3218 18.1632C12.8765 17.4179 12.6796 16.5038 12.7687 15.585C12.8437 14.7882 13.0968 14.2491 13.5468 13.9304C14.2171 13.4522 15.0327 13.6538 15.8202 13.8507C16.0312 13.9022 16.2468 13.9538 16.4577 13.996C16.5562 14.0147 16.6593 14.0335 16.753 14.0522C17.6718 14.221 18.539 14.3804 19.0265 15.0929C19.3265 15.5288 19.4062 16.0772 19.2702 16.7616C19.0827 17.7225 18.5109 18.585 17.6999 19.1335C17.1374 19.5132 16.5093 19.71 15.8859 19.71ZM14.264 15.7257C14.1515 16.9116 14.7655 18.0132 15.628 18.1866C16.0171 18.2616 16.4671 18.1538 16.8609 17.8866C17.3437 17.5585 17.6859 17.0429 17.7984 16.471C17.8499 16.2179 17.8452 16.0163 17.789 15.9319C17.6577 15.7397 17.0343 15.6225 16.4859 15.5241C16.3874 15.5054 16.2843 15.4866 16.1812 15.4679C15.9327 15.421 15.6937 15.36 15.464 15.3038C15.1405 15.2241 14.5452 15.0788 14.4187 15.1538C14.414 15.1538 14.3062 15.2569 14.264 15.7257Z" />
      </g>
      <defs>
        <clipPath id="clip0_3505_1302">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.959961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
