import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

interface NavbarTooltipProps {
  title: string;
  children: React.ReactNode;
  hide?: boolean;
  placement?: 'right' | 'right-start';
  offset?: [number, number];
}

const NavbarTooltip = ({
  title,
  children,
  hide,
  placement = 'right',
  offset = [0, 10],
}: NavbarTooltipProps) => {
  const [visible, setVisible] = useState(false);
  const referenceRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset } },
        { name: 'eventListeners', options: { scroll: true, resize: true } },
        { name: 'zIndex', options: { padding: 1000 } },
      ],
    },
  );

  return (
    <div
      ref={referenceRef}
      onMouseEnter={() => {
        setVisible(true);
        forceUpdate?.();
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      {children}
      {!hide && (
        <div
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          className={`pointer-events-none z-[100] transition-opacity duration-150 ${
            visible ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <div className="relative whitespace-nowrap rounded bg-background-base-surface-0 p-4 text-content-base-default shadow-elevation3">
            {title}
            <div className="absolute left-0 top-1/2 -translate-y-1/2 scale-x-[0.84] transform">
              <div className="mt -ml-[9px] h-[17px] w-[17px] rotate-45 bg-background-base-surface-0"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarTooltip;
