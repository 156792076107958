import { LinkProps } from 'react-router-dom';

export interface OverviewType {
  details: {
    parent_account: string;
    child_account: string;
    service_address: string;
    created_date: string;
    update_date: string;
    gl_code: string;
    branch: string;
  };
  access: {
    access_hours: string;
    access_instructions: string | null; // N/A
    intrusive_testing_window: string;
  };
  contact: {
    main_contact: string;
    main_tn: string;
    main_email: string;
    //
    local_contact: string;
    local_tn: string;
    local_email: string;
    //
    technical_contact: string;
    technical_tn: string;
    technical_email: string;
  };
  attachments: string[];
}

//
export type CommonActivityProps = {
  user?: string;
  id: string;
  date: string;
  adminOnly?: boolean;
  tags?: string[];
  status: keyof typeof ActivityStatusType;
  linkTo?: LinkProps;
};

export type TechExpressTicketType = {
  type: 'tech_express_ticket';
  dispatchDate: string;
  dispatchTime: string;
} & CommonActivityProps;

export type NewQuoteRequestType = {
  type: 'new_quote_request';
  includedServices: string;
  locations: string;
} & CommonActivityProps;

export type InvoiceAddedType = {
  type: 'invoice_added';
  payPeriod: string;
  issued: string;
  due: string;
} & CommonActivityProps;

export type OrderedServicesType = {
  type: 'ordered_servies';
  includedServices: string;
  locations: string;
} & CommonActivityProps;

export type AddedServiceType = {
  type: 'added_service';
  completionDate: string;
  endDate?: string;
} & CommonActivityProps;

export type EndDatedServiceType = {
  type: 'end_dated_service';
  completionDate: string;
  endDate?: string;
} & CommonActivityProps;

export type ActivityProps =
  | TechExpressTicketType
  | NewQuoteRequestType
  | InvoiceAddedType
  | OrderedServicesType
  | AddedServiceType
  | EndDatedServiceType;

export enum ActivityStatusType {
  in_progress = 'In Progress',
  auto_pay_initiated = 'Auto-pay initiated',
  added = 'Added',
  end_dated = 'End Dated',
  order_placed = 'Order placed',
  checking_availability = 'Checking availability',
}

export enum ActivityType {
  tech_express_ticket = 'tech_express_ticket',
  new_quote_request = 'new_quote_request',
  invoice_added = 'invoice_added',
  ordered_servies = 'ordered_servies',
  added_service = 'added_service',
  end_dated_service = 'end_dated_service',
}
