import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import showToast from 'components/Toast/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import { regenerateKey } from './utils';
import { useModal } from 'hooks/useModal';
import DetailsKey from './DetailsKey';

const RegenerateKey = () => {
  const { isOpen, open, close } = useModal();

  const { client_id, name } = useParams<{ client_id: string; name: string }>();
  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const queryClient = useQueryClient();

  const { mutate, isLoading, data } = useMutation(regenerateKey, {
    onSuccess: () => {
      showToast.confirmation({
        title: 'API key regenerated!',
        message: `${name}`,
      });
      queryClient.refetchQueries('user-settings-api-keys');
      open();
    },
  });

  const onCloseDetailsKey = () => {
    navigate(`../../${data?.client_id}`, { replace: true });
  };

  return (
    <>
      {data && (
        <Modal isVisible={isOpen} close={onCloseDetailsKey}>
          <DetailsKey title="Key regenerated!" onClose={close} {...data} />
        </Modal>
      )}
      <Modal isVisible={!data} close={onClose} className="w-full max-w-[624px]">
        <div className="p-8">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Are you sure you want to regenerate this key?
            </h1>
            <p className="mt-2 text-base font-bold text-neutral-500">
              Are you sure you want to regenerate this API key? Regenerating
              will invalidate the current key, and you will need to update any
              applications using it.
            </p>
          </div>
          <div className="mt-8 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={onClose}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="destructive"
              disabled={isLoading}
              onClick={() => mutate(client_id as string)}
            >
              Regenerate key
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegenerateKey;
