import ModalFormHeader from 'components/Modal/ModalFormHeader';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { copyText } from './utils';
import { Copy, Eye, EyeOff } from 'react-ionicons';
import { FC, useEffect, useRef, useState } from 'react';
import { ResponseApiKeyCreate } from './keys.types';

interface DetailsKeyProps extends ResponseApiKeyCreate {
  title: string;
  onClose: () => void;
}

const DetailsKey: FC<DetailsKeyProps> = ({
  title,
  client_id,
  client_secret,
  name,
  onClose,
}) => {
  const [showSecret, setShowSecret] = useState(false);

  const onCopyClick = () => {
    copyText(
      'API secret key copied to clipboard!',
      `Client key: ${client_id}\nSecret key: ${client_secret}`,
      name || '',
    );
  };

  const maskString = (str: string) => {
    if (str.length <= 4) {
      return str; // If the string is 4 characters or fewer, no masking needed
    }
    const maskedPart = '*'.repeat(str.length - 4); // Replace all but last 4 chars with *
    const visiblePart = str.slice(-4); // Get the last 4 characters
    return maskedPart + visiblePart;
  };

  const onClickEye = () => setShowSecret((prev) => !prev);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
  }, [client_secret]);

  return (
    <div className="w-full max-w-[624px]">
      <ModalFormHeader
        title={title}
        subtitle="Your new API key has been generated and is ready to use. Here's what you need to know:"
      />
      <div className="min-h-[552px] p-8">
        <GraniteInput label="Key name" readOnly value={name} />
        <div className="my-6 flex items-end">
          <GraniteInput
            className="flex-grow basis-[90%]"
            innerInputClassName="!rounded-r-none"
            label="Client key"
            subtitle="This is a unique identifier used to authenticate and authorize access to the Granite360 API."
            readOnly
            value={client_id}
          />
          <span
            onClick={() =>
              copyText(
                'API client key copied to clipboard!',
                client_id || '',
                name || '',
              )
            }
            className="h-12 cursor-pointer rounded-r border-y border-r border-neutral-700 bg-blue-900 px-4 py-3.5 text-neutral-500 hover:text-teal-400"
          >
            <Copy width="20px" height="20px" color="currentColor" />
          </span>
        </div>
        <div className="flex items-end">
          <GraniteInput
            className="flex-grow basis-[90%]"
            innerInputClassName="!rounded-r-none !pr-10"
            label="Secret key"
            subtitle="For security reasons, your API key will only be displayed once. Copy and save this API key in a secure place. If you lose it, you will need to generate a new one."
            readOnly
            ref={inputRef}
            onBlur={() => {
              if (inputRef.current)
                inputRef.current.scrollLeft = inputRef.current.scrollWidth;
            }}
            value={showSecret ? client_secret : maskString(client_secret || '')}
            suffix={
              <span onClick={onClickEye} className="cursor-pointer select-none">
                {showSecret ? (
                  <Eye width="20px" height="20px" color="currentColor" />
                ) : (
                  <EyeOff width="20px" height="20px" color="currentColor" />
                )}
              </span>
            }
          />
          <span
            onClick={() =>
              copyText(
                'API secret key copied to clipboard!',
                client_secret || '',
                name || '',
              )
            }
            className="h-12 cursor-pointer rounded-r border-y border-r border-neutral-700 bg-blue-900 px-4 py-3.5 text-neutral-500 hover:text-teal-400"
          >
            <Copy width="20px" height="20px" color="currentColor" />
          </span>
        </div>
        <div className="mt-12 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={onClose}>
            Close
          </GraniteButton>
          <GraniteButton size="large" onClick={onCopyClick}>
            Copy keys
          </GraniteButton>
        </div>
      </div>
    </div>
  );
};

export default DetailsKey;
