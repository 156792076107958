import IconButton from 'components/IconButton';
import { FC, useRef, useState } from 'react';
import { ActivityProps } from 'screens/InventoryAccount/inventoryAccount.types';
import { motion } from 'framer-motion';

const Summary: FC<Pick<ActivityProps, 'type'>> = ({ type }) => {
  const [expand, setExpand] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <motion.div
      initial={{ height: 52 }}
      animate={{
        height: expand
          ? ref.current
            ? ref.current.offsetHeight + 32
            : 'auto'
          : 52,
      }}
      exit={{ height: 52 }}
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 15,
        mass: 1,
      }}
      onClick={() => setExpand((prev) => !prev)}
      className="cursor-pointer overflow-hidden rounded bg-blue-700 p-4 "
    >
      <div ref={ref}>
        <div className="flex items-center gap-2">
          <IconButton
            icon={expand ? 'minus' : 'add'}
            buttonType="secondary"
            size="xs"
          />
          <p className="text-sm font-bold">Summary</p>
        </div>
        {/* TODO: temporary dummy data! */}
        <div
          className="mt-4 cursor-auto pl-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {type === 'invoice_added' && (
            <ul className="ml-4 list-disc text-xs font-bold text-white">
              <li>Invoice #: 10085</li>
              <li>Payment period: 10/1/24-10/31/24</li>
              <li>Total due: $1,613.89</li>
              <li>Issued: 11/1/24</li>
              <li>Due: 11/31/24</li>
            </ul>
          )}
          {type === 'added_service' && (
            <ul className="ml-4 list-disc text-xs font-bold text-white">
              <li>Status: Active</li>
              <li>Granite provided: Yes</li>
              <li>Vendor: Time Warner</li>
              <li>Service type: Broadband</li>
              <li>Service Identifier: 8361100012101725</li>
              <li>Completion date: 8/24/24</li>
            </ul>
          )}
          {type === 'end_dated_service' && (
            <ul className="ml-4 list-disc text-xs font-bold text-white">
              <li>Status: Inactive</li>
              <li>Granite provided: No</li>
              <li>Vendor: Time Warner</li>
              <li>Service Identifier: 8361100012101725</li>
              <li>Completion date: 10/10/23</li>
              <li>End date: 6/3/24/24</li>
            </ul>
          )}
          {type === 'new_quote_request' && (
            <div>
              <p className="mb-1 text-xs font-bold text-white">Locations:</p>
              <ul className="ml-6 list-disc text-xs font-semibold">
                <li>125 Satucket Trail, Bridgewater, MA 02324</li>
                <li>5 Stephens St, Plymouth, MA 02324</li>
              </ul>
              <p className="mb-1 mt-4 text-xs font-bold text-white">
                Services:
              </p>
              <ul className="ml-6 list-disc text-xs font-semibold">
                <li>Broadband</li>
                <li>DIA</li>
              </ul>
            </div>
          )}
          {type === 'ordered_servies' && (
            <div>
              <p className="mb-1 text-xs font-bold text-white">
                125 Satucket Trail, Bridgewater, MA 02324
              </p>
              <ul className="ml-6 list-disc text-xs font-semibold">
                <li>Broadband: Verizon, 100/20 Mb/s</li>
                <li>DIA: Time Warner, 1 Gb/s</li>
              </ul>
              <p className="mb-1 mt-4 text-xs font-bold text-white">
                5 Stephens St, Plymouth, MA 02324
              </p>
              <ul className="ml-6 list-disc text-xs font-semibold">
                <li>Broadband: Comcast, 200/20 Mb/s</li>
                <li>DIA: Time Warner, 1 Gb/s</li>
              </ul>
            </div>
          )}
          {type === 'tech_express_ticket' && (
            <div className="text-xs font-semibold">
              <p className="mb-1 font-bold text-white">Scope of work:</p>
              <p>
                Tech must check in/out with Granite Bridge (Info below) failure
                to check in/out with the bridge will result in delayed payment
                or non-payment.
              </p>
              <br />
              <p>Scope of Work:</p>
              <ul>
                <li>1. Cable installation and WAN cutover.</li>
                <li>
                  2. There is an install document attached to this ticket that
                  outlines the specific steps, and the circuit information is
                  located in the special instructions area of the ticket.
                  Failure to follow the steps outlined in the install document
                  or ticket may result in loss of work and/or nonpayment.
                </li>
                <li>
                  3. Tech must check in/out with Granite Bridge (Info below)
                  failure to check in/out with the bridge can result in
                  non-payment.
                </li>
                <li>
                  4. Tech to upload deliverables to SmartSheet before checking
                  out on the Granite Bridge (link below)
                </li>
              </ul>
              <p className="mb-1 mt-4 font-bold text-white">
                Special instructions:
              </p>
              <div>
                TECH MUST ARRIVE WITH THE FOLLOWING TOOLS AND MATERIALS.
                <br />
                ===================================
                <br />
                Materials Provided by Technician
                <br />
                800ft CAT6 Cable
                <br />
                4 White surface mount boxes
                <br />
                4 CAT6 Jacks
                <br />
                10 CAT6 patch cables of various lengths <br />
                Additional Supplies: All other standard cabling materials not
                specifically listed in this document.
                <br />
                Driver’s license and a copy of their work order on their
                phone/or email.
                <br />
                <br />
                TOOLS <br />
                =============================
                <br />
                Smart Phone (With internet access which can upload pictures from
                site and video call)
                <br />
                Laptop w/ admin access and ethernet port
                <br />
                Hotspot
                <br />
                6 ladder & 8 ladder
                <br />
                Cable Tester, Toner & probe
                <br />
                Label maker
                <br />
                Sharpie/Permanent Markers
                <br />
                General Tools: All other standard cabling tools not specifically
                mentioned in this document.
                <br />
                <br />
                SITE SPECIFIC SOW DETAILS
                <br />
                ==========================
                <br />
                FULL RETAIL SOW
                <br />
                <br />
                SITE CIRCUIT DETAILS
                <br />
                ===================
                <br />
                <br />
                Date Provided 9/12/2024 -RETAIL MERAKI and CKT Q2QN-NBJV-9ZGS
                0c:8d:db:b0:47:35 1 Granite Dynamic IP 10x10 IDF: HAMP |
                IDHAMP.CENTNYWC
                <br /> Port: 2 Target Control Room
                <br />
                Granite Grid - IDF: HAMP | IDHAMP.CENTNYWC, PORT 2.
                <br />
                SUPPORT/ UPDATE/ CHECK-OUT]
                <br />
                ======================================================
                <br />
                <br />
                https://teams.microsoft.com/l/meetup-join/19%3ameeting_NDNlZjg4NDctNDExZS00YTczLWJlYmMtZWM5MGZlNGY4MWY4%40thread.v2/0?context=%7b%22Tid%22%3a%228dea12a7-9a1f-434b-8a56-8dd7fde58c4a%22%2c%22Oid%22%3a%2211fb2189-f8cc-4c0a-90c9-e5e30ba0b526%22%7d
                <br />
                <br />
                Meeting ID: 260 622 660 494
                <br />
                Passcode: c2obb9
                <br />
                Or call in (audio only)
                <br />
                +1 929-229-2436,,257672618#
                <br />
                Phone Conference ID: 257 672 618#
                <br />
                <br />
                <br />
                DELIVERIABLES
                <br />
                ==============
                <br />
                Upload all required deliverables to <br />
                https://app.smartsheet.com/b/form/1f47dd4322f444e1bdb01fea05a8a1a4
                <br />
                Before leaving the site and before calling to clock out.
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Summary;
