import { z } from 'zod';
import {
  LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../shared/constants/error-labels';
import {
  UpdateUserProfileRequest,
  UserProfileResponse,
} from '../../api/users/schemas/UserProfile';
import { TokenUserRoles } from 'api/users/schemas/Users';

export const EditUserFormSchema = z
  .object({
    roles: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
        }),
      )
      .min(1, 'At least one role must be selected')
      .refine(
        (roles) =>
          roles.filter((role) =>
            PRIMARY_ROLES.includes(role.value as PrimaryRole),
          ).length <= 1,
        {
          message:
            'You can select only one of Viewer, User, Company Admin, or Welcome User',
          path: ['roles'],
        },
      )
      .transform((roles) =>
        roles.map((role) => ({
          value: role.value,
          label: role.value,
        })),
      ),
    first_name: z.string(),
    last_name: z.string(),
    phone_number: z
      .string()
      .nonempty(VALID_PHONE_ERROR_LABEL)
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    sub_groups: z.array(z.number()).optional(),
    account: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.account && data.account.value) {
      if (
        data.roles.some((role) =>
          role.value.includes(TokenUserRoles.COMPANY_ADMIN),
        ) &&
        data.account.value !== 'all-accounts'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['account'],
          message:
            'When the role is "Company Admin", the account must be set to "All accounts".',
        });
      }
      if (
        data.account.value === 'all-accounts' &&
        data.sub_groups &&
        data.sub_groups.length > 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['sub_groups'],
          message: 'Sub groups must be empty when account is All-account',
        });
      }
      if (
        data.account.value !== 'all-accounts' &&
        data.sub_groups &&
        data.sub_groups.length === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['sub_groups'],
          message: 'At least one sub group must be selected for this account.',
        });
      }
    } else return;
  });

export type EditUserForm = z.infer<typeof EditUserFormSchema>;

const PRIMARY_ROLES = [
  'viewer',
  'user',
  'company-admin',
  'welcome-user',
  'Viewer',
  'User',
  'Company Admin',
  'Welcome User',
] as const;

type PrimaryRole = (typeof PRIMARY_ROLES)[number];

export const InviteUserFormSchema = z
  .object({
    first_name: z
      .string()
      .nonempty('Required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
        message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
      }),
    last_name: z
      .string()
      .nonempty('Required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
        message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
      }),
    email: z.string().nonempty('Required').email(),
    phone: z
      .string()
      .nonempty(VALID_PHONE_ERROR_LABEL)
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    companyId: z.number().nullish(),
    roles: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    ),
    sub_groups: z.array(z.number()).optional(),
    account: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.account && data.account.value) {
      if (
        data.roles.some((role) =>
          role.value.includes(TokenUserRoles.COMPANY_ADMIN),
        ) &&
        data.account.value !== 'all-accounts'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['account'],
          message:
            'When the role is "Company Admin", the account must be set to "All accounts".',
        });
      }
      if (
        data.account.value === 'all-accounts' &&
        data.sub_groups &&
        data.sub_groups.length > 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['sub_groups'],
          message: 'Sub groups must be empty when account is All-account',
        });
      }
      if (
        data.account.value !== 'all-accounts' &&
        data.sub_groups &&
        data.sub_groups.length === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['sub_groups'],
          message: 'At least one sub group must be selected for this account.',
        });
      }
    } else return;
  });

export type InviteUserForm = z.infer<typeof InviteUserFormSchema>;

export const responseToUserEditForm = (
  response?: UserProfileResponse,
): Omit<EditUserForm, 'account' | 'sub_groups'> | undefined => {
  if (!response) return undefined;
  return {
    roles: response.roles,
    first_name: response.first_name,
    last_name: response.last_name,
    phone_number: response.phone_number,
    // sub_groups: response.roles.some((role) =>
    //   role.value.includes(TokenUserRoles.COMPANY_ADMIN),
    // )
    //   ? []
    //   : response.sub_groups?.map((subGroup) => subGroup.profile.cw_id) || [],
  };
};

export const userEditFormToEditRequest = (
  form: EditUserForm,
  base: UserProfileResponse,
): Omit<UpdateUserProfileRequest, 'sub_groups'> => {
  return {
    first_name: form.first_name,
    last_name: form.last_name,
    phone_number: form.phone_number,
    roles: form.roles.map((role) => role.value),
    company_title: base.company_title,
    department: base.department,
    secondary_email: base.secondary_email,
    secondary_phone_number: base.secondary_phone_number,
  };
};
