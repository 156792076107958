import { apiClient } from 'api/apiClient';
import {
  GetCompaniesResponse,
  GetCompaniesResponseSchema,
  GetGroupResponse,
  GetGroupsParams,
  OktaGroupProfile,
} from './schema';

export const getCompanyAdmins = async () => {
  const response = await apiClient.get(`/api/v1/users/admin-contacts`);

  return response.data;
};

export const getSites = async () => {
  const response = await apiClient.get('/api/v1/companies/sites/download/', {
    headers: { 'Cache-Control': 'no-store' },
    params: { _t: new Date().getTime() },
  });
  return response.data;
};

export const getGroups = async (
  parameters?: GetGroupsParams,
): Promise<GetCompaniesResponse> => {
  const queryParams = {
    ...parameters,
  };
  const response = await apiClient.get('/api/v1/groups', {
    params: queryParams,
  });
  try {
    GetCompaniesResponseSchema.parse(response.data);
  } catch (error) {
    console.error(error);
  }
  return GetCompaniesResponseSchema.parse(response.data);
};

export const getGroup = async (groupId: string): Promise<GetGroupResponse> => {
  const response = await apiClient.get(`/api/v1/groups/${groupId}`);
  return response.data;
};

export const updateGroup = async (
  request: OktaGroupProfile,
  groupId: string,
) => {
  const response = await apiClient.patch(`/api/v1/groups/${groupId}`, request);

  return response.data;
};

export const updatePricingTier = async (
  request: Pick<OktaGroupProfile, 'pricing_tier'>,
  groupId: string,
) => {
  const response = await apiClient.patch(
    `/api/v1/groups/${groupId}/pricing`,
    request,
  );

  return response.data;
};

export const createGroup = async (request: OktaGroupProfile) => {
  const response = await apiClient.post(`/api/v1/groups`, request);

  return response.data;
};
