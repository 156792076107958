import React, { FC } from 'react';
import { OverviewType } from '../inventoryAccount.types';
import CollapsableContent, {
  CollapsableContentProps,
} from './CollapsableContent';
import ContentItem from './ContentItem';
import TextContent from './TextContent';
import { PeopleCircleOutline } from 'react-ionicons';

export interface IContacts
  extends Pick<CollapsableContentProps, 'expand' | 'togglePreference'>,
    Pick<OverviewType, 'contact'> {}

const Contacts: FC<IContacts> = ({ contact, expand, togglePreference }) => {
  return (
    <CollapsableContent
      title="Contacts"
      icon={<PeopleCircleOutline color="currentColor" />}
      expand={expand}
      togglePreference={togglePreference}
    >
      <ContentItem
        title="Main contact"
        content={<TextContent content={contact.main_contact} />}
      />
      <ContentItem
        title="Main contact TN"
        content={<TextContent content={contact.main_tn} />}
      />
      <ContentItem
        title="Main contact email"
        content={<TextContent content={contact.main_email} />}
      />
      <div className="my-2" />
      <ContentItem
        title="Local contact"
        content={<TextContent content={contact.local_contact} />}
      />
      <ContentItem
        title="Local contact TN"
        content={<TextContent content={contact.local_tn} />}
      />
      <ContentItem
        title="Local contact email"
        content={<TextContent content={contact.local_email} />}
      />
      <div className="my-2" />
      <ContentItem
        title="Technical contact"
        content={<TextContent content={contact.technical_contact} />}
      />
      <ContentItem
        title="Technical contact TN"
        content={<TextContent content={contact.technical_tn} />}
      />
      <ContentItem
        title="Technical contact email"
        content={<TextContent content={contact.technical_email} />}
      />
    </CollapsableContent>
  );
};

export default Contacts;
