import { ColumnDef, Row } from '@tanstack/react-table';
import { PageTitleGranite } from 'components';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { useAuthUser } from 'hooks/useAuthUser';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import Searchbar from 'components/Table/SearchBar';
import { useModal } from 'hooks/useModal';
import { ManageCompanyDialog } from './ManageCompanyModal';
import { OktaGroup } from 'api/companies/schema';
import { getGroups } from 'api/companies/api';
import { useInfiniteQuery } from 'react-query';
import Loader from 'components/Loader';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import clsx from 'clsx';
import { usePermissions } from 'hooks/usePermissions';

const Companies: React.FC = () => {
  const { companyName } = useAuthUser();
  const loadMoreRef = useRef(null);
  const [search, setSearch] = useState('');
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['companies', { search }],
    queryFn: ({ pageParam }) => {
      return getGroups({ after: pageParam, search });
    },
    getNextPageParam: (lastPage) => {
      return lastPage.metadata.next;
    },
  });

  const groups = useMemo(
    () => data?.pages.map(({ data }) => data).flat() ?? [],
    [data],
  );

  const CompaniesHeaders: ColumnDef<OktaGroup>[] = [
    {
      header: 'Company macnum',
      // TODO: handle more than one cw_link
      cell: (row) => (
        <TicketIdBadge>{row.row.original?.profile?.macnum}</TicketIdBadge>
      ),
      enableSorting: false,
    },
    {
      header: 'Company name',
      accessorKey: 'display_name',
      enableSorting: false,
      cell: (row) => (
        <div className="text-sm font-medium">
          {row.row.original.profile.display_name ??
            row.row.original.profile.name}
        </div>
      ),
    },
    {
      header: 'Pricing tier',
      enableSorting: false,
      cell: (row) => {
        const discountRange = {
          Standard: {
            bgColor: 'bg-purple-300',
            label: 'Standard',
          },
          'Highest tier': {
            bgColor: 'bg-red-300',
            label: 'Highest tier',
          },
          'High tier': {
            bgColor: 'bg-orange-300',
            label: 'High tier',
          },
          'Low tier': {
            bgColor: 'bg-blue-300',
            label: 'Low tier',
          },
          'Lowest tier': {
            bgColor: 'bg-teal-300',
            label: 'Lowest tier',
          },
        };

        return (
          <>
            <div
              className={clsx(
                'flex h-6 items-center justify-center rounded-[8px] px-2 py-2 text-xs font-bold text-blue-900',
                discountRange[
                  row.row.original.profile.pricing_tier ?? 'Standard'
                ].bgColor,
              )}
            >
              {
                discountRange[
                  row.row.original.profile.pricing_tier ?? 'Standard'
                ].label
              }
            </div>
          </>
        );
      },
    },
    {
      header: 'Multiplier %',
      enableSorting: false,
      cell: (row) => {
        const discountRange = {
          Standard: '0%',
          'Highest tier': '+15%',
          'High tier': '+7%',
          'Low tier': '-7%',
          'Lowest tier': '-13%',
        };

        return (
          <>
            <div
              className={clsx(
                'flex h-6 items-center justify-center rounded-[8px] px-2 py-2 text-xs font-bold',
              )}
            >
              {
                discountRange[
                  row.row.original.profile.pricing_tier ?? 'Standard'
                ]
              }
            </div>
          </>
        );
      },
    },
  ];

  const { open, openWithProps, ...modalProps } = useModal<{
    groupId: string;
    profile?: OktaGroup['profile'];
  }>();

  const { isSuperAdmin } = usePermissions();

  const handleRowClick = (row: Row<OktaGroup>) => {
    openWithProps({
      groupId: row.original.id,
      ...(isSuperAdmin ? {} : { profile: row.original.profile }),
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage(); // Fetch the next page when the end is reached
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <ContentLayout>
      <div className="flex w-full flex-col items-start justify-start">
        <PageTitleGranite title="Companies" className="w-full items-center">
          <DisplayForPermission onlyForSuperAdmin>
            <div className="my-auto">
              <GraniteButton
                size="large"
                variant="primary"
                onClick={() => openWithProps()}
              >
                Add company
              </GraniteButton>
            </div>
          </DisplayForPermission>
        </PageTitleGranite>
        <div className="mb-8 mt-6 flex flex-col items-start justify-start gap-2">
          <div>
            <GraniteLabel className="text-base font-bold">
              Current company
            </GraniteLabel>
            <h1 className="text-2xl font-bold text-content-base-default">
              {companyName}
            </h1>
          </div>
          <p className="text-base font-semibold text-content-base-default">
            You are currently accessing Granite360 as a Granite Super Admin from{' '}
            {companyName}.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-12">
          <Searchbar
            placeholder="Search by macnum or company name"
            onQueryClear={() => setSearch('')}
            onSearch={setSearch}
          />
          <ServerPaginatedTable
            data={groups}
            columns={CompaniesHeaders}
            title="Companies"
            isFetchingData={isLoading}
            showPagination={false}
            // mock the following required props
            // pagination is handled by loadMoreRef
            paginationState={{
              pageIndex: 0,
              pageSize: 1,
            }}
            onPaginationChange={() => {}}
            itemCount={0}
            pageCount={0}
            handleRowClick={handleRowClick}
            tableContainerClassName="table-column-mobile sticky-first-column-mobile"
          />
        </div>
        {hasNextPage && (
          <div ref={loadMoreRef} className="mx-auto mt-4">
            <Loader />
          </div>
        )}
        <ManageCompanyDialog
          {...modalProps}
          onClose={function (): void {
            modalProps.close();
          }}
          onCompanySuccess={() => refetch()}
        />
      </div>
    </ContentLayout>
  );
};

export default Companies;
