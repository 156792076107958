import { OverviewType } from '../inventoryAccount.types';

const overview = {
  details: {
    parent_account: '#001233689',
    child_account: '#02447738',
    service_address: '456 Oak Avenue, Afton, ME 22344',
    created_date: '8/5/22',
    update_date: '9/12/24',
    gl_code: '1001-Afton',
    branch: '9/12/24',
  },
  access: {
    access_hours: 'Mon-Fri, 8:00 AM - 5:00 PM',
    access_instructions: null, // N/A
    intrusive_testing_window:
      'Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST',
  },
  contact: {
    main_contact: 'Beth Washburn',
    main_tn: '(508) 279-2031',
    main_email: 'BWashburn@outlook.com',
    //
    local_contact: 'Jim Barnes',
    local_tn: '(508) 597-6642',
    local_email: 'JBarnes@outlook.com',
    //
    technical_contact: 'Matthew Yates',
    technical_tn: '(508) 597-2231',
    technical_email: 'MYates@outlook.com',
  },
  attachments: ['Network_diagram', 'Floor_plan', 'Call_flow'],
};

export const getOverview = async (): Promise<OverviewType> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      resolve(overview);
    }, 1000);
  });
};
