import { useQuery } from 'react-query';
import { getOverview } from './utils';
import Location from './Location';
import Loader from 'components/Loader';
import Access from './Access';
import Contacts from './Contacts';
import Attachments from './Attachments';
import useLocalStorage from './hooks/useLocalStorage';
// import SimilarAccount from './similarAccount';

const InventoryOverview = () => {
  const { data, isLoading } = useQuery(
    ['inventory-account-overview'],
    () => getOverview(),
    {
      refetchOnMount: true,
    },
  );

  const [preferences, setPreferences] = useLocalStorage(
    'inventory-preferences',
    {
      location: true,
      access: false,
      contacts: false,
      attachments: false,
    },
  );

  const togglePreference = (key: keyof typeof preferences) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (isLoading) return <Loader />;

  return (
    <div className="flex gap-8">
      <div className="flex flex-1 flex-col gap-8">
        {data?.details && (
          <Location
            details={data?.details}
            expand={preferences.location}
            togglePreference={() => togglePreference('location')}
          />
        )}
        {data?.access && (
          <Access
            access={data?.access}
            expand={preferences.access}
            togglePreference={() => togglePreference('access')}
          />
        )}
        {data?.contact && (
          <Contacts
            contact={data?.contact}
            expand={preferences.contacts}
            togglePreference={() => togglePreference('contacts')}
          />
        )}
        {data?.attachments && (
          <Attachments
            attachments={data?.attachments}
            expand={preferences.attachments}
            togglePreference={() => togglePreference('attachments')}
          />
        )}
      </div>
      {/* Comment temporary, will use it when BE is ready!*/}
      {/* <SimilarAccount /> */}
    </div>
  );
};

export default InventoryOverview;
