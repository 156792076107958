import { BasePagination, PaginableRequest } from 'api/common-schemas';
import { SubGroupType } from './accounts.types';
import { apiClient } from 'api/apiClient';

export const getSubGroup = async (
  args: PaginableRequest,
): Promise<BasePagination<SubGroupType>> => {
  const response = await apiClient.get('/api/v1/groups/parents', {
    params: {
      ...args,
    },
  });
  return response.data;
};
