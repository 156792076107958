import React from 'react';
import Activity from './activity';

const Activities = () => {
  return (
    <div className="mt-12 flex max-w-[829px] flex-col gap-8">
      <Activity
        type="tech_express_ticket"
        date="11/3/24"
        id="2203994"
        user="Carah Kennedy"
        tags={['Broadband', 'Repair']}
        dispatchDate="11/3/24"
        dispatchTime="8:00 AM - 5:00 PM"
        status="in_progress"
        linkTo={{ to: '/tech-express/2203994' }}
      />
      <Activity
        type="invoice_added"
        date="11/1/24"
        id="10085"
        adminOnly
        payPeriod="10/1/24-10/31/24"
        issued="11/1/24"
        due="11/31/24"
        status="auto_pay_initiated"
        linkTo={{ to: '/noc-express/10085' }}
      />
      <Activity
        type="added_service"
        date="8/24/24"
        id="8361100012101725"
        completionDate="08/24/24"
        status="added"
        linkTo={{ to: '/inventory' }}
      />
      <Activity
        type="new_quote_request"
        date="10/31/24"
        id="1002544"
        user="Jane Smith"
        adminOnly
        includedServices="Broadband, DIA"
        locations="1"
        status="checking_availability"
        tags={['Broadband', 'DIA']}
        linkTo={{ to: '/access-express/quote-details/1002544' }}
      />
      <Activity
        type="ordered_servies"
        date="10/31/24"
        id="0023358"
        user="Jane Smith"
        adminOnly
        includedServices="Broadband, DIA"
        locations="1"
        status="order_placed"
        tags={['Broadband', 'DIA']}
        linkTo={{ to: '/access-express/quote-details/0023358' }}
      />
      <Activity
        type="end_dated_service"
        date="6/3/24"
        id="FGT60FTK2109CGNR"
        completionDate="6/3/21"
        endDate="6/3/24"
        status="end_dated"
        linkTo={{ to: '/inventory' }}
      />
    </div>
  );
};

export default Activities;
