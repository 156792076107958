import showToast from 'components/Toast/Toast';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ChevronDown, ChevronUp, CopyOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import { ActivityProps } from 'screens/InventoryAccount/inventoryAccount.types';

interface HeaderProps extends Pick<ActivityProps, 'type' | 'id' | 'linkTo'> {
  expand: boolean;
  setExpand: Dispatch<SetStateAction<HeaderProps['expand']>>;
}

const Header: FC<HeaderProps> = ({ type, id, expand, setExpand, linkTo }) => {
  //
  const onCopy = () => {
    navigator.clipboard.writeText(id).then(() => {
      showToast.confirmation({
        message: 'Copied to clipboard!',
      });
    });
  };

  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center gap-2">
        <Link to={linkTo?.to || ''}>
          <HeaderTitle type={type} id={id} linkTo={linkTo} />
        </Link>
        {(type === 'tech_express_ticket' ||
          type === 'invoice_added' ||
          type === 'new_quote_request' ||
          type === 'ordered_servies') && (
          <span className="cursor-pointer hover:text-teal-400" onClick={onCopy}>
            <CopyOutline color="currentColor" width="16px" height="16px" />
          </span>
        )}
      </div>
      <GraniteButton
        variant="secondary"
        onClick={() => setExpand((prev) => !prev)}
      >
        {expand ? (
          <>
            Collapse
            <ChevronUp color="currentColor" width="20px" height="20px" />
          </>
        ) : (
          <>
            Expand
            <ChevronDown color="currentColor" width="20px" height="20px" />
          </>
        )}
      </GraniteButton>
    </div>
  );
};

export default Header;

const HeaderTitle: FC<Pick<ActivityProps, 'type' | 'id' | 'linkTo'>> = ({
  type,
  id,
  // linkTo,
}) => {
  if (type === 'tech_express_ticket')
    return <p className="font-bold text-white">Ticket # {id}</p>;
  if (type === 'new_quote_request' || type === 'ordered_servies')
    return <p className="font-bold text-white">QR # {id}</p>;
  if (type === 'invoice_added')
    return <p className="font-bold text-white">Invoice # {id}</p>;
  if (type === 'added_service')
    return <p className="font-bold text-white">Broadband</p>;
  if (type === 'end_dated_service')
    return (
      <p className="font-bold text-white">Managed - Premise Firewall Branch</p>
    );
};
