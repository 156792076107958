import { z } from 'zod';

export const OktaGroupProfileSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  display_name: z.string().nullish(),
  macnum: z.string().nullish(),
  cw_id: z.number().nullish(),
  cw_type: z.enum(['company', 'site']).nullish(),
  parent_id: z.string().nullish(),
  pricing_tier: z
    .enum(['Standard', 'Highest tier', 'High tier', 'Low tier', 'Lowest tier'])
    .optional(),
});

export const OktaGroupSchema = z.object({
  id: z.string(),
  profile: OktaGroupProfileSchema,
});

export type OktaGroup = z.infer<typeof OktaGroupSchema>;

export const GetCompaniesResponseSchema = z.object({
  data: z.array(OktaGroupSchema),
  metadata: z.object({
    after: z.string().nullish(),
    limit: z.number(),
    next: z.string().nullish(),
  }),
});

export const GetGroupResponseSchema = z.object({
  cw_company_id: z.array(z.number()),
  cw_id: z.number(),
  cw_type: z.enum(['company', 'site']).nullish(),
  description: z.string(),
  display_name: z.string(),
  info: z.object({
    cw_links: z.array(
      z.object({
        id: z.number(),
        macnum: z.string(),
        type: z.string(),
      }),
    ),
  }),
  macnum: z.string(),
  name: z.string(),
  pricing_tier: z.string(),
});

export type GetGroupResponse = z.infer<typeof GetGroupResponseSchema>;
export type GetCompaniesResponse = z.infer<typeof GetCompaniesResponseSchema>;

export const ModifiedOktaGroupProfile = OktaGroupProfileSchema.superRefine(
  (data, ctx) => {
    if (!data.name || data.name.trim() === '') {
      ctx.addIssue({
        path: ['name'],
        message: 'Required',
        code: 'custom',
      });
    }

    if (!data.display_name || data.display_name.trim() === '') {
      ctx.addIssue({
        path: ['display_name'],
        message: 'Required',
        code: 'custom',
      });
    }
  },
);

export type OktaGroupProfile = z.infer<typeof ModifiedOktaGroupProfile>;

export const GetGroupsParamsSchema = z
  .object({
    search: z.string(),
    limit: z.number(),
    after: z.string().optional(),
  })
  .partial();

export type GetGroupsParams = z.infer<typeof GetGroupsParamsSchema>;
