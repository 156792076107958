import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export const ContentLayout = ({
  className,
  ...props
}: ComponentProps<'div'>) => {
  return (
    <div
      className={clsx(
        'relative my-0 flex h-full flex-1 grow flex-col justify-center p-4 pb-32 sm:px-8 sm:pt-8',
        className,
      )}
      {...props}
    />
  );
};
