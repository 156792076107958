import { ComponentProps, forwardRef, ReactNode, Ref } from 'react';
import { GraniteLabel } from '../Label/GraniteLabel';
import { clsx } from 'clsx';
import { CloseCircle } from 'react-ionicons';
import { ErrorSubtext } from '../../ErrorSubtext/ErrorSubtext';

export type FileLike =
  | File
  | { name?: string; filename?: string; id?: number; type?: string };

export interface FileUploadProps
  extends Omit<ComponentProps<'input'>, 'onChange' | 'value'> {
  label?: string;
  subtitle?: string | ReactNode;
  value?: FileLike[];
  onChange: (files: FileLike[]) => void;
  error?: string;
  onRemove?: (file: FileLike, index: number) => void;
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    { label, subtitle, className, value, onChange, onRemove, error, ...props },
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleRemove = onRemove
      ? onRemove
      : (file: FileLike, index: number) => {
          const newValues = [...(value ?? [])];
          newValues.splice(index, 1);
          onChange(newValues);
        };

    return (
      <div className={clsx('flex flex-col gap-4', className)}>
        <GraniteLabel label={label} subtitle={subtitle}>
          <input
            type="file"
            className="hidden"
            onChange={(e) => {
              if (props.multiple) {
                onChange([
                  ...Array.from(e.target.files ?? []),
                  ...(value ?? []),
                ]);
              } else {
                onChange(Array.from(e.target.files ?? []));
              }
            }}
            onClick={(e) => {
              if (e.target instanceof HTMLInputElement) {
                e.target.value = '';
              }
            }}
            {...props}
          />
          <span
            ref={ref}
            className="button medium primary max-w-full cursor-pointer xs:max-w-[128px]"
            role="button"
            tabIndex={0}
          >
            Choose file
          </span>
        </GraniteLabel>
        {(value?.length ?? 0) > 0 && (
          <div className="flex flex-wrap gap-2">
            {value?.map((v, i) => (
              <div
                key={i}
                className="flex h-8 items-center overflow-hidden rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2"
              >
                <span className="truncate fill-content-base-subdued pr-1 text-content-base-default">
                  {v.name}
                </span>
                <button className="fill-content-base-subdued" type="button">
                  <CloseCircle
                    width="16px"
                    height="16px"
                    color="inherit"
                    onClick={() => handleRemove(v, i)}
                  />
                </button>
              </div>
            ))}
          </div>
        )}
        <ErrorSubtext error={error} withTopMargin={false} />
      </div>
    );
  },
);

FileUpload.displayName = 'FileUpload';
