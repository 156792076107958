import { useCallback, useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';

import { useClickOutside } from 'hooks/useClickOutside';
import {
  RadioButtonInput,
  StyledRadioButton,
} from 'components/RadioButton/RadioButton.styles';

interface SubGroupDropdownMenuProps {
  items: SubGroupDropdownItem[];
  isNavbarExpanded: boolean;
  onClickOutside: () => void;
  outsideRefs: React.RefObject<HTMLDivElement>[];
  show: 'sub-group-wide' | 'sub-group-portal' | null;
}

export const SubGroupDropdownMenu = ({
  items,
  isNavbarExpanded,
  onClickOutside,
  outsideRefs,
  show,
}: SubGroupDropdownMenuProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const subMenu = (portal: boolean, isNavbarExpanded: boolean) => (
    <div
      className={clsx('relative py-2 transition-all duration-300  ease-out ', {
        'bg-opacity-85 rounded bg-[#262636] shadow-elevation5 backdrop-blur':
          portal,
        'bg-[#333346]': !portal,
        'rounded px-2': !isNavbarExpanded,
        'rounded-b': isNavbarExpanded,
      })}
    >
      <div className="vertical-scrollbar flex max-h-[198px] flex-col gap-1 overflow-y-auto !scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued">
        {items.map((item) => (
          <SubGroupDropdownItem
            key={item.title}
            isNavbarExpanded={isNavbarExpanded}
            {...item}
          />
        ))}
      </div>
    </div>
  );

  useClickOutside(
    [...outsideRefs, menuRef],
    () => !isNavbarExpanded && onClickOutside(),
  );

  const { styles, attributes, forceUpdate } = usePopper(
    targetRef.current,
    menuRef.current,
    {
      placement: 'right-start',
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [-48, 12] } },
        { name: 'eventListeners', options: { scroll: true, resize: true } },
        { name: 'zIndex', options: { padding: 1000 } },
      ],
    },
  );

  useEffect(() => {
    forceUpdate?.();
  }, [forceUpdate, show, isNavbarExpanded]);

  if (isNavbarExpanded)
    return (
      <div
        className={clsx(
          'overflow-hidden transition-all duration-300 ease-in-out',
          {
            'max-h-[214px] opacity-100': show === 'sub-group-wide',
            'max-h-0 opacity-0': show !== 'sub-group-wide',
          },
        )}
      >
        {subMenu(false, true)}
      </div>
    );

  return (
    <div
      className={clsx({
        visible: show === 'sub-group-portal',
        hidden: show !== 'sub-group-portal',
      })}
      ref={targetRef}
    >
      <div ref={menuRef} style={styles.popper} {...attributes.popper}>
        {subMenu(true, false)}
      </div>
    </div>
  );
};

interface SubGroupDropdownItem {
  onClick: () => void;
  title: string;
  isSelected: boolean;
}

interface SubGroupDropdownItemProps
  extends SubGroupDropdownItem,
    Pick<SubGroupDropdownMenuProps, 'isNavbarExpanded'> {}

const SubGroupDropdownItem = ({
  onClick,
  isSelected,
  isNavbarExpanded,
  title,
}: SubGroupDropdownItemProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      onClick();
    },
    [onClick],
  );

  return (
    <div
      className={clsx(
        'group flex w-full cursor-pointer flex-row items-center rounded p-2 transition-all duration-300 ease-out',
        isSelected && 'text-content-base-default',
        !isNavbarExpanded && 'w-[180px] hover:bg-[#2B2B3C]',
      )}
      onClick={handleClick}
    >
      <div
        className={clsx(
          'flex w-full items-center text-xs',
          isSelected ? 'font-bold' : 'font-semibold',
        )}
      >
        <RadioButtonInput checked={isSelected} />
        <StyledRadioButton checked={isSelected} />
        <span className="truncate" title={title}>
          {title}
        </span>
      </div>
    </div>
  );
};
