import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createPopper } from '@popperjs/core';

interface TooltipProps {
  children: React.ReactNode;
  tooltipText: React.ReactNode;
  isVisible?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const CheckboxTooltip: React.FC<TooltipProps> = ({
  children,
  tooltipText,
  isVisible = true,
  placement = 'top',
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const tooltipElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showTooltip && referenceElement.current && tooltipElement.current) {
      const popperInstance = createPopper(
        referenceElement.current,
        tooltipElement.current,
        {
          placement,
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        },
      );
      return () => popperInstance.destroy();
    }
  }, [showTooltip, placement]);

  return (
    <div
      ref={referenceElement}
      onMouseEnter={() => isVisible && setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className="relative inline-block"
    >
      {children}
      {showTooltip &&
        ReactDOM.createPortal(
          <div
            ref={tooltipElement}
            className="z-50 rounded bg-black px-3 py-2 text-xs text-white shadow-md"
          >
            {tooltipText}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default CheckboxTooltip;
