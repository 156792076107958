export const MobilityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_3505_2864)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75 2.46265C7.92157 2.46265 7.25 3.13422 7.25 3.96265V21.9626C7.25 22.7911 7.92157 23.4626 8.75 23.4626H16.25C17.0784 23.4626 17.75 22.7911 17.75 21.9626V3.96265C17.75 3.13422 17.0784 2.46265 16.25 2.46265H15.4524C15.3859 2.71991 15.2517 2.95723 15.0607 3.14831C14.7794 3.42961 14.3978 3.58765 14 3.58765H11C10.6022 3.58765 10.2206 3.42961 9.93934 3.14831C9.74826 2.95723 9.61406 2.71991 9.54763 2.46265H8.75ZM8.75 0.962646H16.25C17.9069 0.962646 19.25 2.30579 19.25 3.96265V21.9626C19.25 23.6195 17.9069 24.9626 16.25 24.9626H8.75C7.09315 24.9626 5.75 23.6195 5.75 21.9626V3.96265C5.75 2.30579 7.09315 0.962646 8.75 0.962646Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3505_2864">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.962646)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
