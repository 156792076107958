import React, { FC } from 'react';
import CollapsableContent, {
  CollapsableContentProps,
} from './CollapsableContent';
import { NavigateCircleOutline } from 'react-ionicons';
import ContentItem from './ContentItem';
import Tag from 'components/Tag';
import TextContent from './TextContent';
import { OverviewType } from '../inventoryAccount.types';

export interface ILocation
  extends Pick<CollapsableContentProps, 'expand' | 'togglePreference'>,
    Pick<OverviewType, 'details'> {}

const Location: FC<ILocation> = ({ details, expand, togglePreference }) => {
  return (
    <CollapsableContent
      title="Location details"
      icon={<NavigateCircleOutline color="currentColor" />}
      expand={expand}
      togglePreference={togglePreference}
    >
      {details.parent_account && (
        <ContentItem
          title="Parent account #"
          content={
            <div className="w-max">
              <Tag
                status="neutral"
                style="default"
                content={{ type: 'label', label: details.parent_account }}
              />
            </div>
          }
        />
      )}
      {details.child_account && (
        <ContentItem
          title="Child account #"
          content={
            <div className="w-max">
              <Tag
                status="neutral"
                style="default"
                content={{ type: 'label', label: details.child_account }}
              />
            </div>
          }
        />
      )}
      {details.service_address && (
        <ContentItem
          title="Service address"
          content={<TextContent content={details.service_address} />}
        />
      )}
      {details.created_date && (
        <ContentItem
          title="Date created"
          content={<TextContent content={details.created_date} />}
        />
      )}
      {details.update_date && (
        <ContentItem
          title="Last updated"
          content={<TextContent content={details.update_date} />}
        />
      )}
      {details.gl_code && (
        <ContentItem
          title="GL code"
          content={<TextContent content={details.gl_code} />}
        />
      )}
      {details.branch && (
        <ContentItem
          title="Branch #"
          content={<TextContent content={details.branch} />}
        />
      )}
    </CollapsableContent>
  );
};

export default Location;
