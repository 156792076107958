import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { NetOpsResponseForm } from './form-schema';
import { returnLayerStatusOfTicket } from './util';

export interface TicketDetailHeaderProps extends ComponentProps<'div'> {
  buttons?: React.ReactNode | null;
  id: string | undefined;
  ticket?: NetOpsResponseForm;
}

export const TicketDetailHeader = ({
  buttons,
  id,
  ticket,
}: TicketDetailHeaderProps) => {
  const navigate = useNavigate();
  const mainStatus = returnLayerStatusOfTicket(ticket?.status_name as string);
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-col">
          <Breadcrumb
            breadcrumbs={[
              {
                icon: 'home',
                label: 'NOCExpress',
                onClick: () =>
                  navigate('/noc-express?tab=tickets', { replace: true }),
              },
            ]}
          />
          <div className="flex gap-6">
            <h1 className="text-4xl font-bold leading-9 text-content-base-default">
              #{id}
            </h1>
          </div>
        </div>
        {buttons && (
          <div className="flex flex-wrap gap-3 max-xs:w-full">{buttons}</div>
        )}
      </div>
      <div className="grid grid-cols-3 items-end gap-1">
        <WizardStateBlock label="Open" isActive={true} />
        <WizardStateBlock label="In progress" isActive={mainStatus >= 2} />
        <WizardStateBlock label="Resolved" isActive={mainStatus === 3} />
      </div>
    </div>
  );
};
