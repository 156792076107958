import DonutChart from 'components/Charts/DonutChart';
import theme from '../../shared/theme/custom-theme';
import { useMemo, useState } from 'react';
import clsx from 'clsx';
import HorizontalBarChart, {
  BarChartData,
} from 'components/Charts/HorizontalBarChart';
import { inventoryChartFilterOptions } from './utils';
import { useQuery } from 'react-query';
import {
  getInventoryServicesChanges,
  getInventoryServicesCounts,
} from 'api/inventory/api';
import Skeleton from 'components/Skeleton/Skeleton';
import { SingleValue } from 'react-select';
import { OptionType } from 'components/Select/Select';

const Metrics = () => {
  const [selectedDateRange, setSelectedDateRange] = useState('Past Month');

  const { data, isLoading } = useQuery(
    ['inventory-services-count'],
    () => getInventoryServicesCounts(),
    {
      enabled: true,
      staleTime: Infinity,
    },
  );

  const { data: serviceLifecycleData, isLoading: isLifecycleLoading } =
    useQuery(
      ['inventory-services-changes', selectedDateRange],
      () => getInventoryServicesChanges(selectedDateRange),
      {
        enabled: !!selectedDateRange,
      },
    );

  const buildBarChartData = (
    addedCount: number | undefined,
    endDatedCount: number | undefined,
  ): BarChartData[] => {
    return [
      {
        category: 'End dated',
        value: endDatedCount ?? 0,
        backgroundColor: theme.colors.bar.priority2,
      },
      {
        category: 'Added',
        value: addedCount ?? 0,
        backgroundColor: theme.colors.bar.priority1,
      },
    ];
  };

  const serviceLifeCycleChartData = useMemo(() => {
    const addedCount = serviceLifecycleData?.added ?? 0;
    const endDatedCount = serviceLifecycleData?.end_dated ?? 0;

    return buildBarChartData(addedCount, endDatedCount);
  }, [serviceLifecycleData]);

  const handleFilterChange = (option: SingleValue<OptionType>) => {
    if (option?.value) {
      setSelectedDateRange(option.value);
    }
  };

  const buildDonutChartData = (serviceTypes: Record<string, number>) => {
    const predefinedColorsMap: Record<string, string> = {
      Broadband: '#9796F3',
      DIA: '#5153DD',
      Grid: '#FF5F82',
      'PIP/GSE': '#FF315E',
      Rebill: '#6C9DFF',
      Guardian: '#3D79EF',
      Mobility: '#A7F4FF',
      'Hosted PBX/EPOTS': '#59B8C5',
      DID: '#59C566',
      POTS: '#A7FFB2',
      RCF: '#FBB979',
      SIP: '#F99247',
      'Toll-Free': '#FCDF56',
      LD: '#F9CB34',
      Equipment: '#94A3B8',
    };

    const predefinedKeys = Object.keys(predefinedColorsMap);

    const dataWithPredefinedColors = predefinedKeys
      .filter((key) => key in serviceTypes)
      .map((key) => ({
        x: key,
        y: serviceTypes[key],
        backgroundColor: predefinedColorsMap[key],
      }));

    const extraServiceTypes = Object.entries(serviceTypes).filter(
      ([key]) => !predefinedKeys.includes(key),
    );

    const fallbackColors = extraServiceTypes.map(([key, value], index) => {
      const hue = (index * 137.5) % 360;
      return {
        x: key,
        y: value,
        backgroundColor: `hsl(${hue}, 50%, 50%)`,
      };
    });

    return [...dataWithPredefinedColors, ...fallbackColors];
  };

  const servicesChartData = useMemo(
    () => (data?.service_types ? buildDonutChartData(data.service_types) : []),
    [data?.service_types],
  );

  return (
    <div className="grid w-full gap-6 rounded md:grid-cols-2">
      <div className="grid h-full w-full grid-cols-2 gap-6 rounded">
        <NumericDisplayCard
          title="Total locations"
          value={data?.total_accounts}
          isLoading={isLoading}
        />
        <NumericDisplayCard
          title="Total vendors"
          value={
            data?.vendors ? String(Object.keys(data?.vendors)?.length) : '--'
          }
          isLoading={isLoading}
        />
        <div className="col-span-2 w-full rounded bg-background-base-surface-2 p-6 shadow-elevation3">
          <DonutChart
            data={servicesChartData}
            title="My services"
            isLoading={isLoading}
            showTicketLabel={false}
            scrollLegendOnPieHover={true}
          />
        </div>
      </div>
      <div className="grid h-full w-full grid-cols-1 gap-6 rounded">
        <NumericDisplayCard
          title="Total services"
          value={data?.total_records}
          className="!col-span-2"
          isLoading={isLoading}
        />
        <div className="col-span-2 h-full w-full rounded bg-background-base-surface-2 p-6 shadow-elevation3">
          <HorizontalBarChart
            data={serviceLifeCycleChartData}
            title="Service lifecycle"
            isLoading={isLifecycleLoading}
            filterOptions={inventoryChartFilterOptions}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>
    </div>
  );
};

const NumericDisplayCard = ({
  title,
  value,
  className,
  isLoading,
}: {
  title: string;
  value: string;
  className?: string;
  isLoading?: boolean;
}) => {
  const formattedValue = !isNaN(Number(value))
    ? new Intl.NumberFormat('en-US').format(Number(value))
    : value;
  return (
    <div
      className={clsx(
        'col-span-1 h-full rounded bg-background-base-surface-2 p-6 shadow-elevation3',
        className,
      )}
    >
      <h2 className="mb-4 text-base font-bold text-content-base-default">
        {title}
      </h2>
      {isLoading ? (
        <Skeleton className="h-[66px]" />
      ) : (
        <h2 className="m-0 text-[44px] font-bold text-content-base-default">
          {formattedValue}
        </h2>
      )}
    </div>
  );
};

export default Metrics;
