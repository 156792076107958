import React, { FC } from 'react';
import { TechExpressTicketType } from 'screens/InventoryAccount/inventoryAccount.types';
import Status from './Status';
import BodyItem from './BodyItem';

const TechExpressTicket: FC<
  Pick<TechExpressTicketType, 'status' | 'dispatchDate' | 'dispatchTime'>
> = ({ dispatchDate, dispatchTime, status }) => {
  return (
    <div className="flex items-center justify-between">
      <Status status={status} />
      <div className="flex gap-6">
        <BodyItem label="Dispatch date" value={dispatchDate} />
        <BodyItem label="Dispatch time" value={dispatchTime} />
      </div>
    </div>
  );
};

export default TechExpressTicket;
