import { useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { AlertCircle, CloseCircle, RemoveCircle } from 'react-ionicons';

import { PricingTier, PricingTierResponse } from 'api/accessexpress/schema';

import PackageCard from './PackageCard';
import DetailsSummary from './DetailsSummary';
import Header from './Header';
import LookingForMoreServiceOptions from './LookingForMoreServiceOptions';
import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Divider from 'components/Divider';
import ResultsLoading from '../ResultsLoading';
import HeaderContainer from './HeaderContainer';

interface LocationSectionProps {
  location: PricingTierResponse;
  onSelectedTier: (selectedTier: DeepPartial<PricingTier> | null) => void;
  selectedTier: DeepPartial<PricingTier> | null;
  pricingAvailable: boolean;
  locationPricingTiersLength?: number;
  errors?: {
    [locationId: string]: boolean;
  };
  onRemoveLocation?: () => void;
  onMoreOptionsSelect?: () => void;
  isMoreOptionsSelected?: boolean;
  defaultCollapsed?: boolean;
  isLoading?: boolean;
  shouldAnimate?: boolean;
  mode: 'view' | 'update';
  warningMsg: string;
}

const LocationSection = ({
  location,
  onSelectedTier,
  selectedTier,
  pricingAvailable,
  locationPricingTiersLength,
  errors,
  onRemoveLocation,
  onMoreOptionsSelect,
  isMoreOptionsSelected = false,
  defaultCollapsed = false,
  isLoading = false,
  shouldAnimate = false,
  mode,
  warningMsg,
}: LocationSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const hasError = errors?.[location.id];

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTierSelect = (tier: DeepPartial<PricingTier>) => {
    onSelectedTier(tier);
  };

  const renderPackageCards = () => {
    const { pricing_tiers } = location;
    const numMissingCards = 3 - pricing_tiers.length;

    if (!pricingAvailable) {
      return <ResultsLoading />;
    }

    const packageCards = pricing_tiers.map((data) => {
      return (
        <PackageCard
          key={data.name}
          data={{ ...data, productType: location.product_type }}
          selected={selectedTier?.name === data.name}
          onSelect={handleTierSelect}
        />
      );
    });

    if (numMissingCards >= 1) {
      packageCards.push(
        <div
          className={`col-span-${numMissingCards} flex-${numMissingCards}`}
          key="more-options"
        >
          <LookingForMoreServiceOptions
            onSelect={() => onMoreOptionsSelect?.()}
            isSelected={isMoreOptionsSelected}
          />
        </div>,
      );
    }

    if (numMissingCards === 3 && !isMoreOptionsSelected) {
      return (
        <div className="flex-3 col-span-3" key="three-missing">
          <div className="flex h-full w-full flex-col gap-2 rounded rounded-md bg-background-base-surface-3 p-4">
            <div className="flex items-center gap-2">
              <p className="text-xl font-bold text-content-base-default">
                Pricing options are not available at this time
              </p>
              <CloseCircle color="#FF315E" />
            </div>
          </div>
        </div>
      );
    }

    return packageCards;
  };

  const hasPricingTiers = location.pricing_tiers.length > 0;

  const isNothingSelected =
    !selectedTier &&
    !isMoreOptionsSelected &&
    location.pricing_tiers.length > 1;

  return (
    <div
      className={clsx(
        'gap-4 rounded border-2 border-background-base-surface-1 bg-background-base-surface-1 p-4',
        hasError && 'border-status-error-default',
        isNothingSelected && 'border-status-warning-default',
      )}
      id={`location-${location.id}`}
    >
      {isNothingSelected && (mode === 'view' ? !isCollapsed : true) && (
        <div
          className={clsx(
            'mb-6 flex w-full items-center gap-2 rounded border border-status-warning-default bg-status-warning-default bg-opacity-20 p-4 text-status-warning-default',
          )}
        >
          <RemoveCircle color="currentColor" width="24px" height="24px" />
          <span className="text-base font-bold text-content-base-default">
            {warningMsg}
          </span>
        </div>
      )}
      {hasError && (
        <div
          className={clsx(
            'mb-6 flex w-full items-center gap-2 rounded bg-background-base-surface-1 bg-destructive p-4',
          )}
        >
          <AlertCircle color="#FF315E" width="24px" height="24px" />
          <span className="text-base font-bold text-content-base-default">
            Select a tier from the below options, or remove this location.
          </span>
          <GraniteButton
            variant="destructive"
            size="small"
            className="ml-auto"
            onClick={onRemoveLocation}
          >
            Remove location
          </GraniteButton>
        </div>
      )}
      <HeaderContainer isLoading={isLoading} shouldAnimate={shouldAnimate}>
        <Header
          address={`${location.location.address_line_1}, ${location.location.city}, ${location.location.state} ${location.location.zip}`}
          productType={location.product_type}
          isCollapsed={isCollapsed}
          onToggleCollapse={toggleCollapse}
          hasPricingTiers={hasPricingTiers}
        />
        <div className="my-4 w-full">
          <Divider />
        </div>
      </HeaderContainer>
      {!isCollapsed ? (
        <div className={clsx('mt-6', mode === 'view' && 'pointer-events-none')}>
          <div className="grid grid-cols-3 gap-6">{renderPackageCards()}</div>
        </div>
      ) : (
        hasPricingTiers &&
        selectedTier && (
          <div className={clsx(mode === 'view' && 'pointer-events-none')}>
            <DetailsSummary
              recommendation={`Recommended (${selectedTier?.avg_bandwidth} Mb/s)`}
              term={`${selectedTier?.term ?? '3'} ${
                selectedTier?.term === '1' ? 'year' : 'years'
              }  `}
              ipType={`${
                selectedTier?.ip_type?.toLocaleLowerCase() === 'static'
                  ? `Static IP, ${selectedTier.ip_blocks} IP block`
                  : 'Dynamic'
              }`}
              price={selectedTier?.avg_mrc ?? ''}
              isSimpleFlow={
                locationPricingTiersLength === 1 &&
                location.product_type === 'Broadband'
              }
            />
          </div>
        )
      )}
    </div>
  );
};

export default LocationSection;
