import clsx from 'clsx';
import { FC } from 'react';

export enum ServiceTagType {
  broadband = 'Broadband',
  dia = 'DIA',
  edgeBoot = 'edgeboot',
  wireless_mobility = 'Wireless Mobility',
  hosted_pbx = 'Hosted PBX',
  pots = 'POTS',
}

export interface ServiceTagProps {
  serviceType: keyof typeof ServiceTagType;
}
const ServiceTag: FC<ServiceTagProps> = ({ serviceType }) => {
  return (
    <div
      className={clsx(
        'min-w-[120px] rounded-lg px-2 py-1 text-center text-xs font-bold text-blue-900',
        {
          'bg-green-400':
            ServiceTagType[serviceType] === ServiceTagType.broadband ||
            ServiceTagType[serviceType] === ServiceTagType.dia,
          'bg-blue-400':
            ServiceTagType[serviceType] === ServiceTagType.edgeBoot,
          'bg-purple-300':
            ServiceTagType[serviceType] === ServiceTagType.wireless_mobility,
          'bg-teal-400':
            ServiceTagType[serviceType] === ServiceTagType.hosted_pbx,
          'bg-red-400': ServiceTagType[serviceType] === ServiceTagType.pots,
        },
      )}
    >
      {ServiceTagType[serviceType]}
    </div>
  );
};

export default ServiceTag;
