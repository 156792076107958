import Filters from 'components/Filters';
import { GraniteSelect } from 'components/Select/Select';
import Searchbar from 'components/Table/SearchBar';
import { startOfDay, subMonths } from 'date-fns';
import React, { useState } from 'react';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import Services from './Services';
import { useInventoryAccount } from '../InventoryAccountContext';
import clsx from 'clsx';

const filterByDate = [
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 1))),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 3))),
    label: 'Past 3 months',
  },
];

const InventoryServices = () => {
  const [search, setSearch] = useState('');

  const clearAllFilters = () => {};

  const { mode } = useInventoryAccount();
  return (
    <>
      <div
        className={clsx('relative transition-all duration-700', {
          'mb-12': mode === 'view',
          'mb-3': mode === 'edit',
        })}
      >
        <div
          className={clsx(
            'flex w-full flex-wrap items-start justify-between gap-4 transition-all duration-700',
            {
              'pointer-events-none opacity-0': mode === 'edit',
              'opacity-100 ': mode === 'view',
            },
          )}
        >
          <div className="w-full sm:flex-1">
            <Searchbar
              placeholder="Search by account #, name or address"
              clearAllValues={search === ''}
              onQueryClear={() => setSearch('')}
              onSearch={(query: string) => {
                setSearch(query);
              }}
            />
          </div>
          <Filters
            clearFilters={clearAllFilters}
            className="flex w-full flex-col items-start justify-start gap-4 sm:flex-row"
            clearFilterClassName="w-full"
          >
            <GraniteSelect
              options={[]}
              className={''}
              placeholder="Filter service type"
              controlShouldRenderValue={false}
              isSearchable={false}
              onChange={() => {}}
              value={{}}
            />
            <GraniteSelect
              options={[]}
              className={''}
              placeholder="Filter vendor"
              controlShouldRenderValue={false}
              isSearchable={false}
              onChange={() => {}}
              value={{}}
            />
            <GraniteSelect
              options={[]}
              className={''}
              placeholder="Filter completion date"
              controlShouldRenderValue={false}
              isSearchable={false}
              onChange={() => {}}
              value={{}}
            />
            <GraniteSelect
              options={filterByDate}
              className={''}
              placeholder="Filter by end date"
              controlShouldRenderValue={false}
              isSearchable={false}
              onChange={() => {}}
              value={{}}
            />
          </Filters>
        </div>
        <p
          className={clsx(
            'absolute top-0 text-xl font-bold text-white transition-all duration-700',
            {
              'pointer-events-none opacity-0': mode === 'view',
              'opacity-100 ': mode === 'edit',
            },
          )}
        >
          Which service would you like to open a repair ticket for?
        </p>
      </div>
      <Services />
    </>
  );
};

export default InventoryServices;
