import { TicketNoteResponse } from 'api/techexpress/schemas/TicketNoteSchemas';
import { useMemo } from 'react';
import { TICKET_NOTES_COLOR_PALETTE } from 'shared/constants/ticket-notes-color-palette';

export const useTicketFormattedNotes = (notes: TicketNoteResponse[]) => {
  return useMemo(() => {
    const userColorMap = new Map<number | undefined, string>();
    let colorIndex = 1; // 0 is reserved for G360

    return notes?.map((note) => {
      const { contact_id, member_id, created_by } = note;

      if (created_by === 'G360') {
        return { ...note, color: TICKET_NOTES_COLOR_PALETTE[0] };
      }

      const userKey = contact_id || member_id;
      if (!userColorMap.has(userKey)) {
        userColorMap.set(userKey, TICKET_NOTES_COLOR_PALETTE[colorIndex]);
        colorIndex = (colorIndex + 1) % TICKET_NOTES_COLOR_PALETTE.length;

        if (colorIndex === 0) colorIndex = 1;
      }

      return { ...note, color: userColorMap.get(userKey) };
    });
  }, [notes]);
};
