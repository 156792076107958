import {
  NumericCell,
  StackedCell,
  StackedCellMainContent,
  TableTitle,
} from 'components/Table/Table.styles';
import { ReactNode, useCallback, useState } from 'react';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import Searchbar from 'components/Table/SearchBar';
import {
  InventoryAccountFilterSearchParams,
  InventoryAccountItem,
} from 'api/inventory/schema';
import { useCustomizeTHead } from 'hooks/useCustomizeTHead';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useSortedTable } from 'hooks/useSortedTable';
import { setCookie } from 'shared/util/util';
import {
  getInventoryAccountExport,
  getInventoryAccountIndex,
} from 'api/inventory/api';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import Customize from 'components/Table/Customize/Customize';
import { Badge, TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import clsx from 'clsx';
import Filters from 'components/Filters/Filters';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { Chip } from 'components/Chip/Chip';
import { SingleValue } from 'react-select';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { useDownloadAsExcel } from 'hooks/useDownloadAsExcel';
import SwitchTable from './SwitchTable';
import useInventoryStaticData from 'hooks/useInventoryStaticData';
import { useFilterForTable } from 'hooks/useFilterForTable';
import { EmptyInventory } from './Inventory';
import { useFeatureFlags } from 'feature-flags';
import { useNavigate } from 'react-router-dom';

const AccountsTable = ({
  setTableView,
  selectedTableView,
}: {
  setTableView: React.Dispatch<React.SetStateAction<OptionType>>;
  selectedTableView: OptionType;
}) => {
  const [search, setSearch] = useState('');
  const { data: staticData } = useInventoryStaticData();

  const tableHeaders: ColumnDef<InventoryAccountItem>[] = [
    {
      id: 'parent_name',
      header: 'Parent name',
      accessorKey: 'parent_name',
      enableSorting: true,
    },
    {
      id: 'parent_number',
      header: 'Parent #',
      accessorKey: 'parent_number',
      enableSorting: true,
    },
    {
      id: 'account_number',
      header: 'Account #',
      accessorKey: 'account_number',
      enableSorting: true,
      cell: (row) => (
        <TicketIdBadge>{row.getValue() as ReactNode}</TicketIdBadge>
      ),
    },
    {
      id: 'account_name',
      header: 'Account name',
      accessorKey: 'account_name',
      enableSorting: true,
    },
    {
      id: 'address',
      header: 'Street address',
      accessorKey: 'address',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.address}
            </StackedCellMainContent>
          </StackedCell>
        );
      },
      enableSorting: true,
    },
    {
      id: 'city',
      header: 'City',
      accessorKey: 'city',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.city}
            </StackedCellMainContent>
          </StackedCell>
        );
      },
      enableSorting: true,
    },
    {
      id: 'state',
      header: 'State',
      accessorKey: 'state',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.state}
            </StackedCellMainContent>
          </StackedCell>
        );
      },
      enableSorting: true,
    },
    {
      id: 'zip',
      header: 'Zip',
      accessorKey: 'zip',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.zip}
            </StackedCellMainContent>
          </StackedCell>
        );
      },
      enableSorting: true,
    },
    {
      id: 'services_count',
      header: 'Total services',
      accessorKey: 'services_count',
      cell: (row) => (
        <NumericCell>{row.row.original.services_count}</NumericCell>
      ),
      enableSorting: true,
      meta: {
        align: 'right',
      },
    },
    {
      id: 'service_categories',
      header: 'Service categories',
      accessorKey: 'service_categories',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent className="flex gap-1">
              {cellCtx.row.original.service_categories.map((item) => (
                <TicketIdBadge key={item}>{item}</TicketIdBadge>
              ))}
            </StackedCellMainContent>
          </StackedCell>
        );
      },
      enableSorting: false,
    },
    {
      id: 'billing_type',
      header: 'Billing type',
      accessorKey: 'billing_type',
      enableSorting: true,
      cell: ({ getValue }) => {
        const value = getValue<string>();

        let displayValue = value;
        let bgColor = '';
        let textColor = '';

        switch (value) {
          case 'Bill to Parent':
            displayValue = 'Bill to Parent';
            bgColor = '#1A4BAB';
            break;
          case 'Bill to this account':
            displayValue = 'Bill to this account';
            bgColor = '#82FF91';
            textColor = '#191925';
            break;
          case 'No Billing':
            displayValue = 'No billing';
            bgColor = '#94A3B8';
            textColor = '#191925';
            break;
          default:
            displayValue = value;
            bgColor = '#6C757D';
        }

        return (
          <Badge style={{ backgroundColor: bgColor, color: textColor }}>
            {displayValue}
          </Badge>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState<InventoryAccountItem[]>([]);
  const [pageSize, setPageSize] = useState<number>();

  const [parentNumberFilter, setParentNumberFilter] =
    useState<SingleValue<OptionType>>(null);
  const [stateFilter, setStateFilter] = useState<SingleValue<OptionType>>(null);
  const [billingTypeFilter, setBillingTypeFilter] =
    useState<SingleValue<OptionType>>(null);

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'address', desc: true }],
  });

  const {
    queryParamFilter: serviceCategoriesFilter,
    clearFilter: clearServiceCategoriesFilter,
    ...serviceCategoriesFilterProps
  } = useFilterForTable({ queryParamKey: 'service_categories' });

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeInventoryAccount', page.toString(), 365);
  };

  const buildInventoryQueryParams = useCallback(
    (args?: InventoryAccountFilterSearchParams) => ({
      ...(search ? { search } : {}),
      ...sortingQueryParams,
      ...serviceCategoriesFilter,
      ...(parentNumberFilter
        ? { parent_number: parentNumberFilter?.value }
        : {}),
      ...(stateFilter ? { state: stateFilter?.value } : {}),
      ...(billingTypeFilter ? { billing_type: billingTypeFilter?.value } : {}),
      ...args,
    }),
    [
      billingTypeFilter,
      parentNumberFilter,
      search,
      serviceCategoriesFilter,
      sortingQueryParams,
      stateFilter,
    ],
  );

  const getInventoryIndexFn = useCallback(
    (args?: InventoryAccountFilterSearchParams) => {
      return getInventoryAccountIndex(buildInventoryQueryParams(args));
    },
    [buildInventoryQueryParams],
  );

  const getInventoryAccountExportFn = useCallback(
    (args?: InventoryAccountFilterSearchParams) => {
      return getInventoryAccountExport(buildInventoryQueryParams(args));
    },
    [buildInventoryQueryParams],
  );

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getInventoryIndexFn,
    {
      search,
      parentNumberFilter,
      serviceCategoriesFilter,
      stateFilter,
      billingTypeFilter,
      sortingQueryParams,
    },
    [
      'inventory-account-index-table',
      search,
      parentNumberFilter,
      serviceCategoriesFilter,
      billingTypeFilter,
      stateFilter,
      sortingQueryParams,
    ],
    {
      onSuccess: ({ data }: { data: InventoryAccountItem[] }) => {
        setTableData(data);
      },
      refetchOnMount: true,
    },
  );

  const clearAllFilters = useCallback(() => {
    setParentNumberFilter(null);
    setStateFilter(null);
    setBillingTypeFilter(null);
    clearServiceCategoriesFilter();
    setSearch('');
  }, [clearServiceCategoriesFilter]);

  const preferenceFilterClearFunctions: Record<
    string,
    (() => void) | undefined
  > = {
    parent_number: parentNumberFilter
      ? () => setParentNumberFilter(null)
      : undefined,
    state: stateFilter ? () => setStateFilter(null) : undefined,
    billing_type: billingTypeFilter
      ? () => setBillingTypeFilter(null)
      : undefined,
    service_categories: serviceCategoriesFilterProps.value.length
      ? clearServiceCategoriesFilter
      : undefined,
  };

  const {
    columns,
    customizeProps,
    hasFilterByPreference,
    isPreferenceLoading,
    exportWithPreferences,
    customizeFirstColumnMobileClass,
  } = useCustomizeTHead({
    customizeFirstColumnMobileKey: 'account_number',
    columns: tableHeaders,
    tableName: 'inventory-account-view',
    onPreferenceRemoved: (field) => {
      preferenceFilterClearFunctions[field]?.();
    },
  });

  const createExcelFile = useDownloadAsExcel(async () => {
    const accounts = await getInventoryAccountExportFn();
    const transformedData = accounts.data.map((acc) => ({
      ...acc,
      service_categories: acc.service_categories.map((item) => item).join(','),
    }));
    return exportWithPreferences(transformedData);
  }, 'Inventory_accounts');

  const { flags } = useFeatureFlags();
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-start justify-start">
      <TableTitle>My services</TableTitle>
      <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
        <div className="w-full sm:flex-1">
          <Searchbar
            placeholder="Search by account #, name or address"
            clearAllValues={search === ''}
            onQueryClear={() => setSearch('')}
            onSearch={(query: string) => {
              setSearch(query);
            }}
          />
        </div>
        <SwitchTable
          setTableView={setTableView}
          selectedTableView={selectedTableView}
        />
        <Customize {...customizeProps} />
        <Filters
          clearFilters={clearAllFilters}
          clearFilterClassName="col-span-full md:col-span-1 w-full"
          className="grid w-full grid-cols-4 gap-4 md:flex"
        >
          <GraniteSelect
            options={staticData?.account_parent_options?.map(
              (item: string) => ({
                label: item[0],
                subvalue: item[1],
                value: item[0],
              }),
            )}
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('parent_number') && '!hidden',
            )}
            placeholder="Parent #"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={setParentNumberFilter}
            value={parentNumberFilter}
          />
          <GraniteSelect
            options={staticData?.account_state_options?.map((item: string) => ({
              label: item,
              value: item,
            }))}
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('state') && '!hidden',
            )}
            placeholder="State"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={setStateFilter}
            value={stateFilter}
          />
          <GraniteSelect
            options={staticData?.account_service_categories_options?.map(
              (item: string) => ({
                label: item,
                value: item,
              }),
            )}
            isMulti
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('service_categories') && '!hidden',
            )}
            placeholder="Service categories"
            controlShouldRenderValue={false}
            isSearchable={false}
            {...serviceCategoriesFilterProps}
          />
          <GraniteSelect
            options={staticData?.account_billing_type_options?.map(
              (item: string) => ({
                label:
                  item === 'Child - Par Pays'
                    ? 'Bill to Parent'
                    : item === 'Child - Child Pays'
                      ? 'Bill to this account'
                      : item === 'No Billing'
                        ? 'No billing'
                        : item,
                value: item,
              }),
            )}
            className={clsx(
              'col-span-2 md:col-span-2 xl:col-span-1',
              !hasFilterByPreference('billing_type') && '!hidden',
            )}
            placeholder="Billing type"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={setBillingTypeFilter}
            value={billingTypeFilter}
          />
        </Filters>
      </div>
      <div className="mb-12 flex flex-wrap gap-4">
        {parentNumberFilter?.value && (
          <Chip
            label={parentNumberFilter.label}
            onDelete={() => {
              setParentNumberFilter(null);
            }}
          />
        )}
        {stateFilter?.value && (
          <Chip
            label={stateFilter.label}
            onDelete={() => {
              setStateFilter(null);
            }}
          />
        )}
        {serviceCategoriesFilterProps.value.map((sf) => (
          <Chip
            key={sf.value}
            label={sf.label}
            onDelete={() =>
              serviceCategoriesFilterProps.onChange(
                serviceCategoriesFilterProps.value.filter(
                  (option) => option.value !== sf.value,
                ),
              )
            }
          />
        ))}
        {billingTypeFilter?.value && (
          <Chip
            label={billingTypeFilter.label}
            onDelete={() => {
              setBillingTypeFilter(null);
            }}
          />
        )}
      </div>
      <ServerPaginatedTable
        data={tableData ?? []}
        columns={columns}
        title="My services"
        sortingState={sortingState}
        onSortingChange={onSortingChange}
        paginationChanged={paginationChanged}
        downloadTableFn={createExcelFile}
        paginationSizeStored={pageSize}
        emptyDataElement={
          search ||
          parentNumberFilter?.value ||
          stateFilter?.value ||
          billingTypeFilter?.value ||
          serviceCategoriesFilterProps.value.length > 0 ? (
            <EmptySearchResults />
          ) : (
            <EmptyInventory />
          )
        }
        {...(flags.INVENTORY_ACCOUNT && {
          handleRowClick: (row: Row<InventoryAccountItem>) => {
            navigate(`account/${row.original.account_number}/services`);
          },
        })}
        {...paginatedTableProps}
        isFetchingData={
          paginatedTableProps.isFetchingData || isPreferenceLoading
        }
        tableContainerClassName={customizeFirstColumnMobileClass}
      />
    </div>
  );
};

export default AccountsTable;
