import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InviteUserForm, InviteUserFormSchema } from './form-schema';
import PageTitle from 'components/PageTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { GraniteInput } from '../../components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteSelect } from '../../components/Select/Select';
import PhoneNumberInput from '../../components/V2/Input/PhoneNumber';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useMutation } from 'react-query';
import { inviteUser } from '../../api/users/api';
import showToast from '../../components/Toast/Toast';
import Divider from 'components/Divider';
import { UserRolePermissionFeatureSwitches } from 'components/UserPermissionFeatureSwitches/UserRolePermissionFeatureSwitches';
import {
  InviteUserRequest,
  InviteUserResponse,
} from '../../api/users/schemas/Invitations';
import { BEUserRole } from 'api/users/schemas/UserRole';
import UserAccounts, {
  accountOptions,
} from 'screens/UserAccounts/UserAccounts';

export const InviteUser: React.FC = () => {
  const { companyId, companyName, name: inviterName } = useAuthUser();
  const companies = [{ value: companyId, label: companyName }];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InviteUserForm>({
    defaultValues: {
      account: accountOptions[0],
      sub_groups: [],
    },
    resolver: zodResolver(InviteUserFormSchema),
  });
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
  ];

  const inviteUserMutation = useMutation({
    mutationFn: (request: InviteUserRequest) => inviteUser(request),
    onSuccess: (response: InviteUserResponse[]) => {
      if (response.length !== 1) {
        showToast.error({ message: 'An unknown error occurred' });
      }
      const status = response[0];
      if (status.result === 'Success') {
        showToast.confirmation({ message: 'User invited successfully.' });
        navigate('/users');
      } else {
        showToast.error({ message: status.error_message });
      }
    },
  });

  const onSubmit = (formData: InviteUserForm) => {
    formData.companyId = companies[0].value;
    inviteUserMutation.mutate([
      {
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone,
        email: formData.email,
        role: formData.roles[0].value as BEUserRole,
        inviter_name: inviterName,
        sub_groups: formData.sub_groups,
      },
    ]);
  };

  const onError = (err: unknown) => {
    console.log('FormError:', err);
    return;
  };

  return (
    <div className="w-full p-8">
      <div className="invite-user mx-auto flex w-full flex-col items-start justify-start">
        <div className="mb-6 flex items-end justify-start gap-6">
          <PageTitle title="Invite user" breadcrumbs={breadcrumbs} />
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6">
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="w-full"
            id="invite-member-form"
          >
            <div className="grid h-full w-full grid-cols-12 overflow-hidden rounded">
              <div className="col-span-4 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-3 px-8 py-6">
                <h2 className="text-2xl font-bold text-content-base-default">
                  User details
                </h2>
                <div className="flex w-full flex-col items-start justify-start gap-6">
                  <div className="w-full">
                    <GraniteInput
                      label="First Name"
                      placeholder="First Name"
                      {...register('first_name')}
                      error={errors.first_name?.message}
                    />
                  </div>
                  <div className="w-full">
                    <GraniteInput
                      label="Last Name"
                      placeholder="Last Name"
                      {...register('last_name')}
                      error={errors.last_name?.message}
                    />
                  </div>
                  <div className="w-full">
                    <GraniteInput
                      label="Email"
                      placeholder="Email"
                      {...register('email')}
                      error={errors.email?.message}
                    />
                  </div>
                  <div className="w-full">
                    <PhoneNumberInput
                      name="phone"
                      control={control}
                      label="Phone"
                      error={errors.phone?.message}
                    />
                  </div>
                  <div className="w-full">
                    <GraniteSelect
                      options={companies}
                      value={companies[0]}
                      label="Company"
                      isDisabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-8 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-2 px-8 pb-8 pt-6">
                <UserRolePermissionFeatureSwitches control={control} />
                <UserAccounts control={control} />
                <div className="w-full py-8">
                  <Divider />
                </div>
                <div className="flex w-full items-center justify-start gap-4  ">
                  <GraniteButton
                    variant="secondary"
                    size="large"
                    onClick={() => navigate('/users')}
                  >
                    Cancel
                  </GraniteButton>

                  <GraniteButton
                    variant="primary"
                    size="large"
                    type="submit"
                    form="invite-member-form"
                    disabled={inviteUserMutation.isLoading}
                  >
                    Invite user
                  </GraniteButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
