import logo from 'assets/images/logo_v2.svg';
import clsx from 'clsx';

interface GraniteLogoProps {
  className?: string;
}
const GraniteLogo = ({ className = '' }: GraniteLogoProps) => {
  return (
    <img
      className={clsx('pointer-events-none', className)}
      src={logo}
      alt="logo"
    />
  );
};

export default GraniteLogo;
