import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from 'victory';
import theme from '../../shared/theme/custom-theme';
import ChartTitle from './ChartTitle';
import Skeleton from 'components/Skeleton/Skeleton';
import CustomSelectControl from 'screens/Inventory/CustomSelectControl';
import { OptionType } from 'components/Select/Select';
import { SingleValue } from 'react-select';

export interface BarChartData {
  category: string;
  value: number;
  backgroundColor: string;
  color?: string;
}

interface HorizontalBarChartProps {
  data: BarChartData[];
  title?: string;
  isLoading?: boolean;
  filterOptions?: OptionType[];
  onFilterChange?: (selectedFilter: SingleValue<OptionType>) => void;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  data,
  title,
  isLoading,
  filterOptions,
  onFilterChange,
}) => {
  return (
    <>
      {title && <ChartTitle title={title} />}
      {filterOptions && (
        <div className="-mt-6">
          <CustomSelectControl
            options={filterOptions}
            onChange={onFilterChange}
          />
        </div>
      )}
      {isLoading ? (
        <Skeleton className="h-[163px] w-full" />
      ) : (
        <>
          <div className="hidden h-full pb-4 sm:block">
            <VictoryChart
              horizontal
              height={160}
              width={400}
              padding={{ top: 10, bottom: 46, left: 110, right: 60 }}
              minDomain={{ y: 0 }}
            >
              <VictoryAxis
                style={{
                  axis: { display: 'none' },
                  tickLabels: {
                    textAnchor: 'start',
                    fill: theme.colors.text.primary,
                    fontSize: '20px',
                    fontWeight: theme.fontWeights.bold,
                    overflow: 'visible',
                    fontFamily: theme.fonts.body,
                  },
                }}
                offsetX={11}
              />
              <VictoryBar
                data={data}
                y="value"
                x="category"
                labels={({ datum }) => datum.value}
                labelComponent={
                  <VictoryLabel
                    dx={({ datum }) => (datum.value != 0 ? 8 : 0)}
                    textAnchor="start"
                    verticalAnchor="middle"
                    style={{
                      overflow: 'visible',
                      fontFamily: theme.fonts.body,
                      fontSize: '20px',
                      fontWeight: theme.fontWeights.bold,
                      fill: ({ datum }: { datum?: BarChartData }) =>
                        datum && datum.color
                          ? datum.color
                          : theme.colors.text.white, // Use the bar color as label color
                    }}
                  />
                }
                style={{
                  data: {
                    fill: ({ datum }) => datum.backgroundColor,
                  },
                }}
                cornerRadius={{ top: 4, bottom: 4 }}
                barWidth={48}
              />
            </VictoryChart>
          </div>
          <div className="sm:hidden">
            {data.map((datum) => (
              <div
                key={datum.category}
                className="flex w-full items-center justify-between gap-2 border-b border-stroke-base-subdued py-1.5 last:border-none sm:w-40 sm:border-none sm:py-1 sm:hover:text-content-base-default"
              >
                <div className="flex w-full items-center justify-start gap-2">
                  <span>{datum.category}</span>
                  <span className="ml-auto font-bold text-content-base-default sm:hidden">
                    {datum.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default HorizontalBarChart;
