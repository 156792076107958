import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { LogoBig } from './icons/LogoBig';

export const NavbarLogo = ({ isLarge }: { isLarge: boolean }) => {
  return (
    <NavLink
      className="relative h-[57px] flex-none cursor-pointer flex-col gap-8 text-content-base-default"
      to="/"
    >
      <span
        className={clsx(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform',
          'transition-all duration-500 ease-in-out',
          isLarge ? 'opacity-100' : 'opacity-0',
          isLarge ? '' : '-translate-x-[105px] -translate-y-[48px]',
        )}
      >
        <LogoBig small={false} />
      </span>
      <span
        className={clsx(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform',
          'transition-all duration-500 ease-in-out',
          isLarge ? '' : '-translate-x-[105px] -translate-y-[48px]',
        )}
      >
        <LogoBig small={true} />
      </span>
    </NavLink>
  );
};
