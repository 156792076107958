import React, { FC, useState } from 'react';
import Type from './Type';
import { ActivityProps } from 'screens/InventoryAccount/inventoryAccount.types';
import { LockClosed } from 'react-ionicons';
import Insert from './Insert';
import Header from './Header';
import Footer from './Footer';
import Tag from 'components/Tag';
import Body from './Body';
import { motion } from 'framer-motion';
import clsx from 'clsx';

const Activity: FC<ActivityProps> = ({
  type,
  adminOnly = false,
  date,
  id,
  user,
  tags,
  status,
  linkTo,
  ...props
}) => {
  const [expand, setExpand] = useState(true);

  return (
    <div className="flex w-full gap-6 ">
      <div className="flex flex-col items-center gap-2 px-4 ">
        <Type type={type} />
        <div className="h-full w-[1px] bg-neutral-700/50" />
      </div>
      <div className="flex w-full flex-col gap-2">
        <Insert date={date} id={id} type={type} user={user} />
        {adminOnly && (
          <div className="flex items-center gap-1 text-xs font-semibold">
            <LockClosed color="currentColor" width="20px" height="20px" />{' '}
            Visible to Admin only
          </div>
        )}
        <div className="rounded bg-blue-800 shadow-elevation1">
          <Header
            type={type}
            id={id}
            expand={expand}
            setExpand={setExpand}
            linkTo={linkTo}
          />
          <motion.div
            initial={{ height: 'auto' }}
            animate={{ height: expand ? 'auto' : 0 }}
            exit={{ height: 0 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 15,
              mass: 1,
            }}
            className="relative overflow-hidden"
          >
            <div
              className={clsx(
                'pointer-events-none absolute inset-0 z-10 bg-black/90 transition-all duration-300',
                {
                  'opacity-100': !expand,
                  'opacity-0': expand,
                },
              )}
            />
            <div className="border-y border-neutral-700/50 p-6">
              {tags?.length ? (
                <div className="mb-6 flex flex-wrap gap-2">
                  {tags?.map((tag) => (
                    <Tag
                      key={tag}
                      content={{ type: 'label', label: tag }}
                      status="informative"
                      style="default"
                    />
                  ))}
                </div>
              ) : null}
              <Body type={type} status={status} {...props} />
            </div>
            <Footer type={type} linkTo={linkTo} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
