import Checkbox from 'components/Checkbox';
import { FC } from 'react';
import { SubGroupType } from './accounts.types';
import clsx from 'clsx';
import DisabledToolTip from './DisabledToolTip';

export interface SubGroupProps extends SubGroupType {
  onClick?: () => void;
  checked?: boolean;
  disabled?: boolean;
  showDisabledTooltip?: boolean;
}

const SubGroup: FC<SubGroupProps> = ({
  id,
  name,
  onClick = undefined,
  checked,
  disabled,
  showDisabledTooltip = false,
}) => {
  return (
    <DisabledToolTip
      className={clsx(
        'relative flex w-full items-center gap-6 rounded px-4 py-2.5',
        {
          'cursor-pointer': onClick && !disabled,
          'bg-blue-700 text-white': !disabled,
          'cursor-not-allowed bg-neutral-900 text-neutral-500 ring-1 ring-neutral-700':
            disabled,
        },
      )}
      show={!!disabled && showDisabledTooltip}
      {...(!disabled ? { onClick } : {})}
    >
      {onClick && <Checkbox checked={checked} />}
      <p>{id}</p>
      <p>{name}</p>
    </DisabledToolTip>
  );
};

export default SubGroup;
