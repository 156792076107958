import clsx from 'clsx';
import React, { FC } from 'react';
import {
  BagAdd,
  BagCheck,
  BagRemove,
  Location,
  Receipt,
  Ticket,
} from 'react-ionicons';
import { ActivityType } from 'screens/InventoryAccount/inventoryAccount.types';

interface TypeProps {
  type: keyof typeof ActivityType;
}

const types = {
  tech_express_ticket: {
    parentClassName: 'bg-teal-400',
    className: 'text-teal-400',
    icon: <Ticket color="currentColor" width="24px" height="24px" />,
  },
  ordered_servies: {
    parentClassName: 'bg-teal-400',
    className: 'text-teal-400',
    icon: <BagCheck color="currentColor" width="24px" height="24px" />,
  },
  invoice_added: {
    parentClassName: 'bg-teal-400',
    className: 'text-teal-400',
    icon: <Receipt color="currentColor" width="24px" height="24px" />,
  },
  new_quote_request: {
    parentClassName: 'bg-purple-300',
    className: 'text-purple-300',
    icon: <Location color="currentColor" width="24px" height="24px" />,
  },
  added_service: {
    parentClassName: 'bg-green-300',
    className: 'text-green-300',
    icon: <BagAdd color="currentColor" width="24px" height="24px" />,
  },
  end_dated_service: {
    parentClassName: 'bg-red-400',
    className: 'text-red-400',
    icon: <BagRemove color="currentColor" width="24px" height="24px" />,
  },
};

const Type: FC<TypeProps> = ({ type }) => {
  return (
    <div
      className={clsx(
        'flex h-12 w-12 flex-none items-center justify-center rounded-full bg-opacity-[0.24]',
        types[type].parentClassName,
      )}
    >
      <span className={clsx(types[type].className)}>{types[type].icon}</span>
    </div>
  );
};

export default Type;
