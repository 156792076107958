import React, { Fragment, useMemo } from 'react';
import { PageTitle } from '../BaseComponents/PageTitle';
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableCell,
} from '../../../components/Table/Table.styles';
import clsx from 'clsx';
import Pagination from 'components/Table/Pagination';
import Searchbar from 'components/Table/SearchBar';
import styled from 'styled-components';
import { ChevronUp } from 'react-ionicons';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import Divider from 'components/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitleGranite } from 'components';
import Support from '../BaseComponents/MetaInfo/Support';
import { useQuery } from 'react-query';
import { getFinalizedDetails } from '../SelectServiceBundle/utils';
import { QuoteDetailsType, getIncludedProducts } from '../utils';
import { ProductTooltip } from '../BaseComponents/ProductTooltip';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import Main from '../BaseComponents/MetaInfo/Main';
import { format } from 'date-fns';

export const ProductsToLocationsTable = styled.div`
  display: grid;
  grid-template-columns: 40px 158px minmax(100px, 250px);
  grid-auto-columns: auto;
  column-gap: 48px;
  grid-template-rows: auto;
  grid-auto-rows: 56px 1px;
`;

export const TableRow = styled.div`
  display: grid !important;
  background-color: var(--background_base_surface-3_subdued) !important;
  grid-column: 1 / -1;
  grid-template-columns: auto 1fr auto 107px 107px;
  column-gap: 48px;
  border-radius: 4px;
`;

const getRowIdx = (index: number) => (index + 1) * 2;

export const QuoteDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useQuery(
    ['access-express-quote-finalizedDetails', id],
    () => getFinalizedDetails(id ?? ''),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const uniqueProductTypes = React.useMemo(() => {
    return Array.from(
      new Set(
        data?.locations.flatMap((item) =>
          item.formal_products_offerings.map((product) => product.product_type),
        ),
      ),
    );
  }, [data]);

  const transformedData = React.useMemo(() => {
    return data?.locations.map((item) => ({
      productCount: item.formal_products_offerings.length,
      streetAddress: item.address1,
      fullAddress: `${item.city}, ${item.state} ${item.zip_code}`,
      location: item,
      products: item.formal_products_offerings,
    }));
  }, [data]);

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'index', desc: false },
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const columns: ColumnDef<QuoteDetailsType>[] = useMemo(
    () => [
      {
        id: 'index',
        header: '#',
        accessorKey: 'index',
        enableSorting: true,
        sortingFn: (a, b) => a.index - b.index,
        cell: (cellCtx) => (
          <span className="text-content-base-subdued">
            {cellCtx.row.index + 1}
          </span>
        ),
      },
      {
        id: 'address',
        header: 'Address',
        accessorFn: (row: QuoteDetailsType) =>
          [row.streetAddress, row.fullAddress].join(', '),
        enableHiding: false,
        meta: {
          isAddress: true,
        },
        enableSorting: false,
        cell: (cellCtx) => {
          return (
            <StackedCell className="w-full">
              <StackedCellMainContent>
                {cellCtx.row.original.streetAddress}
              </StackedCellMainContent>
              <StackedCellSubtitle>
                {cellCtx.row.original.fullAddress}
              </StackedCellSubtitle>
            </StackedCell>
          );
        },
      },
      ...uniqueProductTypes.map((type) => ({
        id: type,
        meta: {
          isProduct: true,
        },
        header: type.charAt(0).toUpperCase() + type.slice(1),
        accessorFn: (row: QuoteDetailsType) => {
          const products = row.products.filter(
            (product) => product.product_type === type,
          );
          if (products.length) {
            return (
              <div className="flex items-start justify-start gap-2">
                {products.map((product) => {
                  return (
                    <ProductTooltip
                      key={product.id}
                      product={product}
                      inReviewProducts={true}
                    />
                  );
                })}
              </div>
            );
          } else {
            return (
              <span className="text-sm font-medium text-content-base-subdued">
                N/A
              </span>
            );
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (info: any) => <span>{info.getValue()}</span>,
      })),
    ],
    [uniqueProductTypes],
  );

  const table = useReactTable({
    data: transformedData ?? [],
    columns: columns ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    enableMultiSort: false,
    sortDescFirst: false,
  });

  const addressColumn = table.getColumn('address');

  const addressFilter: ColumnFilter | undefined = columnFilters.find(
    (filter) => filter.id === 'address',
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express'),
    },
    {
      label: 'Open quote request',
      onClick: () => navigate(`/access-express/index`),
    },
    {
      label: `#${data?.qe_quote_request_id}`,
      onClick: () => navigate(`/access-express/order-submitted/${id}`),
    },
  ];

  const mainProps = {
    includedProducts: getIncludedProducts(data),
    locations: data?.locations.length.toString() ?? '0',
    dateSubmitted: data?.qe_quote_created_at
      ? format(new Date(data?.qe_quote_created_at), 'MM/dd/yyyy')
      : '',
    submittedBy: data?.requester ?? '',
  };

  return (
    <ContentLayout>
      <PageTitleGranite
        breadcrumbs={breadcrumbs}
        title={
          <div className="flex items-center justify-center gap-6">
            <h1 className="text-4xl font-bold text-content-base-default">
              {data?.quote_name}
            </h1>
            <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
              <span className="h-auto text-center text-base font-bold text-content-base-default">
                QR #{data?.qe_quote_request_id}
              </span>
            </div>
          </div>
        }
      />
      <div className="mt-6 grid grid-cols-2 items-end gap-1">
        <WizardStateBlock
          label="Add locations & requirements"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Choose services"
          isActive={true}
          isCompleted={true}
        />
      </div>
      <div className="mt-12">
        <Main
          {...mainProps}
          title="Order submitted"
          statusColor="bg-[#82F0FF]"
        />
        <Support description="Granite will review this finalized quote and will be in touch with you soon to provide the details of your order." />
      </div>
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-8 !rounded bg-background-base-surface-2 p-6">
          <PageTitle title="You may review the details of this requested quote but can no longer make adjustments." />
          <div className="grid grid-cols-1 gap-12">
            <div className="flex w-full flex-wrap items-start justify-between gap-4">
              <div className="w-full sm:flex-1">
                <Searchbar
                  placeholder="Search by address"
                  clearAllValues={!addressFilter}
                  onQueryClear={() => addressColumn?.setFilterValue(undefined)}
                  onSearch={(query: string) =>
                    addressColumn?.setFilterValue(query)
                  }
                />
              </div>
            </div>
            <div>
              <ProductsToLocationsTable>
                {table.getHeaderGroups().map((headerGroup, index) => (
                  <Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <div
                        key={header.id}
                        style={{ gridColumnStart: index }}
                        className={clsx(
                          'group row-[1] mb-6 flex items-center text-center font-bold text-content-base-default',
                          header.column.getCanSort() &&
                            'cursor-pointer gap-2 fill-input-content-focus',
                          !header.column.columnDef.meta?.isProduct &&
                            'justify-between !gap-1',
                        )}
                        role="columnheader"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        {header.column.getCanSort() ? (
                          <ChevronUp
                            cssClasses={clsx(
                              'group-hover:text-content-base-default group-active:text-content-accent-default focus:text-content-accent-default',
                              header.column.getIsSorted() === 'desc'
                                ? 'rotate-180'
                                : '',
                              header.column.getIsSorted()
                                ? '!text-content-accent-default'
                                : '',
                            )}
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-subdued))"
                          />
                        ) : null}
                      </div>
                    ))}
                  </Fragment>
                ))}
                {table.getRowModel().rows.map((row, index, rows) => {
                  return (
                    <Fragment key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          style={{
                            gridRow: getRowIdx(index),
                          }}
                          $minWidth={0}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                      {index + 1 < rows.length && (
                        <Divider
                          style={{
                            gridRow: getRowIdx(index) + 1,
                            gridColumnStart: 1,
                            gridColumnEnd: `span ${
                              row.getVisibleCells().length + 1
                            }`,
                          }}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </ProductsToLocationsTable>
              <div className="mt-10 flex">
                <Pagination
                  variant="short"
                  pageCount={table.getPageCount()}
                  totalRows={data?.locations.length as number}
                  currentPage={table.getState().pagination.pageIndex + 1 || 1}
                  onPageChange={(page: number) => table.setPageIndex(page - 1)}
                  currentRowsShown={table.getRowModel().rows.length}
                  pageSizeChanged={(page: number) =>
                    table.setPageSize(Number(page))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
