import React, { FC } from 'react';
import {
  ActivityProps,
  AddedServiceType,
  EndDatedServiceType,
  InvoiceAddedType,
  NewQuoteRequestType,
  OrderedServicesType,
  TechExpressTicketType,
} from 'screens/InventoryAccount/inventoryAccount.types';
import TechExpressTicket from './TechExpressTicket';
import InvoiceAdded from './InvoiceAdded';
import AddedService from './AddedService';
import NewQuoteRequest from './NewQuoteRequest';
import OrderedServices from './OrderedServices';
import EndDatedService from './EndedDatedService';
import Summary from './Summary';

const Body: FC<
  Omit<ActivityProps, 'adminOnly' | 'user' | 'date' | 'tags' | 'id'>
> = ({ status, type, ...props }) => {
  let body = null;

  if (type === 'tech_express_ticket') {
    const { dispatchDate, dispatchTime } = props as TechExpressTicketType;
    body = (
      <TechExpressTicket
        dispatchDate={dispatchDate}
        dispatchTime={dispatchTime}
        status={status}
      />
    );
  }
  if (type === 'invoice_added') {
    const { payPeriod, issued, due } = props as InvoiceAddedType;
    body = (
      <InvoiceAdded
        payPeriod={payPeriod}
        issued={issued}
        due={due}
        status={status}
      />
    );
  }
  if (type === 'added_service') {
    const { completionDate, endDate } = props as AddedServiceType;
    body = (
      <AddedService
        completionDate={completionDate}
        endDate={endDate}
        status={status}
      />
    );
  }

  if (type === 'new_quote_request') {
    const { includedServices, locations } = props as NewQuoteRequestType;
    body = (
      <NewQuoteRequest
        includedServices={includedServices}
        locations={locations}
        status={status}
      />
    );
  }

  if (type === 'end_dated_service') {
    const { completionDate, endDate } = props as EndDatedServiceType;
    body = (
      <EndDatedService
        completionDate={completionDate}
        endDate={endDate}
        status={status}
      />
    );
  }

  if (type === 'ordered_servies') {
    const { includedServices, locations } = props as OrderedServicesType;
    body = (
      <OrderedServices
        includedServices={includedServices}
        locations={locations}
        status={status}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded bg-blue-700 px-4 py-2">{body}</div>
      <Summary type={type} />
    </div>
  );
};

export default Body;
