import React, { ReactNode } from 'react';

import ResizableRightSideBar from './ResizableSidebar';

type ResizableLayoutProps = {
  children: ReactNode;
};

type ContentProps = {
  children: ReactNode;
};

type SidebarProps = {
  children: ReactNode;
};

const ResizableLayout: React.FC<ResizableLayoutProps> & {
  Content: React.FC<ContentProps>;
  Sidebar: React.FC<SidebarProps>;
} = ({ children }: ResizableLayoutProps) => {
  return (
    <div className="mx-auto my-0 w-full p-4 pb-0 sm:px-6 sm:pt-4 xl:pr-0">
      <div className="flex flex-col gap-4 lg:flex-row">{children}</div>
    </div>
  );
};

const Content: React.FC<ContentProps> = ({ children }) => {
  return <div className="bg-gray-200 flex-auto p-4">{children}</div>;
};

ResizableLayout.Content = Content;
ResizableLayout.Sidebar = ResizableRightSideBar;

export default ResizableLayout;
