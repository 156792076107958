import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import {
  Slice,
  SliceProps,
  VictoryContainer,
  VictoryPie,
  VictoryTooltip,
  VictoryTooltipProps,
} from 'victory';
import Legend from './Legend';
import ChartTitle from './ChartTitle';
import theme from '../../shared/theme/custom-theme';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';
import { ArrowDownOutline, ArrowUpOutline } from 'react-ionicons';

export interface DonutChartData {
  x: string;
  y: number;
  backgroundColor: string;
}

interface DonutChartProps {
  data: DonutChartData[];
  title?: string;
  isLoading?: boolean;
  showTicketLabel?: boolean;
  scrollLegendOnPieHover?: boolean;
}

interface CustomSliceProps extends SliceProps {
  events?: {
    onMouseOver: (event: MouseEvent) => void;
    onMouseOut: (event: MouseEvent) => void;
  };
  isHighlighted?: (datum: DonutChartData) => boolean;
}

const CustomSlice = (props: CustomSliceProps) => {
  const highlight =
    props.isHighlighted &&
    props.datum &&
    props.isHighlighted(props.datum as DonutChartData);

  // modified transformation from here
  // https://github.com/FormidableLabs/victory/blob/844109cfe4e40b23a4dcb565e551a5a98015d0c0/packages/victory-pie/src/slice.js#L74
  const transform = `translate(${props.origin?.x}, ${props.origin?.y}) scale(${
    highlight ? 1.05 : 1
  })`;
  const sliceEventProps = {
    transform,
  } as unknown as SliceProps;

  return <Slice {...props} style={{ ...props.style }} {...sliceEventProps} />;
};

interface CustomTooltipProps extends VictoryTooltipProps {
  isActive?: (datum: DonutChartData) => boolean;
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const isActive =
    props.isActive &&
    props.datum &&
    props.isActive(props.datum as DonutChartData);
  return <VictoryTooltip {...props} active={isActive || props.active} />;
};

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  title,
  isLoading,
  showTicketLabel = true,
  scrollLegendOnPieHover = false,
}) => {
  const dataSet = data.filter((type) => type.y > 0); // separate labels from data to not show 0 data
  const [hoveredLabel, setHoveredLabel] = useState<string | undefined>();

  const legendContainerRef = useRef<HTMLDivElement | null>(null);
  const legendItemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');

  useEffect(() => {
    const container = legendContainerRef.current;

    const checkScrollable = () => {
      if (container) {
        const isScrolledToTop = container.scrollTop === 0;
        const isScrolledToBottom =
          container.scrollTop + container.clientHeight >=
          container.scrollHeight;

        setIsScrollable(!(isScrolledToTop && isScrolledToBottom));
        if (isScrolledToTop) {
          setScrollDirection('down');
        } else if (isScrolledToBottom) {
          setScrollDirection('up');
        }
      }
    };

    if (container) {
      container.addEventListener('scroll', checkScrollable);
      checkScrollable();
    }

    return () => container?.removeEventListener('scroll', checkScrollable);
  }, []);

  const scrollToLegendItem = (label: string | undefined) => {
    if (label && legendItemRefs.current[label]) {
      legendItemRefs.current[label]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  const handleArrowClick = () => {
    const container = legendContainerRef.current;
    if (container) {
      container.scrollTo({
        top: scrollDirection === 'down' ? container.scrollHeight : 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      {title && <ChartTitle title={title} />}
      {isLoading ? (
        <Skeleton className="h-[170px] w-full" />
      ) : dataSet.length > 0 ? (
        <div className="flex w-full items-center justify-center gap-6">
          <div className="hidden sm:block">
            <VictoryPie
              padding={0}
              data={dataSet}
              innerRadius={120}
              padAngle={1}
              dataComponent={
                <CustomSlice isHighlighted={({ x }) => x === hoveredLabel} />
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseOver: (_, dataComponentProps) => {
                      const label = dataComponentProps.datum.x;
                      scrollLegendOnPieHover && scrollToLegendItem(label);
                      return [
                        {
                          target: 'data',
                          mutation: () => {
                            setHoveredLabel(dataComponentProps.datum.x);
                            return { highlight: true };
                          },
                        },
                        {
                          target: 'labels',
                          mutation: () => ({ active: true }),
                        },
                      ];
                    },
                    onMouseOut: () => {
                      return [
                        {
                          target: 'data',
                          mutation: () => {
                            setHoveredLabel(undefined);
                            return { highlight: false };
                          },
                        },
                        {
                          target: 'labels',
                          mutation: () => ({ active: false }),
                        },
                      ];
                    },
                  },
                },
              ]}
              labels={({ datum }) =>
                showTicketLabel
                  ? `${datum.y} ${datum.y === 1 ? 'ticket' : 'tickets'}`
                  : `${datum.y}`
              }
              labelComponent={
                <CustomTooltip
                  isActive={({ x }) => x === hoveredLabel}
                  cornerRadius={16}
                  pointerWidth={56}
                  pointerLength={24}
                  orientation="top"
                  style={{
                    fontFamily: theme.fonts.body,
                    fill: theme.colors.text.white,
                    fontWeight: theme.fontWeights.bold,
                    fontSize: '44px',
                  }}
                  flyoutPadding={54}
                  flyoutStyle={{
                    fill: theme.colors.background.baseSurface,
                    maxWidth: '120px',
                    borderRadius: '8px',
                    boxShadow:
                      '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
                  }}
                />
              }
              cornerRadius={6}
              style={{
                data: {
                  fill: ({ datum }) => datum.backgroundColor,
                },
              }}
              containerComponent={
                <VictoryContainer className="[&>svg]:overflow-visible" />
              }
            />
          </div>
          <div className="relative max-h-[160px] w-full">
            <div
              className="vertical-scrollbar flex max-h-[160px] w-full flex-col items-start justify-start overflow-y-auto overflow-x-hidden scrollbar-none"
              ref={legendContainerRef}
            >
              {data.map((datum) => (
                <Legend
                  key={datum.x}
                  ref={(el) => (legendItemRefs.current[datum.x] = el)}
                  backgroundColor={datum.backgroundColor}
                  x={datum.x}
                  y={datum.y}
                  onMouseOver={() => setHoveredLabel(datum.x)}
                  onMouseOut={() => setHoveredLabel(undefined)}
                  onFocus={() => setHoveredLabel(datum.x)}
                  onBlur={() => setHoveredLabel(undefined)}
                  active={hoveredLabel === datum.x}
                />
              ))}
            </div>
            {isScrollable && (
              <div
                className={`absolute ${
                  scrollDirection === 'down' ? 'bottom-0' : 'top-0'
                } left-1/2 flex -translate-x-1/2 transform justify-center`}
              >
                <div
                  className="from-gray-300 shadow-gray-400/50 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-background-base-surface-1 to-white shadow-elevation4 transition hover:scale-110"
                  onClick={handleArrowClick}
                >
                  {scrollDirection === 'down' ? (
                    <ArrowDownOutline width="15px" height="15px" color="#fff" />
                  ) : (
                    <ArrowUpOutline width="15px" height="15px" color="#fff" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default DonutChart;
