import { ComponentProps } from 'react';
import { clsx } from 'clsx';
import { DetailField } from 'screens/TicketDetail/TicketDetailUtil';
import { NetOpsTicketStatuses } from 'api/nocexpress/schemas';
import { formatDate } from 'screens/TechExpress/TechExpress';
import { formatTicketNumber, returnMainStatusOfTicket } from './util';
import { NetOpsResponseForm } from './form-schema';

export interface TicketDetailMetaProps extends ComponentProps<'div'> {
  ticket?: NetOpsResponseForm;
}

export const TicketDetailMeta = ({
  className,
  ticket,
}: TicketDetailMetaProps) => {
  const getBackgroundColor = () => {
    const unifiedStatus = returnMainStatusOfTicket(
      ticket?.status_name as string,
    );
    switch (unifiedStatus) {
      case NetOpsTicketStatuses.Enum.Open:
        return '#FBB979';
      case NetOpsTicketStatuses.Enum['In Progress']:
        return '#82FF91';
      case NetOpsTicketStatuses.Enum['Pending Action']:
      case NetOpsTicketStatuses.Enum['Pending NI']:
        return '#F9CB34';
      case NetOpsTicketStatuses.Enum['Pending Customer']:
        return '#FF315E';
      case NetOpsTicketStatuses.Enum.Resolved:
        return '#82F0FF';
      default:
        return '#FBB979';
    }
  };

  const isNestedStatus = NetOpsTicketStatuses.options.some(
    (item) => item === ticket?.status_name,
  );
  return (
    <div className={clsx('col-span-full w-full', className)}>
      <div className="col-span-full flex flex-wrap items-center justify-between gap-4 rounded-t bg-background-base-surface-2 px-6 py-4 last:rounded-b">
        <div className="flex items-center gap-3 text-xl font-bold text-content-base-default">
          <span
            className={clsx('h-[16px] w-[16px] rounded-full')}
            style={{
              backgroundColor: getBackgroundColor(),
            }}
          />
          <div className="flex flex-col items-start justify-start">
            <h1 className="text-xl font-bold text-content-base-default">
              {returnMainStatusOfTicket(ticket?.status_name as string)}
            </h1>
            {!isNestedStatus && (
              <p className="text-base font-bold text-content-base-subdued">
                {ticket?.status_name}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-x-12 gap-y-6">
          {ticket?.technician_name && (
            <DetailField
              description="Assigned"
              value={ticket.technician_name}
            />
          )}
          <DetailField
            description="Last updated"
            value={formatDate(ticket?.last_updated.toString())}
          />
          <DetailField
            description="Opened"
            value={formatDate(ticket?.date_entered.toString())}
          />
          <DetailField
            description="Customer ticket #"
            value={formatTicketNumber(ticket?.customer_ticket_number || '')}
          />
        </div>
      </div>
    </div>
  );
};
