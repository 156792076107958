import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useMutation } from 'react-query';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import showToast from 'components/Toast/Toast';
import {
  ModifiedOktaGroupProfile,
  OktaGroupProfile,
} from 'api/companies/schema';
import {
  getGroup,
  updateGroup,
  createGroup,
  updatePricingTier,
} from 'api/companies/api';
import { LoaderGranite } from 'components';
import { GraniteSelect } from 'components/Select/Select';
import { usePermissions } from 'hooks/usePermissions';

interface ManageCompanyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  dynamicProps?: {
    groupId: string;
    profile?: OktaGroupProfile;
  };
  onCompanySuccess: () => void;
}

const pricingTierOptions = [
  { value: 'Standard', label: 'Standard 0%' },
  { value: 'Highest tier', label: 'Highest tier +15%' },
  { value: 'High tier', label: 'High tier +7%' },
  { value: 'Low tier', label: 'Low tier -7%' },
  { value: 'Lowest tier', label: 'Lowest tier -13%' },
];

export enum pricingTier {
  standard = 'Standard',
  highest_tier = 'Highest tier',
  high_tier = 'High tier',
  low_tier = 'Low tier',
  lowest_tier = 'Lowest tier',
}

const defaultValues: OktaGroupProfile = {
  display_name: '',
  name: '',
  description: 'g360 org',
  macnum: null,
  cw_id: null,
  cw_type: 'company',
  pricing_tier: 'Standard',
};

export const ManageCompanyDialog: React.FC<ManageCompanyDialogProps> = ({
  isOpen,
  onClose,
  dynamicProps,
  onCompanySuccess,
}) => {
  const isEdit = Boolean(dynamicProps?.groupId);
  const [isUpdating, setIsUpdating] = useState(false);
  const { isSuperAdmin } = usePermissions();

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<OktaGroupProfile>({
    ...(isSuperAdmin
      ? { resolver: zodResolver(ModifiedOktaGroupProfile) }
      : {}),
    defaultValues,
  });

  const { isLoading, data } = useQuery(
    ['group', dynamicProps?.groupId],
    () => getGroup(dynamicProps?.groupId as string),
    {
      enabled: isEdit && isSuperAdmin,
    },
  );
  useEffect(() => {
    if (!isLoading && data) {
      const pricing_tier: pricingTier =
        (pricingTierOptions.find((option) => option.value === data.pricing_tier)
          ?.label as pricingTier) ?? 'Standard';
      reset({
        cw_id: data.cw_id,
        cw_type: data.cw_type,
        description: data.description,
        display_name: data.display_name,
        macnum: data.macnum,
        name: data.name,
        pricing_tier,
      });
    } else if (dynamicProps?.profile) {
      const pricing_tier: pricingTier =
        (pricingTierOptions.find(
          (option) => option.value === dynamicProps?.profile?.pricing_tier,
        )?.label as pricingTier) ?? 'Standard';
      reset({
        ...dynamicProps.profile,
        pricing_tier,
      });
    } else if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, isLoading, data, reset, dynamicProps]);

  const mutation = useMutation(
    (payload: OktaGroupProfile) =>
      isEdit
        ? isSuperAdmin
          ? updateGroup(payload, dynamicProps?.groupId as string)
          : updatePricingTier(
              { pricing_tier: payload.pricing_tier },
              dynamicProps?.groupId as string,
            )
        : createGroup(payload),
    {
      onSuccess: () => {
        showToast.confirmation({
          message: `Company ${isEdit ? 'updated' : 'added'} successfully!`,
        });
        onClose();
        onCompanySuccess();
      },
      onError: ({ response }) => {
        showToast.error({
          message: response.data.message ?? 'Something went wrong',
        });
        setIsUpdating(false);
      },
      onSettled: () => {
        setIsUpdating(false);
      },
    },
  );

  const onSubmit = (payload: OktaGroupProfile) => {
    setIsUpdating(true);
    mutation.mutate(payload);
  };

  const onError = (err: unknown) => {
    console.log('FormError:', err);
    return;
  };

  const onModalClose = () => {
    reset({});
    onClose();
  };

  return (
    <Modal
      isVisible={isOpen}
      close={onModalClose}
      className="w-full max-w-[624px] "
      enableScrolling={true}
    >
      <div className="flex h-full flex-col gap-5 p-8">
        <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
          {isEdit ? 'Edit company' : 'Add company'}
        </h1>
        {isLoading ? (
          <LoaderGranite className="m-auto" />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="space-y-6"
          >
            <Controller
              control={control}
              name="pricing_tier"
              render={({ field }) => {
                const value = pricingTierOptions.find(
                  (option) => option.value === field.value,
                );
                return (
                  <GraniteSelect
                    options={pricingTierOptions}
                    label="Pricing tier"
                    error={errors.pricing_tier?.message}
                    isSearchable={false}
                    {...field}
                    onChange={(e) => field.onChange(e?.value)}
                    value={value}
                  />
                );
              }}
            />
            {/* <DisplayForPermission onlyForSuperAdmin> */}
            <GraniteInput
              label="Company name"
              placeholder="Company Name"
              {...register('name')}
              error={errors.name?.message}
              disabled={!isSuperAdmin}
            />
            <GraniteInput
              label="Company display name"
              placeholder="Display Name"
              {...register('display_name')}
              error={errors.display_name?.message}
              disabled={!isSuperAdmin}
            />
            <GraniteInput
              label="Company macnum"
              placeholder="Macnum"
              {...register('macnum')}
              error={errors.macnum?.message}
              disabled={!isSuperAdmin}
            />
            <Controller
              control={control}
              name="cw_id"
              render={({ field }) => (
                <GraniteInput
                  label="Connectwise rec id"
                  innerInputClassName="w-full"
                  className="w-full"
                  placeholder="Connectwise rec id"
                  {...field}
                  disabled={!isSuperAdmin}
                  value={`${field.value ?? ''}`}
                  onChange={(e) => {
                    if (e.target.value.length)
                      field.onChange(Number(e.target.value));
                    else field.onChange(null);
                  }}
                  onKeyDown={(e) => {
                    if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                  error={errors.cw_id?.message}
                />
              )}
            />
            <GraniteInput
              label="Type"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="Type"
              {...register('cw_type')}
              error={errors.cw_type?.message}
              disabled={!isSuperAdmin}
            />
            <GraniteInput
              label="Description"
              placeholder="Description"
              {...register('description')}
              error={errors.description?.message}
              disabled={!isSuperAdmin}
            />
            <div className="flex justify-end gap-4">
              <GraniteButton variant="secondary" onClick={onClose}>
                Cancel
              </GraniteButton>
              <GraniteButton
                variant="primary"
                type="submit"
                size="large"
                disabled={isUpdating}
              >
                Save Changes{' '}
              </GraniteButton>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};
