import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import FormSection from '../../../components/FormSection';
import Divider from '../../../components/Divider';
import { Controller, useForm } from 'react-hook-form';
import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { preventSubmitOnEnter } from '../../../shared/util/preventSubmitOnEnter';
import { useNavigate } from 'react-router-dom';
import {
  WizardAction,
  useOpenNetOpsTicketContext,
} from '../WizardProvider/WizardReducer';
import { OpenNetOpsTicketLayout } from '../BaseComponents/OpenNetOpsTicketLayout';
import { OpenNetOpsTicketBaseForm } from '../BaseComponents/OpenNetOpsTicketBaseForm';
import { GraniteSelect } from 'components/Select/Select';
import './FindLocationOrService.css';
import {
  FindLocationOrServiceForm,
  FindLocationOrServiceFormSchema,
  IDENTIFIERS,
  ServiceOptionLabelValue,
} from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import SiteInformation from '../BaseComponents/SiteInformation';
import BulkAddLocationsDialog from './BulkAddLocations/BulkAddLocationsDialog';
import { useModal } from 'hooks/useModal';
import { Add } from 'react-ionicons';
import { BulkLocations } from './BulkAddLocations/schemas';
import { LocationsTable } from './LocationsTable';
import {
  handleSelectChange,
  handleServiceSelectChange,
} from '../utils/selectWithSelectAllHandler';
import { IdentifierSearchBar } from './IdentifierSearchBar';
import {
  Configurations,
  NOCSiteAddress,
} from 'api/nocexpress/schemas/ConfigurationsSchema';
import isSiteAddress from '../utils/isSiteAddress';
import { useQuery } from 'react-query';
import {
  fetchConfigurationServices,
  fetchNOCStaticData,
  getConfigurations,
} from 'api/nocexpress/api';
import Loader from 'components/Loader';
import showToast from 'components/Toast/Toast';
import clsx from 'clsx';
import useDownloadConfigurations from '../../../shared/util/useDownloadConfigurations';
import { NetOpsItem, NetOpsTicketStatuses } from 'api/nocexpress/schema';
import { DuplicateTicketWarningDialog } from '../BaseComponents/DuplicateTicketWarningDialog';
import { getDuplicateTickets } from 'api/nocexpress/getDuplicateTickets';
import { useProductTourContext } from 'context/ProductTourContext';
import { getFindLocationMockData } from 'mocks/tour';

export const getInformationBySelectedIdentifier = (
  selectedIdentifier: NOCSiteAddress | Configurations,
) => {
  const address = isSiteAddress(selectedIdentifier)
    ? `${selectedIdentifier.address_line_1}, ${selectedIdentifier.city}, ${selectedIdentifier.state}, ${selectedIdentifier.zip}`
    : [
        selectedIdentifier?.site.address_line_1,
        selectedIdentifier?.site.city,
        selectedIdentifier?.site.state,
        selectedIdentifier?.site.zip,
      ]
        .filter(Boolean)
        .join(', ');

  const account = isSiteAddress(selectedIdentifier)
    ? selectedIdentifier.account_name
    : selectedIdentifier.site.account_name;
  const siteName = isSiteAddress(selectedIdentifier)
    ? selectedIdentifier.site_name
    : selectedIdentifier.site.name;

  return [
    {
      label: 'Address',
      value: address,
    },
    {
      label: 'Account',
      value: account,
    },
    {
      label: 'Site name',
      value: siteName,
    },
  ];
};

const siteTypeOptions = [
  {
    label: 'Multi-Site Outage',
    value: 'Multi-Site Outage',
    subvalue: 'Complete loss of service across multiple locations.',
  },
  {
    label: 'Multi-Site Service Impact',
    value: 'Multi-Site Service Impact',
    subvalue:
      'Partial service disruption affecting performance across multiple locations.',
  },
  {
    label: 'Multi-Site Informational',
    value: 'Multi-Site Informational',
    subvalue: 'Non-urgent request for information about multiple locations.',
  },
  {
    label: 'Multi-Site Configuration Request',
    value: 'Multi-Site Configuration Request',
    subvalue:
      'Request for changes or adjustments to network settings across multiple locations.',
  },
];

const initialServiceIdentifierOptions = [{ label: 'Select all', value: '*' }];
const initialServiceOptions = [
  { label: 'Select all', value: '*' },
  { label: 'My service is not listed', value: 'service_not_listed' },
];
interface FindLocationOrServiceProps {
  dispatch: Dispatch<WizardAction>;
  findLocationOrServiceDefaultValues?: FindLocationOrServiceForm;
  storedBulkLocations?: BulkLocations['locations'];
  storedServiceOptions?: { label: string; value: string }[];
}

const FindLocationOrService = ({
  dispatch,
  findLocationOrServiceDefaultValues,
  storedBulkLocations,
  storedServiceOptions,
}: FindLocationOrServiceProps) => {
  const navigate = useNavigate();
  const { running } = useProductTourContext();
  const [bulkLocations, setBulkLocations] = useState<
    BulkLocations['locations']
  >(storedBulkLocations ?? []);

  const bulkDialogRef = useRef(null);

  const [allServices, setAllServices] = useState<Configurations[]>([]);
  const [serviceOptions, setServiceOptions] = useState<
    { label: string; value: string }[]
  >(storedServiceOptions ?? initialServiceOptions);

  const [userClearedService, setUserClearedService] = useState(false);

  const [serviceIdentifiers, setServiceIdentifiers] = useState<
    ServiceOptionLabelValue[]
  >(initialServiceIdentifierOptions);

  const mockData = useMemo(() => getFindLocationMockData(), []);

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<FindLocationOrServiceForm>({
    resolver: zodResolver(FindLocationOrServiceFormSchema),
    //@ts-expect-error schema validation
    defaultValues: running
      ? mockData.findLocationOrServiceForm
      : {
          identifier: { value: 'Address', label: 'Address' },
          service: [],
          service_identifier: [],
          service_category: null,
          ...findLocationOrServiceDefaultValues,
        },
  });

  useEffect(() => {
    if (running) {
      dispatch({
        type: 'GoToTicketDetails',
        //@ts-expect-error schema validation
        findLocationOrServiceForm: mockData.findLocationOrServiceForm,
        services: mockData.services,
        bulkLocations: mockData.bulkLocations,
        serviceOptions: mockData.serviceOptions,
      });
    }
  }, [running, dispatch, mockData]);

  const [
    currentIdentifier,
    selectedIdentifier,
    service,
    siteType,
    type,
    selectedServiceIdentifiers,
  ] = watch([
    'identifier',
    'selectedIdentifier',
    'service',
    'site_type',
    'type',
    'service_identifier',
  ]);

  const clearFormCompletely = () => {
    reset({
      identifier: currentIdentifier,
      service: [],
      service_identifier: [],
      site_type: null,
      service_category: null,
    });
    setTimeout(() => {
      setServiceOptions(initialServiceOptions);
    }, 0);
  };

  const handleClearAll = () => {
    setBulkLocations([]);
    setTimeout(() => {
      clearFormCompletely();
    }, 0);
    if (bulkDialogRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      bulkDialogRef.current.resetForm();
    }
  };

  const onError = (err: unknown) => console.log(err);

  const handleCancel = () => {
    navigate(-1);
  };

  const { open, ...bulkModalProps } = useModal();
  const { openWithProps, ...duplicateModalProps } = useModal<NetOpsItem[]>();

  const watchIdentifier = watch('identifier');

  const site_ids =
    type === 'multi-site' && bulkLocations.length > 0
      ? bulkLocations.map((location) => location.id).join(',')
      : isSiteAddress(selectedIdentifier)
        ? selectedIdentifier?.id.toString()
        : selectedIdentifier?.site_id.toString();

  const { data: services, isLoading: isConfigurationLoading } = useQuery(
    ['serviceIdentifiers', service, selectedIdentifier],
    () =>
      fetchConfigurationServices({
        services:
          service &&
          service.filter((s) => s.value !== '*').map((item) => item.value)
            .length > 0
            ? service
                .filter((s) => s.value !== '*')
                .map((item) => item.label)
                .join(',')
            : undefined,

        site_ids,
      }),
    {
      enabled:
        (!!selectedIdentifier || (bulkLocations.length > 0 && !!siteType)) &&
        !service.find((item) => item.value === 'service_not_listed') &&
        !running,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      onSuccess: (data) => {
        setAllServices(data);
      },
    },
  );

  const { data: staticData, isLoading: isStaticDataLoading } = useQuery(
    ['whatNeedsToBeDone-options'],
    () => fetchNOCStaticData(),
    {
      enabled:
        !!(
          service?.length > 0 &&
          service.find((item) => item.value === 'service_not_listed')
        ) && !running,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (allServices.length > 0 && (!service || service.length === 0)) {
      const uniqueServicesMap = new Map();
      allServices.forEach((service) => {
        if (
          service &&
          service.service &&
          !uniqueServicesMap.has(service.service)
        ) {
          uniqueServicesMap.set(service.service, service);
        }
      });
      const options = [
        { label: 'Select all', value: '*' },
        ...Array.from(uniqueServicesMap.values()).map((service) => ({
          label: service.service,
          value: service.id.toString(),
          service_id: service.service_id,
        })),
        { label: 'My service is not listed', value: 'service_not_listed' },
      ];
      setServiceOptions(options);
    }
  }, [allServices, bulkLocations.length, service]);

  useEffect(() => {
    if (services?.length === 0) {
      setServiceIdentifiers(() => [...[{ label: 'Select all', value: '*' }]]);
    }
    if (services && services?.length > 0) {
      setServiceIdentifiers(() => [
        ...[{ label: 'Select all', value: '*' }],
        ...services.map((item) => {
          return {
            label: `${item.service} - ${item.service_id}`,
            value: item.id.toString(),
            service_id: item.service_id,
            service_id_label: item.service_id_label,
          };
        }),
      ]);
    }
  }, [services, setValue]);

  useEffect(() => {
    const selectableOptions = serviceOptions.filter(
      (option) => option.value !== '*' && option.value !== 'service_not_listed',
    );

    if (selectableOptions.length === 1 && !userClearedService) {
      setValue('service', [
        {
          label: selectableOptions?.[0].label,
          value: selectableOptions?.[0].value,
        },
      ]);
    }
  }, [serviceOptions, setValue, userClearedService]);

  useEffect(() => {
    const selectableOptions = serviceIdentifiers.filter(
      (option) => option.value !== '*',
    );

    if (selectableOptions.length === 1) {
      setValue('service_identifier', [
        {
          label: selectableOptions?.[0].label,
          value: selectableOptions?.[0].value,
          service_id: selectableOptions?.[0].service_id,
          service_id_label: selectableOptions?.[0].service_id_label,
        },
      ]);
    }
  }, [serviceIdentifiers, serviceOptions, setValue]);

  const onSubmit = async (formData: FindLocationOrServiceForm) => {
    const serviceNotListedSelected = formData.service.some(
      (s) => s.value === 'service_not_listed',
    );
    const otherServicesSelected = formData.service.some(
      (s) => s.value !== 'service_not_listed' && s.value !== '*',
    );

    if (serviceNotListedSelected && otherServicesSelected) {
      showToast.error({
        message:
          'Cannot select services with the “My service is not listed” option.',
      });
      return;
    }
    if (
      type === 'multi-site' ||
      formData.service.find((item) => item.value === 'service_not_listed')
    ) {
      dispatch({
        type: 'GoToTicketDetails',
        findLocationOrServiceForm: formData,
        services,
        bulkLocations,
        serviceOptions,
      });
      navigate('details');
    } else {
      const isAllSelected = selectedServiceIdentifiers?.some(
        (item) => item.value === '*',
      );

      const unresolvedStatuses = NetOpsTicketStatuses.options
        .filter((status) => status !== 'Resolved')
        .join(',');

      const payload = isAllSelected
        ? {
            site_ids: site_ids,
            configuration_ids: 'all',
            status: unresolvedStatuses,
          }
        : {
            configuration_ids:
              selectedServiceIdentifiers
                ?.filter((item) => item.value !== '*')
                .map((item) => Number(item.value))
                .join(',') || '',
            status: unresolvedStatuses,
          };

      const duplicates = await getDuplicateTickets(payload);

      if (duplicates.length > 0) {
        openWithProps(duplicates);
      } else {
        dispatch({
          type: 'GoToTicketDetails',
          findLocationOrServiceForm: formData,
          services,
          bulkLocations,
          serviceOptions,
        });
        navigate('details');
      }
    }
  };

  useEffect(() => {
    if (bulkLocations.length > 0) {
      setValue('type', 'multi-site');
    } else if (selectedIdentifier) {
      const isSite = isSiteAddress(selectedIdentifier);
      setValue('type', isSite ? 'site' : 'config');
    } else {
      setValue('type', 'site');
    }
  }, [bulkLocations.length, selectedIdentifier, setValue]);

  const { isLoading: isDownloading, onDownloadHandler } =
    useDownloadConfigurations({ fetchFn: getConfigurations });

  return (
    <OpenNetOpsTicketLayout>
      <OpenNetOpsTicketBaseForm
        className="gap-8 sm:gap-12 xl:min-h-[662px]"
        id="noc-find-location-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
      >
        {bulkLocations.length === 0 && (
          <FormSection title="How we can help?" gridClassName="grid-cols-2">
            <p className="col-span-2 -mb-3 text-base font-bold text-content-base-subdued">
              How would you like to identify your location or service? If a
              multi-service issue, searching by address is required.
            </p>
            <div className="col-span-full flex flex-col items-stretch gap-4 md:flex-row md:items-center md:gap-0">
              <Controller
                name="identifier"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <GraniteSelect
                    {...field}
                    onChange={(newValue) => {
                      setServiceOptions(initialServiceOptions);
                      setTimeout(() => {
                        clearFormCompletely();
                      }, 0);
                      setTimeout(() => {
                        onChange(newValue);
                      }, 10);
                    }}
                    value={value}
                    options={IDENTIFIERS.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    className={`basis-0 self-baseline md:basis-1/4 ${
                      errors.site?.message ? '!mb-6' : ''
                    }`}
                    classNames={{
                      control: () => 'md:!rounded-r-none',
                    }}
                  />
                )}
              />
              <IdentifierSearchBar
                className={`flex-grow basis-0 md:basis-3/4`}
                value={selectedIdentifier}
                identifier={watchIdentifier?.value}
                onChange={() => {
                  clearFormCompletely();
                }}
                onResultSelected={(result) => {
                  // RHF bug workaround
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue('selectedIdentifier', result!);
                }}
                isDisabled={bulkLocations.length > 0}
                name={'search address'}
                error={
                  errors.selectedIdentifier?.message &&
                  'An identifier is required'
                }
              />
            </div>
            <div className="col-span-2 flex flex-col items-start gap-2">
              <p className="text-sm text-content-base-subdued">
                For multi-site service requests, you can add up to 100 of your
                company&apos;s sites that should be investigated or serviced
                within the scope of this ticket.{' '}
                <a
                  rel="noreferrer"
                  download
                  onClick={
                    !isDownloading
                      ? onDownloadHandler
                      : (e) => e.preventDefault()
                  }
                  className={clsx(
                    !isDownloading &&
                      'cursor-pointer text-content-accent-default underline visited:text-content-accent-default',
                    isDownloading &&
                      'text-button-background-primary-disabled underline',
                  )}
                >
                  Download site list
                </a>
              </p>
              <GraniteButton
                variant="secondary"
                className="!box-border w-full !whitespace-nowrap md:w-[170px]"
                onClick={open}
                disabled={bulkLocations.length > 0}
              >
                Add multiple
                <Add width="20px" height="20px" color="inherit" />
              </GraniteButton>
            </div>
          </FormSection>
        )}
        {bulkLocations.length > 0 && (
          <FormSection gridClassName="grid-cols-1">
            <LocationsTable
              locations={bulkLocations}
              open={() => {
                open();
              }}
              clearAll={handleClearAll}
            />
          </FormSection>
        )}
        {(selectedIdentifier || bulkLocations.length > 0) && (
          <>
            <Divider className="col-span-2" />
            <FormSection>
              {bulkLocations.length > 0 && (
                <>
                  <p className="col-span-2 text-base font-bold text-content-base-subdued">
                    We&apos;ve found your location and associated services.
                    Please select the services that you are experiencing issues
                    with.
                  </p>
                  <Controller
                    name="site_type"
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <GraniteSelect
                        {...field}
                        onChange={onChange}
                        value={value}
                        options={siteTypeOptions}
                        className="col-span-2"
                        label="Issue type"
                        error={errors.site_type && 'Required'}
                      />
                    )}
                  />
                </>
              )}

              {selectedIdentifier && (
                <div className="col-span-2 flex flex-col gap-4">
                  <SiteInformation
                    information={getInformationBySelectedIdentifier(
                      selectedIdentifier,
                    )}
                    chronic={
                      isSiteAddress(selectedIdentifier)
                        ? selectedIdentifier.is_chronic
                        : false
                    }
                  />
                </div>
              )}
            </FormSection>
            {(selectedIdentifier || bulkLocations.length > 0) && (
              <div className="-mt-2 flex flex-col gap-6 sm:-mt-4">
                <Controller
                  name="service"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <GraniteSelect
                      {...field}
                      value={value}
                      onChange={(selectedOptions, actionMeta) => {
                        if (
                          actionMeta.action === 'remove-value' &&
                          selectedOptions.length === 0
                        ) {
                          setUserClearedService(true);
                        } else {
                          setUserClearedService(false);
                        }
                        setValue('service_identifier', []);
                        setValue('service_category', null);
                        const updatedOptions = handleServiceSelectChange(
                          selectedOptions,
                          actionMeta,
                          serviceOptions,
                          setServiceOptions,
                          //@ts-expect-error setValue
                          setValue,
                        );
                        onChange(updatedOptions);
                      }}
                      options={serviceOptions}
                      isMulti
                      className="col-span-2"
                      label="Service"
                      error={errors.service?.message}
                    />
                  )}
                />
                {(isConfigurationLoading || isStaticDataLoading) && <Loader />}
                {!isConfigurationLoading &&
                  service?.length > 0 &&
                  !service.find(
                    (item) => item.value === 'service_not_listed',
                  ) && (
                    <Controller
                      name="service_identifier"
                      control={control}
                      render={({ field: { onChange, value, ...field } }) => (
                        <GraniteSelect
                          {...field}
                          value={value}
                          onChange={(selectedOptions, actionMeta) => {
                            const updatedOptions = handleSelectChange(
                              selectedOptions,
                              actionMeta,
                              serviceIdentifiers,
                            );
                            onChange(updatedOptions);
                          }}
                          options={serviceIdentifiers}
                          isMulti
                          className="col-span-2"
                          label="Service identifier"
                          error={errors.service_identifier?.message}
                        />
                      )}
                    />
                  )}
                {!isStaticDataLoading &&
                  service?.length > 0 &&
                  service.find(
                    (item) => item.value === 'service_not_listed',
                  ) && (
                    <Controller
                      name="service_category"
                      control={control}
                      render={({ field: { onChange, value, ...field } }) => (
                        <GraniteSelect
                          {...field}
                          value={value}
                          onChange={onChange}
                          options={Object.entries(
                            staticData?.not_listed_service_to_ticket_type_subtype ||
                              {},
                          ).map(([key, { type_name, subtype_name }]) => ({
                            label: key,
                            //  ||| - Unique delimiter that does not appear in the data
                            value: [type_name, subtype_name].join('|||'),
                          }))}
                          className="col-span-2"
                          label="Select a service category"
                          error={errors.service_category?.message}
                        />
                      )}
                    />
                  )}
              </div>
            )}
          </>
        )}
      </OpenNetOpsTicketBaseForm>
      <div className="sticky top-8 flex gap-4 rounded shadow xl:bg-background-base-surface-2 xl:p-6">
        <GraniteButton
          variant="secondary"
          size="large"
          className="w-full"
          onClick={handleCancel}
        >
          Cancel
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          disabled={isSubmitting || isConfigurationLoading}
          form="noc-find-location-form"
        >
          Next
        </GraniteButton>
      </div>
      <BulkAddLocationsDialog
        {...bulkModalProps}
        locations={bulkLocations}
        getLocations={(locations) => {
          clearFormCompletely();
          setBulkLocations(locations);
        }}
        ref={bulkDialogRef}
      />
      <DuplicateTicketWarningDialog
        duplicates={duplicateModalProps.dynamicProps}
        {...duplicateModalProps}
      />
    </OpenNetOpsTicketLayout>
  );
};

export const FindLocationOrServiceWrapper = () => {
  const { dispatch, state } = useOpenNetOpsTicketContext();

  if (state.state !== 'FindLocationOrService') {
    if (state.state === 'TicketDetails') {
      // Coming from TicketDetails, user clicked back.

      dispatch({
        type: 'GoToFindLocationOrService',
        partialTicketDetails: state.ticketDetails,
        findLocationOrServiceForm: state.findLocationOrServiceForm,
        serviceOptions: state.serviceOptions,
      });
    }
  }

  return (
    <FindLocationOrService
      dispatch={dispatch}
      findLocationOrServiceDefaultValues={state.findLocationOrServiceForm}
      storedBulkLocations={state.bulkLocations}
      storedServiceOptions={state.serviceOptions}
    />
  );
};
