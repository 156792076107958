import FormSection from 'components/FormSection';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import DualRangeSlider from './DualRangeSlider';
import { GraniteSelect } from 'components/Select/Select';

import { useEffect, useState } from 'react';
import { BroadbandProductType } from './schemas';
import { capitalizeFirstLetter } from './utils';

interface BroadbandServicesProps {
  staticData: unknown;
  onChange: (data: BroadbandProductType) => void;
  onUserChange: () => void;
  broadband: BroadbandProductType | null;
}

export const BroadbandServices = ({
  staticData,
  onChange,
  onUserChange,
  broadband,
}: BroadbandServicesProps) => {
  const {
    broadband_speed_options,
    broadband_term_options,
    broadband_ip_block_options,
  } = staticData as {
    broadband_speed_options: string[];
    broadband_term_options: string[];
    broadband_ip_block_options: string[];
  };
  const speedOptions = broadband_speed_options.map(Number);
  const [state, setState] = useState<BroadbandProductType>({
    ip_type: broadband?.ip_type || 'DYNAMIC',
    min_download:
      broadband?.min_download ||
      (speedOptions.find((item) => item === 10) ?? speedOptions[0]),
    max_download:
      broadband?.max_download ||
      (speedOptions.find((item) => item === 250) ??
        speedOptions[speedOptions.length - 1]),
    term:
      broadband?.term ||
      broadband_term_options[broadband_term_options.length - 1],
    product_type: 'Broadband',
    ip_block:
      broadband?.ip_block ||
      broadband_ip_block_options[broadband_ip_block_options.length - 1],
    connection_type: broadband?.connection_type || null,
  });

  useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  return (
    <FormSection
      title="Broadband requirements"
      subtitle="Customize the service needs for locations that require Broadband circuits."
    >
      <GraniteLabel
        label="Bandwidth range"
        subtitle="Adjust slider to select your desired internet bandwidth, starting from 5 Mb/s up to 1.5 Gb/s."
        className="col-start-1 pt-2"
      >
        <DualRangeSlider
          color="#47A853"
          background="#A7FFB2"
          allowedValues={speedOptions}
          value={[state.min_download, state.max_download]}
          onChange={([min, max]) => {
            setState({ ...state, min_download: min, max_download: max });
            onUserChange();
          }}
        />
      </GraniteLabel>

      <div className="flex gap-4">
        <div className="w-1/3">
          <GraniteSelect
            options={broadband_term_options.map((o) => ({
              label: o + (+o === 1 ? ' year' : ' years'),
              value: o,
            }))}
            label="Contract term"
            value={{
              value: state.term,
              label: state.term + (+state.term === 1 ? ' year' : ' years'),
            }}
            onChange={(e) => {
              setState({ ...state, term: e!.value });
              onUserChange();
            }}
          />
        </div>
        <div className="w-1/3">
          <GraniteSelect
            options={[
              { value: 'STATIC', label: 'Static' },
              { value: 'DYNAMIC', label: 'Dynamic' },
            ]}
            label="IP type"
            value={{
              value: state.ip_type.toUpperCase(),
              label: capitalizeFirstLetter(state.ip_type),
            }}
            onChange={(e) => {
              setState({ ...state, ip_type: e!.value });
              onUserChange();
            }}
          />
        </div>
        <div className="w-1/3">
          {state.ip_type !== 'DYNAMIC' && (
            <GraniteSelect
              options={broadband_ip_block_options.map((block) => ({
                label: block,
                value: block,
              }))}
              label="IP block"
              value={{ value: state.ip_block, label: state.ip_block }}
              onChange={(e) => {
                setState({ ...state, ip_block: e!.value });
                onUserChange();
              }}
            />
          )}
        </div>
      </div>
      <div className="w-1/2">
        <GraniteSelect
          options={[
            { value: 'Cable', label: 'Cable' },
            { value: 'Fiber', label: 'Fiber' },
          ]}
          label="Connection type (optional)"
          placeholder="Select..."
          value={
            state.connection_type
              ? { value: state.connection_type, label: state.connection_type }
              : null
          }
          onChange={(e) => {
            setState({ ...state, connection_type: e!.value });
            onUserChange();
          }}
        />
      </div>
    </FormSection>
  );
};
