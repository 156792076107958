import { GraniteButton } from 'components/V2/Button/GraniteButton';
// import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { ViewDetailFragment } from 'screens/TicketDetail/TicketDetail';
import { DetailField } from 'screens/TicketDetail/TicketDetailUtil';
import { TicketDetailHeader } from './TicketDetailHeader';
import { TicketDetailMeta } from './TicketDetailMeta';
import { TicketDetailActivity } from './TicketActivityDetail';
import { useMemo, useState } from 'react';
import { TicketForm } from './TicketForm';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  downloadNocTicketAttachment,
  fetchNOCStaticData,
  followTicket,
  getNocTicketAttachmentsMetadata,
  getTicketDetails,
} from 'api/nocexpress/api';
import { formatPhoneNumber } from 'screens/LetUsHelp/utils/formatPhoneNumber';
import {
  canReopenTicket,
  formatTicketNumber,
  returnMainStatusOfTicket,
} from './util';
import { differenceInHours } from 'date-fns';
import showToast from 'components/Toast/Toast';
import { Download, Eye, EyeOff, InformationCircle } from 'react-ionicons';
import { LocationsTable } from 'screens/OpenNetOpsTicket/FindLocationOrService/LocationsTable';
import { BulkLocations } from 'screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/schemas';
import EscalateTicket from './EscalateTicket';
import { useModal } from 'hooks/useModal';
import { useDownloadAttachments } from 'hooks/useDownloadAttachements';
import CloseTicket from './CloseTicket';
import {
  ESCALATION_IN_PROCESS,
  ESCALATION_REJECTED,
  ESCALATION_REQUESTED,
  ESCALATION_REVIEWED,
  NetOpsResponseForm,
} from './form-schema';
import ChronicFlag from 'screens/OpenNetOpsTicket/BaseComponents/ChronicFlag';
import ReopenTicket from './ReopenTicket';
import NewRepairTicketReopen from './NewRepairTicketReopen';
import { useProductTourContext } from 'context/ProductTourContext';
import { getNOCTicketDetailsMockData } from 'mocks/tour';
import ResizableLayout from 'layouts/ResizableLayout/ResizableLayout';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';

export const TicketDetails = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { id } = useParams();
  const [currentAttachmentId, setCurrentAttachmentId] = useState<
    number | undefined
  >(undefined);
  const [currentFilename, setCurrentFilename] = useState<string | undefined>(
    undefined,
  );
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const { running } = useProductTourContext();
  const [follow, setFollow] = useState<boolean>(false);
  const { data, isLoading } = useQuery(
    [id, 'ticket-details-noc'],
    () =>
      //@ts-expect-error error
      running
        ? Promise.resolve(getNOCTicketDetailsMockData())
        : getTicketDetails(id),
    {
      onSuccess: (data) => {
        setFollow(data.is_followed);
      },
      enabled: !!id,
    },
  );

  const hasMoreThanTwoHoursPassed = (dateEntered: string) => {
    const enteredDate = new Date(dateEntered);
    const now = new Date();
    const hoursDifference = differenceInHours(now, enteredDate);
    return hoursDifference > 2;
  };

  const onSuccessSubmission = () => {
    setIsEdit(false);
  };

  const { data: attachments } = useQuery(
    [id, 'ticket-attachments-noc'],
    () => getNocTicketAttachmentsMetadata(id),
    {
      enabled: !!id && !running,
    },
  );

  const isTicketCanceled = useMemo(
    () => data && data.status_name === 'Cancelled',
    [data],
  );

  const isTicketResolved = useMemo(
    () => data && data.g360_status === 'Resolved',
    [data],
  );

  const isTicketResolvedOrPendingCustomer = useMemo(
    () =>
      data &&
      ['Resolved', 'Pending Customer'].includes(
        returnMainStatusOfTicket(data.status_name),
      ),
    [data],
  );

  useQuery(
    [id, currentAttachmentId, currentFilename, 'downloaded-ticket-attachment'],
    () => downloadNocTicketAttachment(currentAttachmentId, id),
    {
      enabled: !!(currentAttachmentId && currentFilename) && !running,
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;

        if (currentFilename) {
          link.setAttribute('download', currentFilename);
          document.body.appendChild(link);
          link.click();
        }

        setCurrentAttachmentId(undefined);
        setCurrentFilename(undefined);
      },
      onError: () => {
        showToast.error({ message: 'Something went wrong!' });
      },
    },
  );

  const onAttachmentDownload = (id?: number, filename?: string) => {
    setCurrentAttachmentId(id);
    setCurrentFilename(filename);
  };

  const { open, ...modalProps } = useModal();
  const { ...closeTicketProps } = useModal();
  const { ...reopenTicketProps } = useModal();
  const { ...reopenNewRepairTicketProps } = useModal<{
    reason: string;
    additional_details: string;
  }>();

  const canReopenticketResult = canReopenTicket(data?.last_updated as string);

  const isTicketEscalated = useMemo(() => {
    return (
      !hasMoreThanTwoHoursPassed(data?.date_entered || '') ||
      data?.g360_escalated ||
      [
        ESCALATION_REQUESTED,
        ESCALATION_REVIEWED,
        ESCALATION_IN_PROCESS,
        ESCALATION_REJECTED,
      ].includes(data?.escalation_status as string)
    );
  }, [data]);

  const { data: statuses, isSuccess: isNOCStaticDataSuccess } = useQuery(
    [id, 'noc-service-options'],
    () => fetchNOCStaticData(data?.type_name, data?.sub_type),
    {
      enabled: !!id && !running && !!data,
    },
  );

  const followMutation = useMutation(
    (follow: boolean) => followTicket(data?.id as number, { follow }),
    {
      onSuccess: () => {
        setFollow((prev) => !prev);
      },
    },
  );

  const handleFollowClick = () => {
    followMutation.mutate(!follow);
  };

  const { downloadAllAttachments } = useDownloadAttachments({
    ticketId: id as string,
    attachments: attachments as { filename: string; id?: number | undefined }[],
  });

  const services = useMemo(() => {
    if (data?.configurations) {
      const uniqueNames = Array.from(
        new Set(data.configurations.map((config) => config.type)),
      );
      return uniqueNames.join(', ');
    }
    return '';
  }, [data?.configurations]);

  const renderWatchButton = () => {
    return (
      <GraniteButton
        onClick={handleFollowClick}
        size="large"
        variant="ghost"
        disabled={followMutation.isLoading || isLoading || !data}
        className="max-xs:w-full"
      >
        {follow ? 'Unwatch ticket' : 'Watch ticket'}
        {follow ? (
          <EyeOff color="#F8FAFC" width="20px" height="20px" />
        ) : (
          <Eye color="#F8FAFC" width="20px" height="20px" />
        )}
      </GraniteButton>
    );
  };

  const renderButtons = () => {
    if (isTicketResolved) {
      return (
        <>
          {renderWatchButton()}
          <DisplayForPermission
            permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
            upgradeTooltip
          >
            <GraniteButton
              variant="primary"
              onClick={reopenTicketProps.open}
              size="large"
              className="max-xs:w-full"
            >
              Reopen Ticket
            </GraniteButton>
          </DisplayForPermission>
        </>
      );
    }

    if (!isTicketResolved && isTicketCanceled) {
      return renderWatchButton();
    }

    if (!isTicketResolved && isEdit && !isTicketCanceled) {
      return (
        <>
          <GraniteButton
            variant="secondary"
            onClick={() => setIsEdit(false)}
            size="large"
            disabled={isSubmiting}
            className="max-xs:w-full"
          >
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            form="ticket-form"
            key="submit-button"
            type="submit"
            disabled={isSubmiting}
            className="max-xs:w-full"
          >
            Save Changes
          </GraniteButton>
        </>
      );
    }

    return (
      <>
        {renderWatchButton()}
        <DisplayForPermission
          permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
          upgradeTooltip
        >
          <GraniteButton
            onClick={() => setIsEdit(true)}
            size="large"
            key="edit-button"
            disabled={isLoading}
            className="max-xs:w-full"
          >
            Edit Ticket
          </GraniteButton>
        </DisplayForPermission>
      </>
    );
  };
  const handleReOpenTicket = (reason: string, additional_details: string) => {
    reopenTicketProps.close();
    reopenNewRepairTicketProps.openWithProps({
      reason,
      additional_details,
    });
  };

  return (
    <ResizableLayout>
      <ResizableLayout.Content>
        <TicketDetailHeader
          id={id}
          ticket={data as unknown as NetOpsResponseForm}
          buttons={renderButtons()}
        />
        <div className="mt-12 flex flex-col gap-8">
          <div className="col-span-full flex w-full flex-col items-start justify-start">
            <TicketDetailMeta ticket={data as unknown as NetOpsResponseForm} />
            {!isEdit && (
              <div className="flex w-full flex-wrap justify-between gap-y-4 bg-background-base-surface-3 px-6 py-4">
                <div className="min-w-3xs flex flex-col items-start justify-start">
                  <h1 className="text-base font-bold text-content-base-default">
                    Escalate this ticket to higher levels of support for further
                    attention or resolution.
                  </h1>
                </div>
                <div className="flex flex-wrap items-center justify-between gap-4 max-xs:w-full">
                  {isTicketEscalated && !isLoading && (
                    <div className="flex items-center justify-start gap-2 max-xs:w-full">
                      <span className="fill-status-info-default">
                        <InformationCircle
                          color={'inherit'}
                          width={'16px'}
                          height={'16px'}
                        />
                      </span>
                      <p className="text-sm font-semibold text-content-base-default">
                        {data?.g360_escalated
                          ? 'This ticket has been escalated'
                          : !hasMoreThanTwoHoursPassed(data?.date_entered || '')
                            ? 'This ticket must be open for at least 2 hrs in order to escalate'
                            : 'This ticket has been escalated'}
                      </p>
                    </div>
                  )}
                  {!isLoading && (
                    <>
                      <DisplayForPermission
                        permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
                        upgradeTooltip
                      >
                        <GraniteButton
                          variant="tertiary"
                          size="medium"
                          onClick={open}
                          className="product-tour-noc-escalate max-xs:w-full"
                          disabled={
                            isTicketEscalated ||
                            isTicketResolvedOrPendingCustomer
                          }
                        >
                          {!hasMoreThanTwoHoursPassed(data?.date_entered || '')
                            ? 'Escalate ticket'
                            : isTicketEscalated
                              ? 'Ticket escalated'
                              : 'Escalate ticket'}
                        </GraniteButton>
                      </DisplayForPermission>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow md:gap-12">
            {isEdit ? (
              <>
                {data && (running || isNOCStaticDataSuccess) && (
                  <TicketForm
                    data={data as unknown as NetOpsResponseForm}
                    setIsSubmiting={setIsSubmiting}
                    onSuccessSubmission={onSuccessSubmission}
                    attachments={attachments}
                    ticket_issues_dropdown_values={
                      statuses?.ticket_issues_dropdown_values
                    }
                  />
                )}
                <hr className="h-[1px] border-stroke-base-subdued" />
                <div className="flex flex-wrap gap-3">
                  <DisplayForPermission
                    permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
                    upgradeTooltip
                  >
                    <GraniteButton
                      size="large"
                      variant="secondary"
                      onClick={() => setIsEdit(false)}
                      disabled={isSubmiting}
                      className="min-w-32 max-xs:w-full"
                    >
                      Cancel
                    </GraniteButton>
                    <GraniteButton
                      size="large"
                      form="ticket-form"
                      key="submit-button"
                      type="submit"
                      disabled={isSubmiting}
                      className="min-w-32 max-xs:w-full"
                    >
                      Save changes
                    </GraniteButton>
                  </DisplayForPermission>
                </div>
              </>
            ) : (
              <>
                <ViewDetailFragment title="Service details">
                  {services && (
                    <DetailField
                      className={`${
                        data?.configurations.length === 1 ? '' : 'col-span-full'
                      }`}
                      description="Service"
                      value={services}
                    />
                  )}
                  {data?.configurations.length === 1 && (
                    <DetailField
                      description="Service Identifier"
                      value={data?.configurations[0]?.name}
                    />
                  )}
                  <DetailField description="Issue" value={data?.issue} />
                  {data?.tns_impacted && (
                    <DetailField
                      className="col-span-full"
                      description="Telephone numbers impacted"
                      value={data?.tns_impacted}
                    />
                  )}
                  <DetailField
                    className="col-span-full"
                    description="Description"
                    value={data?.initial_description}
                  />
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Ticket details">
                  <DetailField
                    description="Ticket type"
                    value={data?.type_name}
                  />
                  <DetailField
                    description="Ticket subtype"
                    value={data?.sub_type}
                  />
                  <DetailField
                    description="Customer ticket #"
                    value={formatTicketNumber(
                      data?.customer_ticket_number || '',
                    )}
                  />
                  <DetailField
                    description="Prior ticket #"
                    value={data?.prior_ticket}
                  />
                </ViewDetailFragment>
                {data?.sites && data?.sites?.length === 1 && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Location details">
                      <DetailField
                        description="Address"
                        className={
                          data?.chronic_flag ? 'col-span-1' : 'col-span-full'
                        }
                        value={data?.address_1}
                      />
                      {data?.chronic_flag && <ChronicFlag />}

                      <DetailField
                        description="Site name"
                        className="col-span-full"
                        value={
                          data?.site_name
                            ? data?.site_name.split('-')[1].trim()
                            : null
                        }
                      />
                      <DetailField
                        description="Parent account"
                        value={
                          data?.company_identifier
                            ? `#${data?.company_identifier}`
                            : null
                        }
                      />
                      <DetailField
                        description="Child account"
                        value={
                          data?.site_name
                            ? `#${data?.site_name.split('-')[0]}`
                            : null
                        }
                      />
                    </ViewDetailFragment>
                  </>
                )}

                {data?.sites && data?.sites?.length > 1 && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment
                      title="Service locations"
                      gridClassName="!grid-cols-1"
                    >
                      <LocationsTable
                        hideTitle={true}
                        locations={
                          data.sites?.map((item) => {
                            return {
                              site: {
                                address_line_1: item.address_line_1,
                                state: item.state,
                                city: item.city,
                                zip: item.zip,
                              },
                              childAccount: item?.account_name,
                              maintenance_window: item?.maintenance_window,
                              is_chronic: item.is_chronic,
                              parent_account: item?.parent_account,
                            };
                          }) as unknown as BulkLocations['locations']
                        }
                      />
                    </ViewDetailFragment>
                  </>
                )}

                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment
                  title="Local contact details"
                  gridClassName="!grid-cols-none sm:!grid-cols-2 md:!grid-cols-3"
                >
                  <DetailField
                    description="Contact type"
                    value={
                      data?.type_name === 'Multi-Site Service Request'
                        ? 'Technical Contact'
                        : 'Local Contact'
                    }
                  />
                  <DetailField
                    description="Name"
                    value={data?.local_contact_name}
                  />
                  <DetailField
                    description="Phone number"
                    value={formatPhoneNumber(data?.local_contact_number || '')}
                  />
                  {data?.additional_contacts?.map((contact) => (
                    <>
                      <DetailField
                        description="Contact type"
                        value={contact.type}
                      />
                      <DetailField description="Name" value={contact.name} />
                      <DetailField
                        description="Phone number"
                        value={formatPhoneNumber(`${contact.number}` || '')}
                      />
                    </>
                  ))}
                </ViewDetailFragment>
                <hr className="h-[1px] border-stroke-base-subdued" />
                <ViewDetailFragment title="Access hours">
                  {!!data && (
                    <>
                      <DetailField
                        description="Access hours"
                        className="col-span-full"
                        value={data?.access_hours}
                      />
                      <DetailField
                        description="Technician dispatch approval"
                        className="col-span-full"
                        value={`I${
                          data?.technician_dispatch_approved ? '' : ' do not'
                        } authorize a technician to be dispatched to this location if necessary`}
                      />
                    </>
                  )}
                </ViewDetailFragment>
                {data?.sites && data.sites.length === 1 && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Maintenance window">
                      <DetailField
                        description="Maintenance window"
                        className="col-span-full"
                        value={data.sites[0].maintenance_window}
                      />
                    </ViewDetailFragment>
                  </>
                )}
                {attachments?.length ? (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Attachments">
                      <div className="col-span-2 flex flex-row flex-wrap gap-4">
                        {attachments?.length ? (
                          attachments?.map(
                            ({
                              filename,
                              id,
                            }: {
                              filename?: string;
                              id?: number;
                            }) => (
                              <GraniteButton
                                key={id}
                                className="max-w-full overflow-auto"
                                variant="secondary"
                                size="large"
                                onClick={() =>
                                  onAttachmentDownload(id, filename)
                                }
                              >
                                <span
                                  className="h-1/2 truncate"
                                  title={filename}
                                >
                                  {filename}
                                </span>
                                <Download
                                  color="#F8FAFC"
                                  width="22px"
                                  height="22px"
                                />
                              </GraniteButton>
                            ),
                          )
                        ) : (
                          <div className="text-base font-bold text-content-base-subdued">
                            N/A
                          </div>
                        )}
                      </div>
                      {attachments && attachments.length > 0 ? (
                        <GraniteButton
                          className="box-border max-w-[165px]"
                          variant="secondary"
                          size="medium"
                          onClick={downloadAllAttachments}
                        >
                          <span>Export all</span>
                          <Download
                            color="#F8FAFC"
                            width="22px"
                            height="22px"
                          />
                        </GraniteButton>
                      ) : null}
                    </ViewDetailFragment>
                  </>
                ) : null}
                {data && data?.automatic_email_cc_address && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <ViewDetailFragment title="Notifications">
                      {data?.automatic_email_cc_address
                        .split(';')
                        .filter((i: string) => i)
                        .map((email: string, index: number) => (
                          <DetailField
                            key={index}
                            description={`Recipient ${index + 1}`}
                            className="col-span-full truncate"
                            value={email}
                          />
                        ))}
                    </ViewDetailFragment>
                  </>
                )}
                {!isTicketCanceled && !isTicketResolved && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <div className="product-tour-noc-edit flex flex-wrap gap-4">
                      <DisplayForPermission
                        permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
                        upgradeTooltip
                      >
                        {!isLoading && (
                          <GraniteButton
                            size="large"
                            variant="destructive"
                            onClick={() => closeTicketProps.open()}
                            className="min-w-32 max-xs:w-full"
                          >
                            Close ticket
                          </GraniteButton>
                        )}

                        <GraniteButton
                          onClick={() => setIsEdit(true)}
                          size="large"
                          disabled={isLoading}
                          className="min-w-32 max-xs:w-full"
                        >
                          Edit ticket
                        </GraniteButton>
                      </DisplayForPermission>
                    </div>
                  </>
                )}
                {isTicketResolved && (
                  <>
                    <hr className="h-[1px] border-stroke-base-subdued" />
                    <div className="flex flex-wrap items-center justify-start gap-4">
                      <DisplayForPermission
                        permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
                        upgradeTooltip
                      >
                        <GraniteButton
                          variant="primary"
                          onClick={reopenTicketProps.open}
                          size="large"
                          className="min-w-32 max-xs:w-full"
                        >
                          Reopen Ticket
                        </GraniteButton>
                      </DisplayForPermission>
                      <div className="flex items-center justify-start gap-2">
                        <span className="fill-status-info-default">
                          <InformationCircle
                            color={'inherit'}
                            height={'22px'}
                            width={'22px'}
                          />
                        </span>
                        <p className="text-sm font-semibold text-content-base-default">
                          You can reopen this ticket before{' '}
                          {canReopenticketResult.data}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <>
            <CloseTicket
              ticket={data as unknown as NetOpsResponseForm}
              {...closeTicketProps}
            />
            <EscalateTicket id={id} {...modalProps} />
            <ReopenTicket
              ticket={data as unknown as NetOpsResponseForm}
              handleReOpenTicket={(reason, additional_details) =>
                handleReOpenTicket(reason, additional_details)
              }
              {...reopenTicketProps}
            />
            <NewRepairTicketReopen
              ticket={data as unknown as NetOpsResponseForm}
              {...reopenNewRepairTicketProps}
              reason={reopenNewRepairTicketProps?.dynamicProps?.reason}
              additionalDetails={
                reopenNewRepairTicketProps?.dynamicProps?.additional_details
              }
            />
          </>
        </div>
      </ResizableLayout.Content>
      <ResizableLayout.Sidebar>
        <TicketDetailActivity
          ticket={data as unknown as NetOpsResponseForm}
          isEdit={isEdit}
          isTicketEscalated={!!isTicketEscalated}
          ticket_rejection_reasons={statuses?.ticket_rejection_reasons}
        />
      </ResizableLayout.Sidebar>
    </ResizableLayout>
  );
};
