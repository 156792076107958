import clsx from 'clsx';
import IconButton from 'components/IconButton';
import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

export interface CollapsableContentProps {
  children: ReactNode;
  icon?: ReactNode;
  title: string;
  className?: string;
  expand: boolean;
  togglePreference: () => void;
}

const CollapsableContent: FC<CollapsableContentProps> = ({
  children,
  icon,
  title,
  className,
  expand,
  togglePreference,
}) => {
  return (
    <div className="rounded border border-neutral-700 ">
      <div
        className="flex cursor-pointer items-center justify-between p-6"
        onClick={togglePreference}
      >
        <p className="flex items-center gap-4 text-2xl font-bold text-white">
          {icon && <span className="text-neutral-500">{icon}</span>}{' '}
          <span>{title}</span>
        </p>
        <IconButton
          icon={'chevronUp'}
          className={clsx({ 'rotate-180': expand })}
          buttonType="ghost"
        />
      </div>
      <motion.div
        initial={
          expand ? { height: 'auto', opacity: 1 } : { height: 0, opacity: 0 }
        }
        animate={{ height: expand ? 'auto' : 0, opacity: expand ? 1 : 0 }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        className="overflow-hidden"
      >
        <div className={clsx('flex flex-col gap-4 px-6 pb-6 pt-2', className)}>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default CollapsableContent;
