import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createPopper } from '@popperjs/core';
import clsx from 'clsx';
import { toSentenceCase } from 'shared/util/util';

const UserStatusColumn = ({ status }: { status: string }) => {
  const timerRef = useRef<number | null>(null);
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const displayStatus = getDisplayStatus(status);
  const tooltipText = getTooltipText(displayStatus);

  useEffect(() => {
    if (isTooltipVisible && referenceElement.current && tooltipRef.current) {
      const popperInstance = createPopper(
        referenceElement.current,
        tooltipRef.current,
        {
          placement: 'top',
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
        },
      );
      return () => popperInstance.destroy();
    }
  }, [isTooltipVisible]);

  const showTooltip = () => clearTimerAndSetVisible(true);
  const hideTooltip = () => setTimerToHideTooltip();
  const keepTooltipVisible = () => clearTimerAndSetVisible(true);
  const handleMouseLeave = () => checkAndHideTooltip();

  const clearTimerAndSetVisible = (isVisible: boolean) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setIsTooltipVisible(isVisible);
  };

  const setTimerToHideTooltip = () => {
    timerRef.current = window.setTimeout(() => setIsTooltipVisible(false), 100);
  };

  const checkAndHideTooltip = () => {
    if (
      !referenceElement.current?.matches(':hover') &&
      !tooltipRef.current?.matches(':hover')
    ) {
      setIsTooltipVisible(false);
    }
  };

  return (
    <div
      ref={referenceElement}
      className="relative flex items-center justify-center gap-2"
      onMouseEnter={
        displayStatus === 'Pending' ? keepTooltipVisible : undefined
      }
      onMouseLeave={hideTooltip}
    >
      <StatusIndicator status={status} />
      <StatusText displayStatus={displayStatus} />
      {isTooltipVisible &&
        renderTooltip(tooltipRef, tooltipText, showTooltip, handleMouseLeave)}
    </div>
  );
};

export const getDisplayStatus = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'Active';
    case 'STAGED':
    case 'DEPROVISIONED':
      return 'Pending';
    case 'LOCKED_OUT':
      return 'Locked out';
    case 'RECOVERY':
      return 'Password reset';
    case 'PASSWORD_EXPIRED':
      return 'Password expired';

    default:
      return status;
  }
};

const getTooltipText = (displayStatus: string) => {
  if (displayStatus !== 'Pending') return null;
  return (
    <p className="text-xs text-white">
      Reach out to{' '}
      <a
        href="mailto:g360-signups@granitenet.com"
        className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          e.stopPropagation()
        }
      >
        g360-signups@granitenet.com
      </a>{' '}
      for assistance.
    </p>
  );
};

export const indicatorBgColor = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 'bg-user-status-active';
    case 'STAGED':
    case 'DEPROVISIONED':
      return 'bg-[#FBB979]';
    case 'LOCKED_OUT':
      return 'bg-status-error-default';
    case 'RECOVERY':
    case 'PASSWORD_EXPIRED':
      return 'bg-ticket-status-techassigned';

    default:
      return 'bg-user-status-deactivated';
  }
};

const StatusIndicator = ({ status }: { status: string }) => {
  return (
    <span className={clsx('h-3 w-3 rounded-full', indicatorBgColor(status))} />
  );
};

export const StatusText = ({ displayStatus }: { displayStatus: string }) => (
  <span className="text-sm font-semibold">{toSentenceCase(displayStatus)}</span>
);

const renderTooltip = (
  tooltipRef: React.RefObject<HTMLDivElement>,
  tooltipText: React.ReactNode,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
) => {
  return ReactDOM.createPortal(
    <div
      ref={tooltipRef}
      className="rounded bg-black px-4 py-2 text-xs text-white shadow-md"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {tooltipText}
    </div>,
    document.body,
  );
};

export default UserStatusColumn;
