import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const TabButton = styled.button<{
  $active: boolean;
  $variant?: string;
  $withUnderline: boolean;
}>`
  padding: ${({ $variant }) => {
    switch ($variant) {
      case 'large':
        return '12px 16px';
      case 'compact':
        return '8px 0';
      case 'medium':
      default:
        return '7px 16px';
    }
  }};
  border: none;
  background-color: transparent;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.button.primary : theme.colors.text.white};
  border-bottom: ${({ $active, $withUnderline }) =>
    $withUnderline
      ? $active
        ? `solid 2px rgb(var(--stroke-accent-default))`
        : `solid 2px rgb(var(--stroke-base-default))`
      : $active
        ? 'solid 2px rgb(var(--stroke-accent-default))'
        : ''};
  cursor: pointer;
  font-size: ${({ theme, $variant }) =>
    $variant === 'large' ? theme.text.size.xl : theme.text.size.lg};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ $variant }) => ($variant === 'large' ? '28px' : '24px')};
  display: flex;
  gap: 8px;
  align-items: baseline;

  svg {
    color: ${({ $active }) =>
      $active
        ? 'rgb(var(--stroke-accent-default))'
        : 'rgb(var(--content-base-subdued))'};
    fill: ${({ $active }) =>
      $active
        ? 'rgb(var(--stroke-accent-default))'
        : 'rgb(var(--content-base-subdued))'};
  }

  &:active:not(:disabled) {
    color: ${({ theme }) => theme.colors.tab.active} !important;
    border-bottom: ${({ theme }) => `solid 2px ${theme.colors.tab.active}`};

    svg {
      color: ${({ theme }) => theme.colors.tab.active};
      fill: ${({ theme }) => theme.colors.tab.active};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.stroke.readonly};
    cursor: default;

    svg {
      color: ${({ theme }) => theme.colors.stroke.readonly};
      fill: ${({ theme }) => theme.colors.stroke.readonly};
    }
  }

  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.button.primary};
    border-bottom: ${({ theme }) => `solid 2px ${theme.colors.button.primary}`};

    svg {
      color: ${({ theme }) => theme.colors.button.primary};
    }
  }
`;
