import React, { FC } from 'react';
import { OverviewType } from '../inventoryAccount.types';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import CollapsableContent, {
  CollapsableContentProps,
} from './CollapsableContent';
import { ClipboardOutline, Download } from 'react-ionicons';

export interface IAttachments
  extends Pick<CollapsableContentProps, 'expand' | 'togglePreference'>,
    Pick<OverviewType, 'attachments'> {}

const Attachments: FC<IAttachments> = ({
  attachments,
  expand,
  togglePreference,
}) => {
  return (
    <CollapsableContent
      title="Attachments"
      icon={<ClipboardOutline color="currentColor" />}
      className="!gap-6"
      expand={expand}
      togglePreference={togglePreference}
    >
      <div className="flex gap-4">
        {attachments.map((attachment) => (
          <GraniteButton key={attachment} variant="secondary" size="large">
            {attachment} <Download />
          </GraniteButton>
        ))}
      </div>
      <GraniteButton variant="secondary" className="w-max">
        Export All <Download />
      </GraniteButton>
    </CollapsableContent>
  );
};

export default Attachments;
