import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableTitle,
} from 'components/Table/Table.styles';
import { FC, ReactNode, useCallback, useState } from 'react';
import Tabs from 'components/Table/Tabs/Tabs';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import Searchbar from 'components/Table/SearchBar';
import {
  InventoryFilterSearchParams,
  InventoryItem,
} from 'api/inventory/schema';
import { useCustomizeTHead } from 'hooks/useCustomizeTHead';
import { ColumnDef } from '@tanstack/react-table';
import { useSortedTable } from 'hooks/useSortedTable';
import { setCookie, toSentenceCase } from 'shared/util/util';
import { getInventoryExport, getInventoryIndex } from 'api/inventory/api';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import Customize from 'components/Table/Customize/Customize';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import clsx from 'clsx';
import { useFilterForTable } from 'hooks/useFilterForTable';
import Filters from 'components/Filters/Filters';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { Chip } from 'components/Chip/Chip';
import { SingleValue } from 'react-select';
// import ActionsMenu from './ActionsMenu';
import format from 'date-fns/format';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { useDownloadAsExcel } from 'hooks/useDownloadAsExcel';
import SwitchTable from './SwitchTable';
import useInventoryStaticData from 'hooks/useInventoryStaticData';
import { EmptyInventory } from './Inventory';
import { useFeatureFlags } from 'feature-flags';
import { useNavigate } from 'react-router-dom';

const ServicesTable: FC<{
  setTableView: React.Dispatch<React.SetStateAction<OptionType>>;
  selectedTableView: OptionType;
}> = ({ selectedTableView, setTableView }) => {
  const [search, setSearch] = useState('');
  // const activeRowIdRef = useRef<string | null>(null);
  // const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null)

  const { data: staticData } = useInventoryStaticData();

  // const handleEllipsisClick = (
  //   event: React.MouseEvent<HTMLDivElement>,
  //   rowId: string,
  // ) => {
  //   event.stopPropagation();
  //   if (activeRowIdRef.current === rowId) {
  //     activeRowIdRef.current = null;
  //     setMenuAnchorEl(null);
  //   } else {
  //     activeRowIdRef.current = rowId;
  //     setMenuAnchorEl(event.currentTarget as HTMLDivElement);
  //   }
  // };
  const { flags } = useFeatureFlags();
  const navigate = useNavigate();

  const tableHeaders: ColumnDef<InventoryItem>[] = [
    {
      id: 'parent_name',
      header: 'Parent name',
      accessorKey: 'parent_name',
      enableSorting: true,
    },
    {
      id: 'parent_number',
      header: 'Parent #',
      accessorKey: 'parent_number',
      enableSorting: true,
    },
    {
      id: 'account_number',
      header: 'Account #',
      accessorKey: 'account_number',
      enableSorting: true,
      cell: (row) => (
        <TicketIdBadge
          {...(flags.INVENTORY_ACCOUNT && {
            onClick: () => {
              navigate(`account/${row.row.original.account_number}/services`);
            },
          })}
        >
          {row.getValue() as ReactNode}
        </TicketIdBadge>
      ),
    },
    {
      id: 'account_name',
      header: 'Account name',
      accessorKey: 'account_name',
      enableSorting: true,
    },
    {
      id: 'address',
      header: 'Address',
      accessorKey: 'address',
      enableSorting: false,
      cell: (cellCtx) => {
        const address = [
          cellCtx.row.original.city,
          cellCtx.row.original.state,
          cellCtx.row.original.zip,
        ].filter(Boolean);
        const singleSiteAddress = address.length > 0 ? address.join(', ') : '';

        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.address}
            </StackedCellMainContent>
            <StackedCellSubtitle>{singleSiteAddress}</StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    {
      id: 'service_type',
      header: 'Service type',
      accessorKey: 'service_type',
      enableSorting: true,
    },
    {
      id: 'vendor',
      header: 'Vendor',
      accessorKey: 'vendor',
      enableSorting: true,
    },
    {
      id: 'service_id',
      header: 'Service ID',
      accessorKey: 'service_id',
      enableSorting: false,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      enableSorting: true,
      cell: (row) => (
        <p>
          <span
            className={clsx(
              'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
              row.row.original.status === 'Active' && 'bg-green-400',
              row.row.original.status === 'InActive' &&
                'bg-status-error-default',
            )}
          />
          {row?.row?.original?.status.charAt(0).toUpperCase() +
            row?.row?.original?.status.slice(1).toLowerCase()}
        </p>
      ),
    },
    {
      id: 'completion_date',
      header: 'Install date',
      accessorKey: 'completion_date',
      enableSorting: true,
      cell: (row) => {
        const rowData = row?.row?.original;
        const completionDate = rowData?.completion_date;
        return completionDate
          ? format(new Date(completionDate), 'MM/dd/yyyy')
          : '';
      },
    },
    {
      id: 'end_date',
      header: 'End date',
      accessorKey: 'end_date',
      enableSorting: true,
      cell: (row) => {
        const rowData = row?.row?.original;
        const endDate = rowData?.end_date;
        return endDate ? format(new Date(endDate), 'MM/dd/yyyy') : '';
      },
    },
    {
      id: 'btn',
      header: 'BTN',
      accessorKey: 'btn',
      enableSorting: true,
      cell: (row) =>
        row.row.original.btn ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'serial_number',
      header: 'Serial number',
      accessorKey: 'serial_number',
      enableSorting: true,
      cell: (row) =>
        row.row.original.serial_number ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'mac',
      header: 'MAC address',
      accessorKey: 'mac',
      enableSorting: true,
      cell: (row) =>
        row.row.original.mac ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'ip',
      header: 'IP address',
      accessorKey: 'ip',
      enableSorting: true,
      cell: (row) =>
        row.row.original.ip ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'model_number',
      header: 'Model #',
      accessorKey: 'model_number',
      enableSorting: true,
      cell: (row) =>
        row.row.original.model_number ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'tag_number',
      header: 'Tag #',
      accessorKey: 'tag_number',
      enableSorting: true,
      cell: (row) =>
        row.row.original.tag_number ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'product_offering',
      header: 'Product offering',
      accessorKey: 'product_offering',
      enableSorting: true,
      cell: (row) =>
        row.row.original.product_offering ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'host_status',
      header: 'Host status',
      accessorKey: 'host_status',
      enableSorting: true,
      cell: (row) =>
        row.row.original.host_status ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    // {
    //   id: 'actions',
    //   header: 'Actions',
    //   cell: ({ row }) => (
    //     <div style={{ position: 'relative', display: 'inline-block' }}>
    //       <EllipsisHorizontal
    //         width="14px"
    //         height="14px"
    //         color="#fff"
    //         onClick={(event: React.MouseEvent<HTMLDivElement>) =>
    //           handleEllipsisClick(event, row.id)
    //         }
    //       />
    //       {activeRowIdRef.current === row.id && (
    //         <ActionsMenu
    //           anchorEl={menuAnchorEl}
    //           onClose={() => {
    //             activeRowIdRef.current = null;
    //             setMenuAnchorEl(null);
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   meta: {
    //     align: 'center',
    //   },
    // },
  ];

  const [tableData, setTableData] = useState<InventoryItem[]>([]);
  const [pageSize, setPageSize] = useState<number>();

  const [completionDateFilter, setCompletionDateFilter] =
    useState<SingleValue<OptionType>>(null);
  const [endDateFilter, setEndDateFilter] =
    useState<SingleValue<OptionType>>(null);
  const [statusFilter, setStatusFilter] =
    useState<SingleValue<OptionType>>(null);

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'completion_date', desc: true }],
  });

  const {
    queryParamFilter: serviceTypeFilter,
    clearFilter: clearServiceTypeFilter,
    ...serviceTypeFilterProps
  } = useFilterForTable({ queryParamKey: 'service_type' });
  const {
    queryParamFilter: vendorFilter,
    clearFilter: clearVendorFilter,
    ...vendorFilterProps
  } = useFilterForTable({ queryParamKey: 'vendor' });

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeInventory', page.toString(), 365);
  };

  const [activeTab, setActiveTab] = useState<'all' | 'active' | 'deactivated'>(
    'all',
  );
  const [tabFilter, setTabFilter] = useState({});

  const buildInventoryQueryParams = useCallback(
    (args?: InventoryFilterSearchParams) => ({
      ...(search ? { search } : {}),
      ...sortingQueryParams,
      ...serviceTypeFilter,
      ...vendorFilter,
      ...(completionDateFilter
        ? { completion_date: completionDateFilter?.value }
        : {}),
      ...(endDateFilter ? { end_date: endDateFilter?.value } : {}),
      ...(statusFilter ? { status: statusFilter?.value } : {}),
      ...tabFilter,
      ...args,
    }),
    [
      completionDateFilter,
      endDateFilter,
      search,
      serviceTypeFilter,
      sortingQueryParams,
      statusFilter,
      tabFilter,
      vendorFilter,
    ],
  );

  const getInventoryIndexFn = useCallback(
    (args?: InventoryFilterSearchParams) => {
      return getInventoryIndex(buildInventoryQueryParams(args));
    },
    [buildInventoryQueryParams],
  );

  const getInventoryExportFn = useCallback(
    (args?: InventoryFilterSearchParams) => {
      return getInventoryExport(buildInventoryQueryParams(args));
    },
    [buildInventoryQueryParams],
  );

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getInventoryIndexFn,
    {
      search,
      serviceTypeFilter,
      statusFilter,
      vendorFilter,
      completionDateFilter,
      endDateFilter,
      tabFilter,
      sortingQueryParams,
    },
    [
      'inventory-index-table',
      search,
      serviceTypeFilter,
      statusFilter,
      vendorFilter,
      completionDateFilter,
      endDateFilter,
      tabFilter,
      sortingQueryParams,
    ],
    {
      onSuccess: ({ data }: { data: InventoryItem[] }) => {
        setTableData(data);
      },
      refetchOnMount: true,
    },
  );

  const tabs = [
    {
      title:
        activeTab === 'all' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `All (${paginatedTableProps.itemCount})`
          : 'All',
      onClick: () => {
        setTabFilter({});
        setActiveTab('all');
      },
    },
    {
      title:
        activeTab === 'active' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `Active (${paginatedTableProps.itemCount})`
          : 'Active',
      onClick: () => {
        setTabFilter({
          status: 'Active',
        });
        setActiveTab('active');
      },
    },
    {
      title:
        activeTab === 'deactivated' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `Deactivated (${paginatedTableProps.itemCount})`
          : 'Deactivated',
      onClick: () => {
        setTabFilter({
          status: 'InActive',
        });
        setActiveTab('deactivated');
      },
    },
  ];

  const clearAllFilters = useCallback(() => {
    clearServiceTypeFilter();
    clearVendorFilter();
    setSearch('');
    setCompletionDateFilter(null);
    setEndDateFilter(null);
    setStatusFilter(null);
  }, [clearServiceTypeFilter, clearVendorFilter]);

  const preferenceFilterClearFunctions: Record<
    string,
    (() => void) | undefined
  > = {
    service_type: serviceTypeFilterProps.value.length
      ? clearServiceTypeFilter
      : undefined,
    vendor: vendorFilterProps.value.length ? clearVendorFilter : undefined,
    status: statusFilter ? () => setStatusFilter(null) : undefined,
    completion_date: completionDateFilter
      ? () => setCompletionDateFilter(null)
      : undefined,
    end_date: endDateFilter ? () => setEndDateFilter(null) : undefined,
  };

  const {
    columns,
    customizeProps,
    hasFilterByPreference,
    isPreferenceLoading,
    exportWithPreferences,
    customizeFirstColumnMobileClass,
  } = useCustomizeTHead({
    customizeFirstColumnMobileKey: 'account_number',
    columns: tableHeaders,
    tableName: 'inventory-service-view',
    onPreferenceRemoved: (field) => {
      preferenceFilterClearFunctions[field]?.();
    },
  });

  const createExcelFile = useDownloadAsExcel(async () => {
    const tickets = await getInventoryExportFn();
    return exportWithPreferences(tickets.data);
  }, 'Inventory_services');

  return (
    <div className="flex w-full flex-col items-start justify-start">
      <TableTitle>My services</TableTitle>
      <div className="relative mb-5 w-full">
        <Tabs tabs={tabs} />
      </div>
      <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
        <div className="w-full sm:flex-1">
          <Searchbar
            placeholder="Search by macnum, address, service, or service ID"
            clearAllValues={search === ''}
            onQueryClear={() => setSearch('')}
            onSearch={(query: string) => {
              setSearch(query);
            }}
          />
        </div>
        <SwitchTable
          setTableView={setTableView}
          selectedTableView={selectedTableView}
        />
        <Customize {...customizeProps} />
        <Filters
          clearFilters={clearAllFilters}
          clearFilterClassName="col-span-full md:col-span-1 w-full"
          className="grid w-full grid-cols-4 gap-4 md:flex"
        >
          <GraniteSelect
            options={staticData?.service_type_options?.map((item: string) => ({
              label: item,
              value: item,
            }))}
            isMulti
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('service_type') && '!hidden',
            )}
            placeholder="Service type"
            controlShouldRenderValue={false}
            isSearchable={false}
            {...serviceTypeFilterProps}
          />
          <GraniteSelect
            options={Object.entries(
              staticData?.service_vendor_options ?? {},
            ).map(([key, valueArray]) => ({
              label: key,
              value: (valueArray as []).join(','),
            }))}
            isMulti
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('vendor') && '!hidden',
            )}
            placeholder="Vendor"
            controlShouldRenderValue={false}
            isSearchable={false}
            {...vendorFilterProps}
          />
          <GraniteSelect
            options={staticData?.service_status_options?.map(
              (item: string) => ({
                label: toSentenceCase(item),
                value: item,
              }),
            )}
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('status') && '!hidden',
            )}
            placeholder="Status"
            controlShouldRenderValue={false}
            isSearchable={false}
            onChange={setStatusFilter}
            value={statusFilter}
          />
          <GraniteSelect
            options={staticData?.service_completion_date_options?.map(
              (item: string) => ({
                label: toSentenceCase(item),
                value: item,
              }),
            )}
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('completion_date') && '!hidden',
            )}
            placeholder="Install date"
            isClearable={false}
            isSearchable={false}
            controlShouldRenderValue={false}
            onChange={setCompletionDateFilter}
            value={completionDateFilter}
          />
          <GraniteSelect
            options={staticData?.service_end_date_options?.map(
              (item: string) => ({
                label: toSentenceCase(item),
                value: item,
              }),
            )}
            className={clsx(
              'col-span-2',
              !hasFilterByPreference('end_date') && '!hidden',
            )}
            placeholder="End date"
            isClearable={false}
            isSearchable={false}
            controlShouldRenderValue={false}
            onChange={setEndDateFilter}
            value={endDateFilter}
          />
        </Filters>
      </div>
      <div className="mb-12 flex flex-wrap gap-4">
        {serviceTypeFilterProps.value.map((sf) => (
          <Chip
            key={sf.value}
            label={sf.label}
            onDelete={() =>
              serviceTypeFilterProps.onChange(
                serviceTypeFilterProps.value.filter(
                  (option) => option.value !== sf.value,
                ),
              )
            }
          />
        ))}
        {vendorFilterProps.value.map((sf) => (
          <Chip
            key={sf.value}
            label={sf.label}
            onDelete={() =>
              vendorFilterProps.onChange(
                vendorFilterProps.value.filter(
                  (option) => option.value !== sf.value,
                ),
              )
            }
          />
        ))}
        {statusFilter?.value && (
          <Chip
            label={statusFilter.label}
            onDelete={() => {
              setStatusFilter(null);
            }}
          />
        )}
        {completionDateFilter?.value && (
          <Chip
            label={completionDateFilter.label}
            onDelete={() => {
              setCompletionDateFilter(null);
            }}
          />
        )}
        {endDateFilter?.value && (
          <Chip
            label={endDateFilter.label}
            onDelete={() => {
              setEndDateFilter(null);
            }}
          />
        )}
      </div>
      <ServerPaginatedTable
        data={tableData ?? []}
        columns={columns}
        title="My services"
        sortingState={sortingState}
        onSortingChange={onSortingChange}
        paginationChanged={paginationChanged}
        downloadTableFn={createExcelFile}
        paginationSizeStored={pageSize}
        emptyDataElement={
          search ||
          statusFilter?.value ||
          serviceTypeFilterProps.value.length > 0 ||
          completionDateFilter?.value ||
          endDateFilter?.value ||
          serviceTypeFilterProps.value.length > 0 ||
          vendorFilterProps.value.length > 0 ? (
            <EmptySearchResults />
          ) : (
            <EmptyInventory />
          )
        }
        {...paginatedTableProps}
        isFetchingData={
          paginatedTableProps.isFetchingData || isPreferenceLoading
        }
        tableContainerClassName={customizeFirstColumnMobileClass}
      />
    </div>
  );
};

export default ServicesTable;
