import React from 'react';

type Status = 'success' | 'informative' | 'danger' | 'caution' | 'neutral';
type Style = 'default' | 'subdued';
type ContentType =
  | { type: 'label'; label: string }
  | { type: 'label+icon'; label: string; icon: React.ReactNode };

interface TagProps {
  status: Status;
  style: Style;
  content: ContentType;
}

const colorSchemes = {
  success: {
    default: {
      background: 'bg-status-success-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-success-subdued',
      text: 'text-content-base-default',
    },
  },
  informative: {
    default: {
      background: 'bg-status-info-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-info-subdued',
      text: 'text-content-base-default',
    },
  },
  danger: {
    default: {
      background: 'bg-status-error-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-error-subdued',
      text: 'text-content-base-default',
    },
  },
  caution: {
    default: {
      background: 'bg-status-warning-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-warning-subdued',
      text: 'text-content-base-default',
    },
  },
  neutral: {
    default: {
      background: 'bg-background-base-surface-3',
      text: 'text-content-base-default',
    },
    subdued: {
      background: 'bg-blue-900',
      text: 'text-white',
    },
  },
};

const Tag: React.FC<TagProps> = ({ status, style, content }) => {
  const { background, text } = colorSchemes[status][style];

  return (
    <div
      className={`flex h-6 items-center justify-center rounded-[8px] px-2 py-2 text-xs font-bold ${background} ${text} `}
    >
      {content.type === 'label' ? (
        <span>{content.label}</span>
      ) : (
        <>
          <span className="mr-1">{content.icon}</span>
          <span>{content.label}</span>
        </>
      )}
    </div>
  );
};

export default Tag;
