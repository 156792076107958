import clsx from 'clsx';
import React, { FC } from 'react';

interface TextContentProps {
  content?: string | null;
}
const TextContent: FC<TextContentProps> = ({ content }) => {
  return (
    <p
      className={clsx('text-base font-bold', {
        'text-white': content,
        'text-neutral-500': !content,
      })}
    >
      {content ?? 'N/A'}
    </p>
  );
};

export default TextContent;
