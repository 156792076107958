import {
  CardLogo,
  GraniteSupportButton,
  HomeHref,
  HomeLayoutFragment,
  HomeLink,
  HomeServicesGrid,
  HomeServicesHeader,
  HomeWelcomeContainer,
  HomeWelcomeDate,
  HomeWelcomeQuickLink,
  HomeWelcomeQuickLinkBar,
  HomeWelcomeTitle,
  UICard,
  UICardFooter,
  UICardHeader,
} from './Home.styles';
import techexpressLogo from '../../assets/images/techexpress.svg';
import accessexpressLogo from '../../assets/images/accessexpress.svg';
import nocexpressLogo from '../../assets/images/nocexpress.svg';
import { ConstructSharp, Exit, Wifi } from 'react-ionicons';
import format from 'date-fns/format';
import { ReactComponent as Footsteps } from '../../assets/icon/footsteps.svg';
import CompanyName from 'components/Navbar/CompanyName';
import { useAuthUser } from 'hooks/useAuthUser';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { TokenUserPermissions } from '../../api/users/schemas/Users';
import { CompanyAdminsDialog } from './CompanyAdminsDialog';
import { useModal } from 'hooks/useModal';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { usePermissions } from 'hooks/usePermissions';

const Home = () => {
  const { name, companyName } = useAuthUser();

  const { open, ...props } = useModal();

  const { hasPermission } = usePermissions({
    permission: [TokenUserPermissions.ACCESS_EXPRESS_MANAGE],
  });

  return (
    <div className="flex w-full flex-col items-start justify-start gap-6 px-4 py-8 lg:px-8">
      <HomeWelcomeContainer className="gap-2 bg-background-base-surface-2/70 px-6 py-8 md:py-12 lg:py-16">
        <HomeWelcomeDate className="text-sm leading-snug sm:text-base">
          {format(new Date(), 'eeee, MMMM do, yyyy')}
        </HomeWelcomeDate>
        <HomeWelcomeTitle className="text-4xl md:text-5xl lg:text-[52px] lg:!leading-tight">
          Welcome, {name ?? ''}
        </HomeWelcomeTitle>
        <CompanyName name={companyName} textClasses="text-xl md:text-2xl" />
        <DisplayForPermission
          permission={[
            TokenUserPermissions.ACCESS_EXPRESS_WRITE,
            TokenUserPermissions.TECH_EXPRESS_WRITE,
            TokenUserPermissions.NOC_EXPRESS_WRITE,
          ]}
        >
          <HomeWelcomeQuickLinkBar className="mt-6 flex-wrap md:mt-8">
            <DisplayForPermission
              permission={TokenUserPermissions.ACCESS_EXPRESS_WRITE}
            >
              <HomeWelcomeQuickLink
                to="/access-express"
                className="!w-full sm:!w-auto"
              >
                <Wifi width="24px" height="24px" />
                Order services
              </HomeWelcomeQuickLink>
            </DisplayForPermission>
            <DisplayForPermission
              permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
            >
              <HomeWelcomeQuickLink
                to="/tech-express/let-us-help"
                className="!w-full sm:!w-auto"
              >
                <Footsteps />
                Request dispatch
              </HomeWelcomeQuickLink>
            </DisplayForPermission>
            <DisplayForPermission
              permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
            >
              <HomeWelcomeQuickLink
                to="/noc-express/open-ticket"
                className="!w-full sm:!w-auto"
              >
                <ConstructSharp width="24px" height="24px" color="inherit" />
                Open repair ticket
              </HomeWelcomeQuickLink>
            </DisplayForPermission>
          </HomeWelcomeQuickLinkBar>
        </DisplayForPermission>
      </HomeWelcomeContainer>
      <HomeLayoutFragment>
        <HomeServicesHeader className="text-xl leading-tight md:text-[28px]">
          Your services
        </HomeServicesHeader>
        <HomeServicesGrid>
          <DisplayForPermission
            permission={TokenUserPermissions.ACCESS_EXPRESS_READ}
          >
            <UICard>
              <CardLogo alt="Access Express logo" src={accessexpressLogo} />
              <p>
                Browse and order Granite products and services available at your
                service locations.
              </p>
              <UICardFooter>
                <div className="flex justify-start gap-4">
                  <HomeLink to="access-express/index">
                    <GraniteButton
                      size="large"
                      variant={`${hasPermission ? 'secondary' : 'primary'}`}
                      className="self-end"
                    >
                      {hasPermission ? 'Go to quote requests' : 'View quotes'}
                    </GraniteButton>
                  </HomeLink>
                  {hasPermission && (
                    <HomeLink to="/upload-qr">
                      <GraniteButton
                        size="large"
                        variant="primary"
                        className="self-end"
                      >
                        Upload quote
                      </GraniteButton>
                    </HomeLink>
                  )}
                </div>
              </UICardFooter>
            </UICard>
          </DisplayForPermission>
          <DisplayForPermission
            permission={TokenUserPermissions.TECH_EXPRESS_READ}
          >
            <UICard>
              <CardLogo alt="Tech Express Logo" src={techexpressLogo} />
              <p>
                Request a service technician to any location serviced by Granite
                for a range of different service needs.
              </p>
              <UICardFooter>
                <HomeLink to="/tech-express">
                  <GraniteButton size="large">View your tickets</GraniteButton>
                </HomeLink>
              </UICardFooter>
            </UICard>
          </DisplayForPermission>
          <DisplayForPermission
            permission={TokenUserPermissions.NOC_EXPRESS_READ}
          >
            <UICard>
              <CardLogo alt="NOC Express Logo" src={nocexpressLogo} />
              <p>
                View all repair tickets, as well as a personalized dashboard
                based on the performance of your company’s network of Granite
                provided services.
              </p>
              <UICardFooter>
                <HomeLink to="/noc-express">
                  <GraniteButton size="large">View your tickets</GraniteButton>
                </HomeLink>
              </UICardFooter>
            </UICard>
          </DisplayForPermission>
          <UICard>
            <UICardHeader>Granite support</UICardHeader>
            <p>
              Need assistance? Visit our help center for support from Granite’s
              24/7 Customer Service team.
            </p>
            <UICardFooter>
              <HomeHref href="https://granitenet.com/support">
                <GraniteSupportButton>
                  Visit help center
                  <Exit width="16px" height="16px" color />
                </GraniteSupportButton>
              </HomeHref>
            </UICardFooter>
          </UICard>
        </HomeServicesGrid>
      </HomeLayoutFragment>
      <CompanyAdminsDialog {...props} />
    </div>
  );
};

export default Home;
