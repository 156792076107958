import { PageTitleGranite } from 'components';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import Metrics from './Metrics';
import InventoryRecentActivity from './InventoryRecentActivity';
import ServicesTable from './ServicesTable';
import { useState } from 'react';
import AccountsTable from './AccountsTable';
import { OptionType } from 'components/Select/Select';
import { switchTableOptions } from './SwitchTable';
import { FileTray } from 'react-ionicons';
import { useFeatureFlags } from 'feature-flags';

export const EmptyInventory = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You do not have any inventory.
      </p>
    </div>
  );
};

const Inventory = () => {
  const [tableView, setTableView] = useState<OptionType>(switchTableOptions[0]);
  const { flags } = useFeatureFlags();

  return (
    <ContentLayout className="overflow-hidden">
      <div className="mb-6">
        <PageTitleGranite title="Inventory" />
      </div>
      <div className="mb-12 flex flex-col gap-6 xl:flex-row">
        <Metrics />
        <div className="w-full rounded bg-background-base-surface-2 p-4 shadow-elevation3 md:px-6 md:py-6 xl:max-w-[504px]">
          <InventoryRecentActivity />
        </div>
      </div>
      {flags.INVENTORY_ACCOUNT_INDEX_VIEW && tableView.value === 'account' ? (
        <AccountsTable
          setTableView={setTableView}
          selectedTableView={tableView}
        />
      ) : (
        <ServicesTable
          setTableView={setTableView}
          selectedTableView={tableView}
        />
      )}
    </ContentLayout>
  );
};

export default Inventory;
